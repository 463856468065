import { nextTick } from 'vue';
import { useEventBus } from '@vueuse/core';

const BOUNCE_DURATION = 2000;

export default function useErrorBounceOnFormSubmit(errorRef) {
  const bus = useEventBus('v-form-invalid-submit');

  nextTick(() => {
    bus.on(() => {
      errorRef.value?.classList?.add('animate-bounce');

      setTimeout(() => {
        errorRef.value?.classList?.remove('animate-bounce');
      }, BOUNCE_DURATION);
    });
  });
}

