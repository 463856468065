import { api } from '@/api';
import type { GroupMemberLoanApplication } from '@/api/groupMemberLoanApplication';

function signContract(groupMemberLoanApplicationId: number) {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');

  return api<{ groupMemberLoanApplication: GroupMemberLoanApplication }>({
    url: `/api/internal/group_member_loan_applications/${groupMemberLoanApplicationId}/contract_signatures`,
    method: 'post',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });
}

export { signContract };
