import type { UseQueryOptions } from '@tanstack/vue-query';
import { unref, type MaybeRef, ref } from 'vue';

import type { ApiError } from '@/api';
import { getAll, type Context, type Tag, type ResourceType } from '@/api/tags';
import useReactiveQuery from '@/composables/useReactiveQuery';

import { tagsKey } from '../queryKeys/tags';

type FetchTagsComposableArgs = {
  resourceId: MaybeRef<number>
  resourceType: MaybeRef<ResourceType>
  context: MaybeRef<Context>
} & UseQueryOptions<Tag[], ApiError>;

function useFetchTags({ resourceId, resourceType, context, ...options }: FetchTagsComposableArgs) {
  return useReactiveQuery({
    queryKey: tagsKey({ resourceType: ref(resourceType), resourceId: ref(resourceId), context: ref(context) }),
    queryFn: () => getAll(unref(resourceId), unref(resourceType), unref(context))
      .then((response) => response.data.tags),
    ...options,
  });
}

export { useFetchTags };
