export default {
  circuloCreditReport: {
    paymentFrequency: {
      A: 'Anual',
      B: 'Bimestral',
      C: 'Catorcenal',
      D: 'Deducción de salario',
      E: 'Semestral',
      M: 'Mensual',
      Q: 'Quincenal',
      R: 'Minimo revolventes',
      S: 'Semanal',
      T: 'Trimestral',
      U: 'Único',
    },
    loanType: {
      AA: 'Arrendamiento Automotriz',
      AB: 'Automotriz Bancario',
      AE: 'Física Actividad Empresarial',
      AM: 'Aparatos/Muebles',
      AR: 'Arrendamiento',
      AV: 'Aviación',
      BC: 'Banca Comunal',
      BL: 'Bote/Lancha',
      BR: 'Bienes Raíces',
      CA: 'Compra De Automóvil',
      CC: 'Crédito Al Consumo',
      CF: 'Crédito Fiscal',
      CO: 'Consolidación',
      CP: 'Crédito Personal Al Consumo',
      ED: 'Editorial',
      EQ: 'Equipo',
      FF: 'Fondeo Fira',
      FI: 'Fianza',
      FT: 'Factoraje',
      GS: 'Grupo Solidario',
      HB: 'Hipotecario Bancario',
      HE: 'Préstamo Tipo Home Equity',
      HV: 'Hipotecario ó Vivienda',
      LC: 'Línea de Crédito',
      MC: 'Mejoras a la Casa',
      NG: 'Préstamo No Garantizado',
      PB: 'Préstamo Personal Bancario',
      PC: 'Procampo',
      PE: 'Préstamo Para Estudiante',
      PG: 'Préstamo Garantizado',
      PQ: 'Préstamo Quirografario',
      PM: 'Préstamo Empresarial',
      PN: 'Préstamo de Nómina',
      PP: 'Préstamo Personal',
      SH: 'Segunda Hipoteca',
      TC: 'Tarjeta De Crédito',
      TD: 'Tarjeta Departamental',
      TG: 'Tarjeta Garantizada',
      TS: 'Tarjeta De Servicios',
      VR: 'Vehículo Recreativo',
      OT: 'Otros',
      NC: 'Desconocido',
    },
    responsibilityType: {
      I: 'Individual',
      M: 'Mancomunado',
      O: 'Obligado Solidario',
      A: 'Aval',
      T: 'Titular con Aval',
    },
  },
  groupInvitation: {
    status: {
      pending: 'Pendiente',
      accepted: 'Aceptada',
      rejected: 'Rechazada',
    },
    yearsKnown: {
      'one_or_less': '1 año o menos',
      'one_to_two': '1 a 2 años',
      'three_to_five': '3 a 5 años',
      'five_to_ten': '5 a 10 años',
      'ten_or_more': '10 años o más',
    },
    relationship: {
      'close_relative': 'Familiar cercano',
      'distant_relative': 'Familiar lejano',
      'in_law_relative': 'Familiar político',
      partner: 'Pareja',
      friend: 'Amigo',
      neighbor: 'Vecino',
      acquaintance: 'Conocido',
    },
  },
  groupMember: {
    roles: {
      'guest': 'Integrante solidario',
      'vice_president': 'Vicepresidente',
      'president': 'Presidente',
    },
  },
  groupLoanApplication: {
    aasmState: {
      'inviting': 'Invitando',
      'draft': 'Draft',
      'application_in_review': 'En revisión',
      'application_rejected': 'Rechazada',
      'in_kyc': 'KYC',
      'kyc_in_review': 'KYC en revisión',
      'kyc_rejected': 'KYC rechazado',
      'contracts_signature_pending': 'Firmando',
      'contracts_signed': 'Contratos firmados',
      'disbursed': 'Crédito desembolsado',
      'canceled': 'Cancelada',
    },
    rejectionTags: {
      'committee_policy_violation': 'Comité incumple políticas',
      'majority_members_policy_violation': 'Mayoría de integrantes incumple políticas',
      'exceeds_reformation_attempts': 'Supera intentos de re-conformación',
      'high_risk_policies_at_limit': 'Alto riesgo, políticas al raz',
      'potential_fraud': 'Tentativa de fraude',
    },
  },
  groupMemberLoanApplication: {
    aasmState: {
      'draft': 'Borrador',
      'submitted': 'Enviada',
      'rejected': 'Rechazada',
      'in_kyc': 'KYC',
      'kyc_submitted': 'KYC enviada',
      'contract_signature_pending': 'Firmando',
      'contract_signed': 'Contrato firmado',
    },
    rejectionTags: {
      'exceeds_maximum_age': 'Excede edad máxima',
      'under_minimum_age': 'Bajo edad mínima',
      'high_risk': 'Riesgo alto',
      'policies_at_risk_limit': 'Políticas al raz',
      'individual_loan_arrears_policy_violation': 'Incumple atrasos en individuales',
      'group_loan_arrears_policy_violation': 'Incumple atrasos en grupales',
      'non_compliance_policies': 'Incumple políticas de crédito (otros)',
      'straw_borrower_attempt': 'Intento de prestanombre',
      'not_consolidated': 'No consolidado',
      'exceeds_maximum_recent_delinquent_payments': 'Más de 2 pagos en atraso en menos de 6 meses',
      'exceeds_maximum_group_delinquent_amount': 'Sobrepasa el máximo de de deuda vencida en grupales',
      'exceeds_maximum_individual_delinquent_amount': 'Sobrepasa el máximo de de deuda vencida en individuales',
      'surname_collision': 'Existe repetición de apellidos entre los integrantes',
      'members_from_different_states': 'Hay miembros que viven en distintos estados',
      'more_than_allowed_men_in_application': 'Hay más de 2 hombres en el grupo',
      'too_many_recent_queries': 'Se ha consultado su buró de crédito más de 8 veces en los últimos meses',
      'exceeds_maximum_recently_opened_delinquent_individual_loans':
        'Tiene deuda vencida en créditos individuales abiertos recientemente',
    },
  },
  promoterPresidentInvites: {
    knownFrom: {
      'recommended_by_current_client': 'Lo recomendó una clienta actual',
      'recommended_by_someone': 'Lo recomendadó alguien',
      'contacted_by_someone': 'Lo contactó alguien',
      'worked_with_them_before': 'Trabajó con la presidenta y otras integrantes',
      'worked_with_president_before': 'Trabajó con la presidenta',
      'not_known': 'No las conoce',
    },
    knownSince: {
      'more_than_1_year': 'Más de 1 año',
      'less_than_1_year': 'Menos de un año',
      'less_than_6_months': 'Menos de 6 meses',
      'less_than_3_months': 'Menos de 3 meses',
      'just_met': 'Recién',
    },
  },
};
