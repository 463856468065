<script setup lang="ts">
import { computed, toRef, type Ref } from 'vue';

import type { ResourceComment } from '@/api/comment';
import type { IUser } from '@/api/currentUser';
import { useFetchDocuments } from '@/api/document/queries';
import BaseImageWithControls from '@/components/base-image-with-controls.vue';
import useStrictInject from '@/composables/useStrictInject';
import { formatRelativeDate } from '@/utils/filters';
import { currentUserKey } from '@/utils/keys';

interface Props {
  comment: ResourceComment;
}

const props = defineProps<Props>();

const currentUser = useStrictInject<Ref<IUser>>(currentUserKey);
const isAuthor = computed(() => !!currentUser && currentUser.value.id === props.comment.author.id);

const documentsQ = useFetchDocuments({
  documentableType: 'Comment',
  documentableId: toRef(props.comment, 'id'),
});

function getDocumentUrlFromId(id: number) {
  const document = documentsQ.data?.find(doc => doc.id === id);

  return document?.fileUrl;
}

// eslint-disable-next-line max-len, vue/max-len
const ATTACHMENT_REGEX = /<action-text-attachment[^>]*content-type="([^"]*)"[^>]*url="([^"]*)"[^>]*filename="([^"]*)"[^>]*><\/action-text-attachment>/g;

interface CommentPart {
  type: 'text' | 'image' | 'file';
  content?: string;
  url?: string;
  filename?: string;
}

interface MatchResult {
  parts: CommentPart[];
  lastIndex: number;
}

function createTextPart(content: string) {
  return {
    type: 'text' as const,
    content,
  };
}

function createAttachmentPart(match: RegExpMatchArray): CommentPart {
  const [, contentType, url, filename] = match;
  const documentUrl = getDocumentUrlFromId(Number(url));

  return {
    type: contentType.startsWith('image') ? 'image' : 'file',
    url: documentUrl,
    filename,
  };
}

const commentParts = computed(() => {
  const commentBody = props.comment.body;

  const dividedParts = Array.from(commentBody.matchAll(ATTACHMENT_REGEX))
    .reduce((result, match) => {
      const { parts, lastIndex } = result;
      const matchIndex = match.index;

      if (matchIndex > lastIndex) {
        parts.push(createTextPart(commentBody.slice(lastIndex, matchIndex)));
      }

      parts.push(createAttachmentPart(match));

      return { parts, lastIndex: matchIndex + match[0].length };
    }, { parts: [], lastIndex: 0 } as MatchResult);

  if (dividedParts.lastIndex < commentBody.length) {
    dividedParts.parts.push(createTextPart(commentBody.slice(dividedParts.lastIndex)));
  }

  return dividedParts.parts;
});
</script>

<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="flex flex-col gap-y-2 rounded-lg border bg-white px-4 py-3 shadow">
    <div class="mb-1 flex flex-row items-center gap-x-2 text-xs font-light text-gray-700">
      <span
        v-if="isAuthor"
        class="italic"
      >
        Tú
      </span>
      <span v-else>
        {{ comment.author.firstName }}
      </span>
      <span>-</span>
      <span>
        {{ formatRelativeDate(props.comment.createdAt) }}
      </span>
    </div>
    <div class="overflow-scroll text-sm text-gray-700">
      <template
        v-for="(part, index) in commentParts"
        :key="index"
      >
        <div
          v-if="part.type === 'text'"
          v-html="part.content"
        />
        <base-image-with-controls
          v-else-if="part.type === 'image' && part.url"
          :src="part.url"
          class="max-h-24 rounded border border-primary-600 p-2"
        />
        <a
          v-else-if="part.type === 'file'"
          class="text-primary-600"
          :href="part.url"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ part.filename }}
        </a>
      </template>
    </div>
  </div>
</template>
