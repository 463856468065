<script setup lang="ts">
import type { AasmState } from '@/api/groupLoanApplication';
import GroupLoanApplicationStatusBoardColumn from '@/components/reviews/group-loan-application-status-board-column.vue';

const GROUP_LOAN_APPLICATION_AASM_STATES: AasmState[] = [
  'inviting',
  'draft',
  'application_in_review',
  'in_kyc',
  'kyc_in_review',
  'contracts_signature_pending',
  'contracts_signed',
  'disbursed',
  'application_rejected',
  'canceled',
];
</script>
<template>
  <div class="flex w-screen gap-x-2 overflow-x-auto px-20 py-4">
    <group-loan-application-status-board-column
      v-for="status in GROUP_LOAN_APPLICATION_AASM_STATES"
      :key="status"
      :status="status"
    />
  </div>
</template>
