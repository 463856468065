import type { UseQueryOptions } from '@tanstack/vue-query';
import { toRef, unref, type MaybeRef } from 'vue';

import type { ApiError } from '@/api';
import { get, type GroupMemberLoanApplication } from '@/api/groupMemberLoanApplication';
import { groupMemberLoanApplicationKey } from '@/api/queryKeys';
import useReactiveQuery from '@/composables/useReactiveQuery';

type FetchGroupMemberLoanApplicationComposableArgs = {
  id: MaybeRef<number>
} & UseQueryOptions<GroupMemberLoanApplication, ApiError>;

function useFetchGroupMemberLoanApplication({ id, ...options }: FetchGroupMemberLoanApplicationComposableArgs) {
  return useReactiveQuery({
    queryKey: groupMemberLoanApplicationKey(toRef(id)),
    queryFn: () => get(unref(id)).then(response => response.data.groupMemberLoanApplication),
    ...options,
  });
}

export { useFetchGroupMemberLoanApplication };
