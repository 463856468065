<script setup>
import { computed } from 'vue';
import { ExclamationTriangleIcon, CheckCircleIcon, InformationCircleIcon, XCircleIcon } from '@heroicons/vue/20/solid';

const props = defineProps({
  type: {
    type: String,
    required: true,
    validator: (value) => ['error', 'success', 'alert', 'notice', 'info'].includes(value),
  },
  message: { type: String, default: '' },
  description: { type: String, default: '' },
});

const alertClasses = computed(() => {
  switch (props.type) {
  case 'error':
    return 'rounded-md bg-red-100 p-4';
  case 'success':
    return 'rounded-md bg-green-100 p-4';
  case 'alert':
    return 'rounded-md border-yellow-400 bg-yellow-50 p-4';
  case 'info':
  case 'notice':
  default:
    return 'rounded-md bg-blue-100 p-4';
  }
});

const textColor = computed(() => {
  switch (props.type) {
  case 'error':
    return 'text-red-700';
  case 'success':
    return 'text-green-700';
  case 'alert':
    return 'text-yellow-700';
  case 'info':
  case 'notice':
  default:
    return 'text-blue-700';
  }
});

const iconColor = computed(() => {
  switch (props.type) {
  case 'error':
    return 'text-red-400';
  case 'success':
    return 'text-green-400';
  case 'alert':
    return 'text-yellow-400';
  case 'info':
  case 'notice':
  default:
    return 'text-blue-400';
  }
});

const iconComponent = computed(() => {
  switch (props.type) {
  case 'error':
    return XCircleIcon;
  case 'success':
    return CheckCircleIcon;
  case 'alert':
    return ExclamationTriangleIcon;
  case 'info':
  case 'notice':
  default:
    return InformationCircleIcon;
  }
});

</script>

<template>
  <div :class="alertClasses">
    <div class="flex">
      <div class="shrink-0">
        <component
          :is="iconComponent"
          class="h-5 w-5"
          :class="iconColor"
          aria-hidden="true"
        />
      </div>
      <div class="ml-3 flex-1">
        <slot>
          <p
            class="text-xs sm:text-sm"
            :class="textColor"
          >
            {{ message }}
          </p>
          <slot name="description">
            <p
              v-if="description"
              class="text-xs text-gray-700"
            >
              {{ description }}
            </p>
          </slot>
        </slot>
      </div>
    </div>
  </div>
</template>

