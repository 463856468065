<script setup lang="ts">
import { ChevronDoubleDownIcon } from '@heroicons/vue/24/solid';
import { watch, computed } from 'vue';
import { useI18n } from 'vue-i18n';

import type { AasmState } from '@/api/groupLoanApplication';
import {
  type FetchGroupLoanApplicationsResponse, useFetchGroupLoanApplications,
} from '@/api/groupLoanApplication/queries';
import BaseBadge from '@/components/base-badge.vue';
import BaseSpinner from '@/components/base-spinner.vue';
import GroupLoanApplicationStatusBoardCard from '@/components/reviews/group-loan-application-status-board-card.vue';
import useUiStore from '@/stores/ui';
import { groupLoanApplicationBadgeColor } from '@/utils/states';

const CREATED_AT_ASC = 'created_at asc';
const SUBMITTED_AT_ASC = 'submitted_at asc';
const MEETING_AT_ASC = 'meeting_at asc';
const MEETING_AT_DESC = 'meeting_at desc';
const UPDATED_AT_DESC = 'updated_at desc';

const SORTING_FIELD_BY_AASM_STATE: Record<AasmState, string> = {
  'inviting': CREATED_AT_ASC,
  'draft': CREATED_AT_ASC,
  'application_in_review': SUBMITTED_AT_ASC,
  'in_kyc': SUBMITTED_AT_ASC,
  'kyc_in_review': SUBMITTED_AT_ASC,
  'contracts_signature_pending': SUBMITTED_AT_ASC,
  'contracts_signed': MEETING_AT_ASC,
  'disbursed': MEETING_AT_DESC,
  'application_rejected': UPDATED_AT_DESC,
  'canceled': UPDATED_AT_DESC,
};

const PAGE_SIZE_BY_AASM_ATATE: Record<AasmState, number> = {
  'inviting': 5,
  'draft': 10,
  'application_in_review': 10,
  'in_kyc': 10,
  'kyc_in_review': 10,
  'contracts_signature_pending': 10,
  'contracts_signed': 10,
  'disbursed': 10,
  'application_rejected': 5,
  'canceled': 5,
};

interface Props {
  status: AasmState;
}

const props = defineProps<Props>();

const { t } = useI18n();
const uiStore = useUiStore();

const statusLabel = computed(() => t(`groupLoanApplication.aasmState.${props.status}`));

const {
  data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage, isError, error,
} = useFetchGroupLoanApplications({
  q: {
    aasmStateEq: props.status,
    sorts: SORTING_FIELD_BY_AASM_STATE[props.status],
  },
  pageSize: PAGE_SIZE_BY_AASM_ATATE[props.status],
});

const allGroupLoanApplications = computed(() => {
  if (!data.value?.pages) return [];

  return data.value.pages.flatMap(
    (element: FetchGroupLoanApplicationsResponse) => element.groupLoanApplications,
  );
});

watch([error, isError], () => {
  if (isError.value && error.value?.response && error.value.response.status !== 404) {
    const errorMessage = error.value.response.data?.detail || 'Ocurrió un error al consultar las solicitudes';

    uiStore.toast({
      message: `${statusLabel.value}: ${errorMessage}`,
      type: 'error',
      position: 'top',
    });
  }
});
</script>
<template>
  <div
    class="flex w-72 shrink-0 flex-col gap-y-5 rounded-md bg-gray-50 px-4 py-2 ring-1 ring-gray-300"
  >
    <base-badge
      :color="groupLoanApplicationBadgeColor(status)"
      :label="statusLabel"
      size="base"
    />
    <group-loan-application-status-board-card
      v-for="groupLoanApplication in allGroupLoanApplications"
      :key="groupLoanApplication.id"
      :group-loan-application="groupLoanApplication"
    />
    <div class="flex items-center justify-center">
      <base-spinner
        v-if="isFetchingNextPage || isFetching"
        :size="4"
      />
      <p
        v-if="!isFetching && allGroupLoanApplications.length === 0"
        class="italic text-gray-400"
      >
        No hay solicitudes
      </p>
    </div>
    <base-button
      v-if="allGroupLoanApplications.length > 0"
      :disabled="isFetchingNextPage || !hasNextPage"
      variant="white"
      class="flex items-center justify-center"
      @click="fetchNextPage"
    >
      <ChevronDoubleDownIcon
        v-if="hasNextPage"
        class="h-5 w-5 fill-gray-600"
      />
      <p v-else>
        No hay más solicitudes
      </p>
    </base-button>
  </div>
</template>
