import { api } from '@/api';
import type { GroupLoanApplication } from '@/api/groupLoanApplication';
import type { BaseUser } from '@/api/user';

interface GroupLoanRenewalForm {
  user: Pick<BaseUser, 'phoneNumber'>
}

function create(body: GroupLoanRenewalForm) {
  return api.post<{ groupLoanApplication: GroupLoanApplication }>('/api/internal/support/group_loan/renewals', body);
}

export { create };
export type { GroupLoanRenewalForm };
