<script setup lang="ts">
import { useForm } from 'vee-validate';
import { computed } from 'vue';

import type { GroupLoanApplication } from '@/api/groupLoanApplication';
import { type PreApprovePayload } from '@/api/groupLoanApplication/reviews';
import { usePreApproveGroupLoanApplication } from '@/api/groupLoanApplication/reviews/mutations';
import type { GroupMemberLoanApplication } from '@/api/groupMemberLoanApplication';
import { useCreateTags } from '@/api/tags/mutations';
import BaseSlideOver from '@/components/base-slide-over.vue';
import GroupMemberLoanApplicationPreApproval from '@/components/reviews/group-member-loan-application-pre-approval.vue';
import useUIStore from '@/stores/ui';
import { toCurrency } from '@/utils/filters';
import LoanUtils from '@/utils/loanUtils';

interface Props {
  groupLoanApplication: GroupLoanApplication
  rejectionTags: string[];
  open: boolean;
}

interface Emits {
  (e: 'close'): void;
}

export interface GroupMemberLoanApplicationWithLabels extends GroupMemberLoanApplication {
  slideOverItems: {
    'Monto': string;
    'Rol': string;
    'Ingresos': string;
    'Monto disponible para crédito': string;
    'Capacidad de pago interna': string;
    'Costo por mil': string;
    'Pago semanal': string;
    'Peso': string;
    'Periodos / Mont disp. para crédito': string
  };
}

defineEmits<Emits>();
const props = defineProps<Props>();

const uiStore = useUIStore();
const group = computed(() => props.groupLoanApplication.group);

const initialEditableGroupInfo = props.groupLoanApplication.memberLoanApplications
  .reduce((acc, groupMemberLoanApplication) => {
    acc[`${groupMemberLoanApplication.id}`] = {
      meetsLoanConditions: groupMemberLoanApplication.meetsLoanConditions,
      consolidated: groupMemberLoanApplication.consolidated,
      acceptedAmount: groupMemberLoanApplication.acceptedAmount,
      interestRate: groupMemberLoanApplication.interestRate,
      preApproved: groupMemberLoanApplication.preApproved,
      rejectionTags: [],
    };

    return acc;
  }, {} as Record<string, {
    meetsLoanConditions: boolean;
    consolidated: boolean;
    acceptedAmount: number | null;
    interestRate: number;
    preApproved: boolean;
    rejectionTags: string[];
  }>);

const { values, setFieldValue, handleSubmit } = useForm(
  { initialValues: initialEditableGroupInfo, keepValuesOnUnmount: true },
);

const createTagsMutation = useCreateTags({
  onError: (e) => {
    uiStore.toast({
      message: e.response?.data?.detail || 'Hubo un error al crear los tags',
      type: 'error',
      position: 'top',
    });
  },
});

// eslint-disable-next-line max-statements, complexity
function computeSlideOverItems(
  groupMemberLoanApplication: GroupMemberLoanApplication,
  slideOverItems: Record<string, string | number | null>,
) {
  const info = values[groupMemberLoanApplication.id];
  const groupMember = props.groupLoanApplication.group.members.find(
    member => member.id === groupMemberLoanApplication.groupMemberId,
  );

  let periodPayment = null;

  if (info.acceptedAmount) {
    periodPayment = Math.ceil(LoanUtils.calculateLoanPayment(
      info.acceptedAmount,
      info.interestRate,
      props.groupLoanApplication.repaymentPeriods,
    ));
  }

  if (groupMember) {
    slideOverItems.Monto = groupMemberLoanApplication.amount && toCurrency(groupMemberLoanApplication.amount);
    slideOverItems.Rol = groupMember.humanRole;
    slideOverItems.Ingresos = groupMemberLoanApplication.income && toCurrency(groupMemberLoanApplication.income);
    slideOverItems.Gastos = groupMemberLoanApplication.expenses && toCurrency(groupMemberLoanApplication.expenses);
    slideOverItems['Monto disponible para crédito'] = groupMemberLoanApplication.loanPaymentAbility && toCurrency(
      groupMemberLoanApplication.loanPaymentAbility,
    );
    slideOverItems['Capacidad de pago interna'] = groupMemberLoanApplication.internalLoanPaymentAbility;
    slideOverItems['Costo por mil'] = LoanUtils.costPerThousand(
      info.interestRate,
      props.groupLoanApplication.repaymentPeriods,
    );
    slideOverItems['Pago semanal'] = periodPayment && toCurrency(periodPayment);
    slideOverItems.Peso = ((info.acceptedAmount || 0) / Object.values(values)
      .map(info => info.acceptedAmount || 0)
      .reduce((acc, amount) => acc + amount, 0))
      .toFixed(2); // eslint-disable-line no-magic-numbers
    slideOverItems['Periodos / Monto disp. para crédito'] = periodPayment &&
      groupMemberLoanApplication.loanPaymentAbility && (
      periodPayment / groupMemberLoanApplication.loanPaymentAbility
    ).toFixed(2); // eslint-disable-line no-magic-numbers
  }
}

const groupMemberLoanApplicationsComputedInfoWithLabels = computed(
  () => props.groupLoanApplication.memberLoanApplications
    .map(
      groupMemberLoanApplication => {
        const slideOverItems = {};
        computeSlideOverItems(groupMemberLoanApplication, slideOverItems);

        return {
          ...groupMemberLoanApplication,
          slideOverItems,
        } as GroupMemberLoanApplicationWithLabels;
      },
    ),
);

const groupTotalLoanAmount = computed(
  () => Object.values(values)
    .filter(info => info.preApproved)
    .reduce((total, applicationInfo) => total + (applicationInfo.acceptedAmount || 0), 0),
);

const groupPeriodPayment = computed(
  () => Object.values(values)
    .filter(info => info.preApproved)
    .reduce(
      (total, applicationInfo) => total +
        Math.ceil(LoanUtils.calculateLoanPayment(
          applicationInfo.acceptedAmount || 0,
          applicationInfo.interestRate,
          props.groupLoanApplication.repaymentPeriods,
        )),
      0,
    ),
);

const weightedAverageInterestRate = computed(
  () => {
    const totalWeight = Object.values(values)
      .filter(info => info.preApproved)
      .map(info => info.acceptedAmount || 0)
      .reduce((sum, amount) => sum + amount, 0);

    return Object.values(values)
      .filter(info => info.preApproved)
      .map(info => (info.interestRate) * ((info.acceptedAmount || 0) / totalWeight))
      .reduce((acc, rate) => acc + rate, 0);
  },
);

const preApproveMutation = usePreApproveGroupLoanApplication({
  id: props.groupLoanApplication.id,
  onSuccess: () => {
    uiStore.toast({
      message: 'Solicitudes pre-aprobadas',
      type: 'success',
      position: 'top',
    });
  },
});

const preApprove = handleSubmit(() => {
  const formData: PreApprovePayload = {
    'group_member_loan_application': {},
  };

  props.groupLoanApplication.memberLoanApplications.forEach(groupMemberLoanApplication => {
    const info = values[groupMemberLoanApplication.id];

    if (info.preApproved) {
      setFieldValue(`${groupMemberLoanApplication.id}.rejectionTags`, []);
    }

    formData.group_member_loan_application[groupMemberLoanApplication.id] = {
      'accepted_amount': info.acceptedAmount || groupMemberLoanApplication.amount || 0,
      'interest_rate': info.interestRate,
      'pre_approved': info.preApproved,
      'meets_loan_conditions': info.meetsLoanConditions,
      'consolidated': info.consolidated,
    };

    createTagsMutation.mutate({
      resourceId: groupMemberLoanApplication.id,
      resourceType: 'GroupMemberLoanApplication',
      tags: info.rejectionTags,
      context: 'rejection_tags',
    });
  });

  preApproveMutation.mutate(formData);
});
</script>

<template>
  <base-slide-over
    :open="open"
    @close="$emit('close')"
  >
    <div class="flex flex-col gap-y-8 p-2">
      <div class="flex flex-col rounded-md bg-gray-100 p-4">
        <h1 class="text-sm font-semibold">
          {{ group.name }}
        </h1>
        <h2 class="text-xs text-gray-500">
          {{ group.publicId }}
        </h2>
      </div>
      <div class="flex flex-col gap-y-4">
        <div class="grid grid-cols-2">
          <span class="text-sm font-semibold">
            Monto total
          </span>
          <span class="text-sm text-gray-600">
            {{ groupTotalLoanAmount }}
          </span>
        </div>
        <div class="grid grid-cols-2">
          <span class="text-sm font-semibold">
            Pago por periodo del grupo
          </span>
          <span class="text-sm text-gray-600">
            {{ groupPeriodPayment }}
          </span>
        </div>
        <div class="grid grid-cols-2">
          <span class="text-sm font-semibold">
            Tasa promedio ponderada
          </span>
          <span class="text-sm text-gray-600">
            {{ (weightedAverageInterestRate * 100).toFixed(2) }}%
          </span>
        </div>
      </div>
      <form @submit="preApprove">
        <ul class="flex flex-col overflow-y-scroll">
          <li
            v-for="(groupMemberLoanApplication, index) in groupMemberLoanApplicationsComputedInfoWithLabels"
            :key="index"
            class="py-2"
          >
            <group-member-loan-application-pre-approval
              :group-member-loan-application="groupMemberLoanApplication"
              :rejection-tags="rejectionTags"
            />
          </li>
        </ul>
        <base-button
          v-if="groupLoanApplication.aasmState === 'application_in_review'"
          class="w-full"
          text="Actualizar todas las solicitudes"
          :loading="preApproveMutation.isPending"
          type="submit"
        />
      </form>
    </div>
  </base-slide-over>
</template>
