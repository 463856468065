<script setup lang="ts">
import { computed, ref, toRef } from 'vue';

import { useFetchGroupLoanApplication } from '@/api/groupLoanApplication/queries';
import type { GroupMemberLoanApplication } from '@/api/groupMemberLoanApplication';
import { useFetchGroupMemberLoanApplication } from '@/api/groupMemberLoanApplication/queries';
import { useGenerateSigningUrl } from '@/api/groupMemberLoanApplication/support/mutations';
import BaseButton from '@/components/base-button.vue';
import BasePdfViewer from '@/components/base-pdf-viewer.vue';
import copy from '@/utils/copy-to-clipboard';
import { toCurrency } from '@/utils/filters';

interface Props {
  groupMemberLoanApplication: GroupMemberLoanApplication;
}
const props = defineProps<Props>();

const fetchGroupMemberLoanApplicationQ = useFetchGroupMemberLoanApplication({
  id: toRef(props.groupMemberLoanApplication, 'id'),
  initialData: props.groupMemberLoanApplication,
});

const fetchGroupLoanApplicationQ = useFetchGroupLoanApplication({
  id: toRef(props.groupMemberLoanApplication, 'groupLoanApplicationId'),
});

const signingUrl = ref<string | null>(null);
const name = computed(() => fetchGroupMemberLoanApplicationQ.data?.groupMember.user.fullName);
const phoneNumber = computed(() => (
  fetchGroupMemberLoanApplicationQ.data?.groupMember.user.phoneNumber.replace(/^\+?0*/, '')
));
const groupName = computed(() => fetchGroupLoanApplicationQ.data?.group.name);
const whatsappMessage = computed(() => {
  if (!signingUrl.value) return '';

  return `Hola ${name.value},
En el siguiente enlace puedes firmar tu contrato del crédito grupal con *${groupName.value}*:

${signingUrl.value}
  `;
});
const encodedWhatsappMessage = computed(() => encodeURIComponent(whatsappMessage.value));

const generateSigningUrlMutation = useGenerateSigningUrl({
  groupMemberLoanApplicationId: toRef(props.groupMemberLoanApplication, 'id'),
  onSuccess: (url) => {
    signingUrl.value = url;
  },
});

</script>

<template>
  <div
    v-if="
      !!fetchGroupMemberLoanApplicationQ.data &&
        !!fetchGroupMemberLoanApplicationQ.data.unsignedContractUrl &&
        !!fetchGroupLoanApplicationQ.data
    "
    class="flex flex-1 flex-col gap-y-6 rounded-lg bg-white p-6"
  >
    <h3 class="font-semibold text-black">
      Detalles del contrato
    </h3>
    <div class="grid grid-cols-2 gap-x-8">
      <BasePdfViewer
        :url="fetchGroupMemberLoanApplicationQ.data.unsignedContractUrl"
      />
      <div class="flex flex-col gap-y-8">
        <ul>
          <li>💰 Monto otorgado: {{ toCurrency(fetchGroupMemberLoanApplicationQ.data.acceptedAmount || 0) }}</li>
          <li>📌 Pago semanal: {{ toCurrency(fetchGroupMemberLoanApplicationQ.data.repaymentAmount || 0) }}</li>
          <li>📤 Total a pagar: {{ toCurrency(fetchGroupMemberLoanApplicationQ.data.totalRepaymentAmount || 0) }}</li>
          <li>💵 Costo por mil: {{ toCurrency(fetchGroupMemberLoanApplicationQ.data.costPerThousand || 0) }}</li>
          <li>
            🚨 Multas grupales por atraso:
            {{ toCurrency(fetchGroupLoanApplicationQ.data.delinquentFees.firstDay || 0) }} el primer día, luego
            {{ toCurrency(fetchGroupLoanApplicationQ.data.delinquentFees.daily || 0) }} por cada día adicional de
            atraso.
          </li>
        </ul>

        <div class="flex flex-col gap-y-4">
          <BaseButton
            v-if="!signingUrl"
            text="Generar URL de firma"
            :loading="generateSigningUrlMutation.isPending"
            @click="generateSigningUrlMutation.mutate()"
          />
          <div
            v-else
            class="flex flex-col gap-y-4"
          >
            <code class="overflow-auto whitespace-pre-wrap rounded-lg border bg-gray-100 p-6">
              {{ whatsappMessage }}
            </code>
            <div class="flex items-center justify-between">
              <a
                :href="`https://wa.me/${phoneNumber}?text=${encodedWhatsappMessage}`"
                target="_blank"
                class="text-primary-700"
              >
                Haz clic aquí para enviar el enlace por WhatsApp
              </a>
              <BaseButton
                text="Copiar texto"
                @click="copy(whatsappMessage)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
