
<script setup>
import { ref, watch, computed, defineProps, defineEmits } from 'vue';
import { Switch } from '@headlessui/vue';

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
  label: { type: String, required: true },
  name: { type: String, required: true },
  modelValue: { type: Boolean, required: true },
  size: {
    type: String,
    default: 'md',
    validator: (value) => ['xxs', 'xs', 'sm', 'md', 'lg'].includes(value),
  },
});

const enabled = ref(props.modelValue);

watch(enabled, (newValue) => {
  emit('update:modelValue', newValue);
});

watch(() => props.modelValue, (newVal) => {
  if (newVal !== enabled.value) {
    enabled.value = newVal;
  }
});

const switchSizeClasses = computed(() => {
  switch (props.size) {
  case 'xxs':
    return 'h-4 w-8';
  case 'xs':
    return 'h-5 w-10';
  case 'sm':
    return 'h-6 w-12';
  case 'lg':
    return 'h-8 w-16';
  case 'md':
  default:
    return 'h-7 w-14';
  }
});

const indicatorSizeClasses = computed(() => {
  switch (props.size) {
  case 'xxs':
    return { size: 'h-3 w-3', translate: 'translate-x-4' };
  case 'xs':
    return { size: 'h-4 w-4', translate: 'translate-x-5' };
  case 'sm':
    return { size: 'h-5 w-5', translate: 'translate-x-6' };
  case 'lg':
    return { size: 'h-6 w-6', translate: 'translate-x-8' };
  case 'md':
  default:
    return { size: 'h-6 w-6', translate: 'translate-x-7' };
  }
});

const switchColorClasses = computed(() => {
  if (enabled.value) {
    return props.color === 'primary' ? 'bg-primary-600' : 'bg-green-600';
  }

  return 'bg-gray-100';
});
</script>

<template>
  <Switch
    v-model="enabled"
    :name="name"
    :class="[switchColorClasses, switchSizeClasses]"
    class="relative inline-flex shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75"
  >
    <span
      aria-hidden="true"
      :class="[
        enabled ? indicatorSizeClasses.translate : 'translate-x-0',
        indicatorSizeClasses.size,
      ]"
      class="pointer-events-none inline-block rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out"
    />
  </Switch>
</template>

