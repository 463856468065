<script setup lang="ts">
import { computed } from 'vue';

interface Props {
  label?: string | null;
  color?: 'gray' | 'red' | 'yellow' | 'green' | 'blue' | 'primary' | 'pink';
  size?: 'xxs' | 'xs' | 'sm' | 'base' | 'md';
}

const props = withDefaults(defineProps<Props>(), {
  label: null,
  color: 'gray',
  size: 'base',
});

const sizeClasses = {
  xxs: 'px-[4px] py-[2px] text-[10px]',
  xs: 'px-1.5 py-0.5 text-xs',
  sm: 'px-2 py-1 text-xs',
  base: 'px-2 py-1 text-sm',
  md: 'px-3 py-1.5 text-base',
};

const colorClasses = {
  gray: 'bg-gray-50 text-gray-700 ring-gray-600/10',
  red: 'bg-red-50 text-red-700 ring-red-600/10',
  yellow: 'bg-yellow-50 text-yellow-800 ring-yellow-600/20',
  green: 'bg-green-50 text-green-700 ring-green-600/20',
  blue: 'bg-blue-50 text-blue-700 ring-blue-700/10',
  primary: 'bg-primary-50 text-primary-600 ring-primary-600/10',
  pink: 'bg-pink-50 text-pink-700 ring-pink-700/10',
};

const badgeClasses = computed(
  () => `self-start inline-flex items-center ring-1 ring-inset ring-gray-500/10
      rounded-full ${sizeClasses[props.size]} ${colorClasses[props.color]}`,
);

</script>

<template>
  <span :class="badgeClasses">
    <slot v-if="$slots.default">{{ label }}</slot>
    <template v-else>{{ label }}</template>
  </span>
</template>
