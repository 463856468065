<script setup lang="ts">
import { ref, toRef } from 'vue';

import type { GroupLoanApplication } from '@/api/groupLoanApplication';
import { useFetchGroupLoanApplication } from '@/api/groupLoanApplication/queries';
import BaseBadge from '@/components/base-badge.vue';
import BaseButton from '@/components/base-button.vue';
import CreateInvitationModal from '@/components/support/group-loan-application/create-invitation-modal.vue';
import InvitationRow from '@/components/support/group-loan-application/invitation-row.vue';
import { groupLoanApplicationBadgeColor } from '@/utils/states';

interface Props {
  groupLoanApplication: GroupLoanApplication;
}
const props = defineProps<Props>();

const fetchGroupLoanApplicationQ = useFetchGroupLoanApplication({
  id: toRef(props.groupLoanApplication, 'id'),
  initialData: props.groupLoanApplication,
});

const showCreateInvitationModal = ref(false);
</script>

<template>
  <div
    v-if="fetchGroupLoanApplicationQ.data"
    class="container flex flex-col gap-y-4"
  >
    <div class="flex items-center gap-x-4">
      <h1 class="font-bold">
        {{ fetchGroupLoanApplicationQ.data.group.name }} - <span class="font-bold">Ciclo:</span> {{ fetchGroupLoanApplicationQ.data.cycle }} ({{ fetchGroupLoanApplicationQ.data.id }})
      </h1>
      <base-badge
        :color="groupLoanApplicationBadgeColor(fetchGroupLoanApplicationQ.data.aasmState)"
        :label="$t(`groupLoanApplication.aasmState.${fetchGroupLoanApplicationQ.data.aasmState}`)"
      />
    </div>
    <template v-if="['inviting', 'draft'].includes(fetchGroupLoanApplicationQ.data.aasmState)">
      <div class="flex flex-1 flex-col gap-y-5 rounded-lg bg-white p-4">
        <div class="flex flex-col">
          <h2 class="text-lg font-semibold">
            Presidenta
          </h2>
          <p><span class="font-bold">Nombre:</span> {{ fetchGroupLoanApplicationQ.data.group.president.fullName }}</p>
          <p><span class="font-bold">Teléfono:</span> {{ fetchGroupLoanApplicationQ.data.group.president.phoneNumber }}</p>
        </div>
        <div class="flex flex-col gap-y-2">
          <h2 class="text-lg font-semibold">
            Miembros del grupo
          </h2>
          <div class="grid grid-cols-3 items-center justify-between gap-x-4 rounded-lg p-2">
            <p class="font-bold">
              Nombre
            </p>
            <p class="font-bold">
              Teléfono
            </p>
            <p />
          </div>
          <template
            v-for="groupMember in fetchGroupLoanApplicationQ.data.group.members"
            :key="groupMember.id"
          >
            <invitation-row
              v-if="groupMember.invitation && groupMember.user.fullName"
              :group-loan-application-id="groupLoanApplication.id"
              :invitation-id="groupMember.invitation?.id"
              :full-name="groupMember.user.fullName"
              :phone-number="groupMember.user.phoneNumber"
            />
          </template>
        </div>
        <div class="flex flex-col gap-y-2">
          <div class="flex items-center gap-x-4">
            <h2 class="text-lg font-semibold">
              Invitaciones pendientes
            </h2>
            <base-button
              text="Invitar"
              variant="primary"
              @click="showCreateInvitationModal = true"
            />
          </div>
          <div class="grid grid-cols-3 items-center justify-between gap-x-4 rounded-lg p-2">
            <p class="font-bold">
              Nombre
            </p>
            <p class="font-bold">
              Teléfono
            </p>
            <p />
          </div>
          <template
            v-for="invitation in fetchGroupLoanApplicationQ.data.group.pendingInvitations"
            :key="invitation.id"
          >
            <invitation-row
              v-if="invitation.invitee.fullName"
              :group-loan-application-id="groupLoanApplication.id"
              :invitation-id="invitation.id"
              :full-name="invitation.invitee.fullName"
              :phone-number="invitation.invitee.phoneNumber"
            />
          </template>
        </div>
      </div>
      <create-invitation-modal
        :group-loan-application-id="groupLoanApplication.id"
        :group-id="groupLoanApplication.groupId"
        :show="showCreateInvitationModal"
        @close="showCreateInvitationModal = false"
      />
    </template>
    <template v-else>
      <div class="flex w-full flex-col items-center justify-center rounded-lg bg-white py-6 text-center">
        <p>
          La solicitud está en estado
          <span class="font-bold text-primary-700">
            {{ $t(`groupLoanApplication.aasmState.${fetchGroupLoanApplicationQ.data.aasmState}`) }}.
          </span>
          De momento no hay acciones disponibles.
        </p>
        <p>
          Solo hay acciones disponibles cuando la solicitud está en estado
          {{ $t('groupLoanApplication.aasmState.inviting') }} o
          {{ $t('groupLoanApplication.aasmState.draft') }},
        </p>
      </div>
    </template>
  </div>
</template>
