import { type MutationOptions } from '@tanstack/vue-query';

import type { ApiError } from '@/api';
import { create, type PhoneNumber } from '@/api/phoneNumber';
import useReactiveMutation from '@/composables/useReactiveMutation';

type ValidatePhoneNumberMutationArgs = MutationOptions<PhoneNumber, ApiError, PhoneNumber['number']>;

function useValidatePhoneNumber(options: ValidatePhoneNumberMutationArgs = {}) {
  return useReactiveMutation({
    ...options,
    mutationFn: (phoneNumber) => create(phoneNumber).then(response => response.data.phoneNumber),
  });
}

export { useValidatePhoneNumber };

