<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount, useAttrs, type Component } from 'vue';

import BaseIcon from '@/components/base-icon.vue';
import BaseSpinner from '@/components/base-spinner.vue';

const attrs = useAttrs();

interface Props {
  variant?: 'primary' | 'white' | 'text' | 'red';
  size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg';
  text?: string;
  loading?: boolean;
  leftIcon?: string | Component;
  rightIcon?: string | Component;
}

const props = withDefaults(defineProps<Props>(), {
  variant: 'primary',
  size: 'md',
  text: undefined,
  loading: false,
  leftIcon: undefined,
  rightIcon: undefined,
});

const sizeClasses = {
  xxs: 'px-2 py-0 text-[12px] rounded',
  xs: 'px-2 py-0.5 text-xs rounded',
  sm: 'px-4 py-1.5 text-xs rounded',
  md: 'px-4 py-2 text-sm rounded-md',
  lg: 'px-6 py-3 text-base rounded-lg',
};

const variantClasses = {
  primary: 'bg-primary-600 shadow-sm text-white hover:bg-primary-700 focus:ring-primary-600',
  white: `bg-white shadow-sm text-primary-600 transition duration-300
    hover:ring-1 ring-primary-600 border border-primary-600 focus:ring-primary-600`,
  text: `text-primary-600 border border-transparent hover:border-primary-600 transition
    duration-300 focus:ring-primary-600`,
  red: `bg-red-500 text-white border border-transparent hover:border-red-600
    transition duration-300 focus:ring-red-600`,
};

const disabledVariantClasses = {
  primary: 'bg-primary-600/50 shadow-sm text-white hover:bg-primary-600/60',
  text: 'text-primary-600 border border-transparent opacity-50',
  white: 'bg-gray-50 shadow-sm text-primary-400 transition duration-300 border border-primary-600',
  red: '',
};

const buttonClasses = computed(() => [
  'focus:outline-none',
  'focus:ring-2',
  'focus:ring-offset-2',
  attrs.disabled ? disabledVariantClasses[props.variant] : variantClasses[props.variant],
  attrs.disabled ? 'cursor-not-allowed' : 'cursor-pointer',
  sizeClasses[props.size],
  'relative',
]);

function captureClickEvent(e: Event) {
  if (attrs.href && attrs.disabled) e.preventDefault();
}

const buttonRef = ref<HTMLElement | null>(null);
onMounted(() => buttonRef.value?.addEventListener('click', captureClickEvent, true));
onBeforeUnmount(() => buttonRef.value?.removeEventListener('click', captureClickEvent, true));
</script>

<template>
  <component
    :is="$attrs.href ? 'a' : 'button'"
    ref="buttonRef"
    :class="buttonClasses"
  >
    <slot>
      <div :class="[{ 'invisible': loading }, 'flex flex-row items-center justify-center']">
        <base-icon
          v-if="leftIcon"
          :icon="leftIcon"
          class="mr-2 h-4 w-auto"
        />
        <slot>
          <span class="font-semibold">
            {{ text }}
          </span>
        </slot>
        <base-icon
          v-if="rightIcon"
          :icon="rightIcon"
          class="ml-2 mt-[2px] h-4 w-auto"
        />
      </div>
      <span
        v-if="loading"
        class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
      >
        <base-spinner
          :size="4"
        />
      </span>
    </slot>
  </component>
</template>

