<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { type PromoterPresidentInvite } from '@/api/promoterPresidentInvite';
import BaseDisclosure from '@/components/base-disclosure.vue';
import BaseHeaderTable from '@/components/base-header-table.vue';
import baseImageWithControls from '@/components/base-image-with-controls.vue';
import useFilteredAttrs from '@/composables/useFilteredAttrs';

interface Props {
  promoterPresidentInvite?: PromoterPresidentInvite;
}

const props = defineProps<Props>();

const { t } = useI18n();
const {
  knownSince, knownFrom, additionalInformation, previousCreditInstitution, paymentSheet,
} = (props.promoterPresidentInvite ?? {});

const showPromoterInfo = computed(() => (
  knownSince || knownFrom || additionalInformation || previousCreditInstitution || paymentSheet
));

function getKnownSinceLabel() {
  return knownSince ? t(`promoterPresidentInvites.knownSince.${knownSince}`) : '';
}

function getKnownFromLabel() {
  return knownFrom ? t(`promoterPresidentInvites.knownFrom.${knownFrom}`) : '';
}

const filteredAttrs = useFilteredAttrs({ excluded: ['class'] });
</script>

<template>
  <div
    class="rounded-lg bg-gray-100"
    :class="$attrs.class"
  >
    <base-disclosure
      button-text="Información proporcionada por el asesor"
      v-bind="filteredAttrs"
    >
      <div
        v-if="showPromoterInfo"
        className="flex flex-col gap-y-4"
      >
        <base-header-table
          title=""
          size="sm"
          :headers="[
            'Conoce al grupo porque', 'Las conoce hace', 'Financiera anterior', 'Información adicional'
          ] as const"
          :rows="[[
            getKnownFromLabel(),
            getKnownSinceLabel(),
            previousCreditInstitution ?? '',
            additionalInformation ?? '',
          ]]"
        />
        <div class="p-4">
          <base-image-with-controls
            v-if="paymentSheet?.fileUrl"
            :src="paymentSheet.fileUrl"
            class="h-60 w-60"
            caption="Hoja de pagos"
          />
        </div>
      </div>
      <span
        v-else
        class="ml-4 italic text-gray-700"
      >
        El asesor no proporcionó información
      </span>
    </base-disclosure>
  </div>
</template>
