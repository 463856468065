
import { type MutationOptions, useQueryClient } from '@tanstack/vue-query';
import { toRef } from 'vue';

import type { ApiError } from '@/api';
import { tagsKey } from '@/api/queryKeys/tags';
import { create, type CreateTagPayload } from '@/api/tags';
import useReactiveMutation from '@/composables/useReactiveMutation';

type UseCreateTags = MutationOptions<void, ApiError, CreateTagPayload>

function useCreateTags(options: UseCreateTags) {
  const queryClient = useQueryClient();

  return useReactiveMutation({
    mutationFn: (values) => create(values).then((response) => response.data),

    onSuccess: (data, variables, context) => {
      options.onSuccess?.(data, variables, context);
      queryClient.invalidateQueries({
        queryKey: tagsKey({
          resourceId: toRef(variables.resourceId),
          resourceType: toRef(variables.resourceType),
          context: toRef(variables.context),
        }),
      });
    },
    ...options,
  });
}

export { useCreateTags };
