<script setup lang="ts">
import { useField } from 'vee-validate';
import { toRef, watch } from 'vue';

import { useValidatePhoneNumber } from '@/api/phoneNumber/mutations';
import VeeValidatePhoneInput from '@/components/vee-validate/vee-validate-phone-input.vue';

interface Props {
  name: string;
  label: string;
}
const props = defineProps<Props>();

const { value, errors, setErrors } = useField<string>(toRef(props, 'name'));

const validatePhoneNumberMutation = useValidatePhoneNumber({
  onSuccess: (validationResponse) => {
    if (validationResponse.validated) return;

    setErrors(['El número de teléfono no tiene una cuenta de Whatsapp asociada']);
  },
  onError: (error) => {
    setErrors([error.message]);
  },
});

watch(errors, (newErrors, oldErrors) => {
  if (newErrors.length > 0 || oldErrors.length === 0) return;

  validatePhoneNumberMutation.mutate(value.value);
});

</script>

<template>
  <vee-validate-phone-input
    :name="name"
    :label="label"
    v-bind="$attrs"
    :loading="validatePhoneNumberMutation.isPending"
    :disabled="validatePhoneNumberMutation.isPending"
  />
</template>
