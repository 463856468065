<script setup lang="ts">
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/vue';
import { ChevronUpIcon } from '@heroicons/vue/20/solid';
import { ClockIcon } from '@heroicons/vue/24/outline';
import { formatDistanceToNow } from 'date-fns';
import { ref, computed, type Ref } from 'vue';

import type { IUser } from '@/api/currentUser';
import type { GroupMemberLoanApplication, Section } from '@/api/groupMemberLoanApplication';
import { useFetchCreditPolicyResult } from '@/api/groupMemberLoanApplication/creditPolicyResult/queries';
import { useFetchTags } from '@/api/tags/queries';
import BaseBadge from '@/components/base-badge.vue';
import BaseHeaderTable from '@/components/base-header-table.vue';
import BaseTabs from '@/components/base-tabs.vue';
import Comments from '@/components/comments.vue';
import ConsolidatedCreditReportLoansTable from '@/components/reviews/consolidated-credit-report-loans-table.vue';
import ConsolidatedCreditReport from '@/components/reviews/consolidated-credit-report.vue';
import GroupMemberLoanApplicationKyc from '@/components/reviews/group-member-loan-application-kyc.vue';
import useStrictInject from '@/composables/useStrictInject';
import { getGroupLoansFromConsolidatedCreditReport } from '@/utils/creditReport';
import { currentUserKey } from '@/utils/keys';
import { groupMemberLoanApplicationBadgeColor } from '@/utils/states';

const currentUser = useStrictInject<Ref<IUser>>(currentUserKey);

const props = defineProps<{
  groupMemberLoanApplication: GroupMemberLoanApplication
}>();

const groupMember = computed(() => props.groupMemberLoanApplication.groupMember);
const user = computed(() => groupMember.value.user);

const selectedTab = ref(0);

const SECTIONS_TO_REMOVE = ['address'];
const FIELDS_TO_REMOVE = ['firstName', 'paternalSurname', 'maternalSurname'];

const loanApplicationSchema = computed(
  () => props.groupMemberLoanApplication.form.data,
);

const loanApplicationFilteredOrderedSections = computed(
  () => Object.entries(loanApplicationSchema.value.sections || [])
    .filter(([key, _]) => !SECTIONS_TO_REMOVE.includes(key))
    .map(([_, section]) => section)
    .sort((a, b) => a.order - b.order),
);

function filteredSortedFields(fields: Section[keyof Section]['fields']) {
  return Object.entries(fields)
    .filter(([_, field]) => Boolean(field.value))
    .filter(([key, _]) => !FIELDS_TO_REMOVE.includes(key))
    .map(([_, value]) => value)
    .sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
}

const canGoToSupportForm = computed(() => (
  ['draft', 'in_kyc', 'contract_signature_pending'].includes(props.groupMemberLoanApplication.aasmState)
));

const groupMemberLoanApplicationId = computed(() => props.groupMemberLoanApplication.id);

const rejectionTagsQ = useFetchTags({
  resourceId: groupMemberLoanApplicationId,
  resourceType: 'GroupMemberLoanApplication',
  context: 'rejection_tags',
});

const creditPolicyResultQ = useFetchCreditPolicyResult({ groupMemberLoanApplicationId });
const hardDealBreakerPolicies = computed(
  () => creditPolicyResultQ.data?.policies?.filter((policy) => !policy.approved && policy.hardDealBreaker) ?? [],
);
const softDealBreakerPolicies = computed(
  () => creditPolicyResultQ.data?.policies?.filter((policy) => !policy.approved && policy.softDealBreaker) ?? [],
);
</script>

<template>
  <base-tabs
    :tab-titles="groupMemberLoanApplication.consolidatedCreditReport ? ['Solicitud', 'Reporte CC'] : ['Solicitud']"
    :selected-tab-index="selectedTab"
  >
    <template #tab-0>
      <div class="flex flex-col gap-y-8 p-2 md:gap-y-10">
        <div class="flex flex-col justify-between gap-y-4 md:flex-row">
          <div class="flex flex-col gap-y-3">
            <base-badge
              v-if="groupMember.active"
              :color="groupMemberLoanApplicationBadgeColor(groupMemberLoanApplication.aasmState)"
              :label="$t(`groupMemberLoanApplication.aasmState.${groupMemberLoanApplication.aasmState}`)"
            />
            <base-badge
              v-else
              color="red"
              label="Inactivo"
            />
            <div
              v-if="groupMemberLoanApplication.preApproved"
              class="flex flex-row gap-x-7 md:gap-x-12"
            >
              <div
                v-if="groupMemberLoanApplication.acceptedAmount"
                class="flex flex-col gap-y-1"
              >
                <p class="text-sm font-medium text-gray-700">
                  💰 Monto otorgado
                </p>
                <p class="text-lg font-medium">
                  {{ $filters.toCurrency(groupMemberLoanApplication.acceptedAmount) }}
                </p>
              </div>
              <div
                v-if="groupMemberLoanApplication.interestRate"
                class="flex flex-col gap-y-1"
              >
                <p class="text-sm font-medium text-gray-700">
                  📌 Tasa de interés
                </p>
                <p class="text-lg font-medium">
                  {{ (groupMemberLoanApplication.interestRate * 100).toFixed(2) }}%
                </p>
              </div>
            </div>
          </div>
          <div class="flex flex-col items-center gap-x-4 gap-y-1 self-start md:flex-row">
            <a
              v-if="currentUser.support && canGoToSupportForm"
              :href="`/support/group_member_loan_applications/${groupMemberLoanApplication.id}`"
              class="text-primary-600"
              target="_blank"
            >
              Ir al formularo de soporte
            </a>
            <base-button
              :href="`https://wa.me/${user.phoneNumber}`"
              text="Abrir chat de WhatsApp"
              variant="white"
            />
          </div>
        </div>
        <div
          v-if="hardDealBreakerPolicies?.length > 0 || softDealBreakerPolicies?.length > 0"
          class="flex flex-col gap-y-1"
        >
          <p
            v-if="!creditPolicyResultQ.data?.approved"
            class="font-semibold"
          >
            Se sugiere rechazar
          </p>
          <div class="flex flex-col gap-y-1">
            <p
              v-if="creditPolicyResultQ.data?.approved"
              className="text-sm"
            >
              Detectamos que se incumplen las siguientes políticas
            </p>
            <div class="flex flex-wrap gap-x-3 gap-y-1">
              <base-badge
                v-for="hardDealBreakerPolicy in hardDealBreakerPolicies"
                :key="hardDealBreakerPolicy.id"
                size="xs"
                color="red"
              >
                {{ $t(`groupMemberLoanApplication.rejectionTags.${hardDealBreakerPolicy.rejectionTag}`) }}
              </base-badge>
              <base-badge
                v-for="softDealBreakerPolicy in softDealBreakerPolicies"
                :key="softDealBreakerPolicy.id"
                size="xs"
                color="yellow"
              >
                {{ $t(`groupMemberLoanApplication.rejectionTags.${softDealBreakerPolicy.rejectionTag}`) }}
              </base-badge>
            </div>
          </div>
        </div>
        <div
          v-if="groupMemberLoanApplication.applicationSubmittedAt || groupMemberLoanApplication.kycSubmittedAt"
          class="flex flex-col gap-y-1 md:gap-y-3"
        >
          <div
            v-if="groupMemberLoanApplication.applicationSubmittedAt"
            class="flex flex-row items-center gap-x-1"
          >
            <ClockIcon
              class="h-4 w-4 text-primary-600/70"
            />
            <span class="text-sm text-gray-700">
              Solicitud enviada
              {{
                formatDistanceToNow(
                  new Date(groupMemberLoanApplication.applicationSubmittedAt
                  ), { addSuffix: true }
                )
              }}
            </span>
          </div>
          <div
            v-if="groupMemberLoanApplication.kycSubmittedAt"
            class="flex flex-row items-center gap-x-1"
          >
            <ClockIcon
              class="h-4 w-4 text-primary-600/70"
            />
            <span class="text-sm text-gray-700">
              KYC enviado
              {{ formatDistanceToNow(new Date(groupMemberLoanApplication.kycSubmittedAt), { addSuffix: true }) }}
            </span>
          </div>
          <div
            v-if="rejectionTagsQ.data"
            className="flex gap-x-1"
          >
            <base-badge
              v-for="rejectionTag in rejectionTagsQ.data"
              :key="rejectionTag.id"
              color="red"
              :label="$t(`groupMemberLoanApplication.rejectionTags.${rejectionTag.name}`)"
              size="xs"
            />
          </div>
        </div>
        <div class="grid grid-cols-1 gap-6 md:grid-cols-2 md:gap-12">
          <ul
            v-if="groupMember.invitation"
            class="flex flex-col gap-y-1"
          >
            <span class="mb-3 text-sm font-semibold text-primary-600">
              Invitación de {{ user.firstName }}
            </span>
            <li
              v-for="item in [
                { label: 'Relación con la presidenta', value: groupMember.invitation.relationship },
                { label: 'Se conocen hace', value: groupMember.invitation.humanYearsKnown },
              ]"
              :key="item.label"
              class="text-xs"
            >
              <b>{{ item.label }}</b>: {{ item.value }}
            </li>
          </ul>
          <ul class="flex flex-col gap-y-1">
            <span class="mb-3 text-sm font-semibold text-primary-600">
              Información personal
            </span>
            <li
              v-for="item in [
                { label: 'ID', value: groupMemberLoanApplication.id },
                { label: 'Public ID', value: groupMemberLoanApplication.publicId },
                { label: 'Nombre', value: user.fullName },
                { label: 'Correo electrónico', value: user.email },
                { label: 'Teléfono', value: user.phoneNumber },
                { label: 'Estado', value: user.state },
                { label: 'Municipio', value: user.municipality },
                { label: 'Colonia', value: user.colony },
                { label: 'Calle', value: user.street },
                { label: 'Número exterior', value: user.externalNumber },
                { label: 'Número interior', value: user.internalNumber },
                { label: 'Código postal', value: user.postalCode },
                { label: 'Rol', value: groupMember.humanRole },
              ]"
              :key="item.label"
              class="text-xs"
            >
              <b>{{ item.label }}</b>: {{ item.value }}
            </li>
          </ul>
          <ul
            v-for="section in loanApplicationFilteredOrderedSections"
            :key="section.order"
            class="flex flex-col gap-y-1"
          >
            <span class="mb-3 text-sm font-semibold text-primary-600">
              {{ section.title }}
            </span>
            <template
              v-if="Object.values(section.fields).some(field => Boolean(field.value))"
            >
              <li
                v-for="item in filteredSortedFields(section.fields).map((field) => ({
                  label: field.label,
                  value: field.value,
                }))"
                :key="item.label"
                class="text-xs"
              >
                <b>{{ item.label }}</b>: {{ item.value }}
              </li>
            </template>
            <span
              v-else
              class="text-xs text-gray-700"
            >
              No hay respuestas aún
            </span>
          </ul>
        </div>
        <Disclosure v-slot="{ open: disclosureOpen }">
          <DisclosureButton
            class="flex flex-col gap-x-2 self-start rounded-lg font-semibold"
          >
            <div class="flex items-center">
              <p>Créditos grupales</p>
              <ChevronUpIcon
                :class="{'rotate-180': !disclosureOpen}"
                class="h-5 w-5 text-primary-900"
              />
            </div>
            <p
              v-if="!disclosureOpen"
              class="text-xs font-normal text-gray-500"
            >
              Haz click aquí para desplegar la información
            </p>
          </DisclosureButton>
          <DisclosurePanel>
            <consolidated-credit-report-loans-table
              :loans="getGroupLoansFromConsolidatedCreditReport(
                groupMemberLoanApplication.consolidatedCreditReport
              )"
            />
          </DisclosurePanel>
        </Disclosure>
        <base-header-table
          v-if="groupMemberLoanApplication.kycSubmittedAt"
          size="sm"
          title="Referencias"
          :headers="['Nombre', 'Teléfono', 'Relación']"
          :rows="groupMemberLoanApplication.references.map((reference) => [
            reference.name ?? '',
            reference.phoneNumber ?? '',
            reference.relationship ?? '',
          ])"
        />
        <group-member-loan-application-kyc
          :group-member-loan-application="groupMemberLoanApplication"
        />
        <comments
          name="comment[body]"
          resource-type="GroupMemberLoanApplication"
          :resource-id="groupMemberLoanApplication.id"
        />
      </div>
    </template>
    <template #tab-1>
      <consolidated-credit-report
        v-if="groupMemberLoanApplication.consolidatedCreditReport"
        :group-member-loan-application="groupMemberLoanApplication"
      />
      <template v-else-if="!groupMemberLoanApplication.creditConsultationConsent">
        <p class="p-8 text-center text-lg">
          Aún <span class="font-bold text-purple-700">no</span> se ha otorgado el consentimiento para la consulta de buró de crédito
        </p>
      </template>
      <template v-else>
        <p class="p-8 text-center text-lg">
          Hubo un error al cargar el reporte de buró de crédito
        </p>
      </template>
    </template>
  </base-tabs>
</template>
