<script setup lang="ts">
import { useField } from 'vee-validate';

import useFilteredAttrs from '@/composables/useFilteredAttrs';

interface Props {
  label?: string;
  description?: string;
  name: string;
}

const props = defineProps<Props>();

const {
  value,
  handleChange,
  errorMessage,
  meta,
  setTouched,
} = useField<boolean>(() => props.name);

function handleInput(event: Event) {
  const target = event.target as HTMLInputElement;

  handleChange(target.checked);
}

const filteredAttrs = useFilteredAttrs({ excluded: ['class'] });
</script>

<template>
  <div class="flex items-start gap-x-3">
    <div class="flex h-6 items-center">
      <input
        v-bind="filteredAttrs"
        :id="name"
        :value="value"
        :checked="value"
        :aria-describedby="`${name}-description`"
        :name="name"
        type="checkbox"
        class="h-4 w-4 rounded border border-gray-300 text-primary-600 focus:ring focus:ring-primary-600"
        @input="handleInput"
        @focus="setTouched(false)"
      >
    </div>
    <div class="flex flex-col items-start gap-y-1">
      <div class="text-left text-sm leading-6">
        <slot name="label">
          <label
            v-if="label"
            :for="name"
            class="text-gray-900"
          >
            {{ label }}
          </label>
        </slot>
        <p
          v-if="description"
          :id="`${name}-description`"
          class="text-sm text-gray-500"
        >
          {{ description }}
        </p>
      </div>
      <span
        v-if="errorMessage"
        class="mt-1 text-xs text-red-500 sm:text-sm"
        :class="{
          'animate-bounce': meta.touched,
        }"
      >
        {{ errorMessage }}
      </span>
    </div>
  </div>
</template>
