import type { AxiosResponse } from 'axios';

import type { Group } from '@/api/group';
import type { GroupMemberLoanApplication } from '@/api/groupMemberLoanApplication';
import type { CCLoan } from '@/api/groupMemberLoanApplication/CC';
import { api } from '@/api/index';
import type { BaseUser } from '@/api/user';

type AasmState = 'inviting' | 'draft' | 'application_in_review' | 'application_rejected' | 'in_kyc' |
  'kyc_in_review' | 'contracts_signature_pending' | 'contracts_signed' | 'disbursed' | 'canceled';

interface BaseGroupLoanApplication {
  id: number;
  groupId: number;
  aasmState: AasmState;
  contractsSignedAt: string | null;
  createdAt: string;
  updatedAt: string;
  submittedAt: string | null;
  repaymentPeriods: number;
  cycle: number;
  meetingAt: string | null;
  cancellationReason: string | null;
}

interface GroupLoanApplication extends BaseGroupLoanApplication {
  totalRequestedAmount: number;
  delinquentFees: {
    firstDay: number;
    daily: number;
  };
  group: Group;
  memberLoanApplications: GroupMemberLoanApplication[];
  inactiveMemberLoanApplications: GroupMemberLoanApplication[];
}

interface ConsolidatedLoan {
  groupMemberLoanApplicationIds: number[];
  loans: CCLoan[];
  mostRecentLoanStartDate: string;
  mostRecentDelinquentBalanceDate: string | null;
  mostRecentDelinquentBalanceAmount: number;
  mostRecentDelinquentPaymentsCount: number;
  worstDelinquencyStrike: number;
}

interface CompositionChanges {
  left: BaseUser[];
  joined: BaseUser[];
}

type GroupLoanApplicationResponse = AxiosResponse<{ groupLoanApplication: BaseGroupLoanApplication }>;

const BASE_PATH = '/api/internal/group_loan_applications';

type FetchGroupLoanApplicationsRansackParams = {
  aasmStateEq?: GroupLoanApplication['aasmState']
  sorts?: string;
}

type GetAllParams = {
  page: { number?: number, size?: number };
  q?: FetchGroupLoanApplicationsRansackParams
}

function getAll(params?: GetAllParams) {
  return api.get<{ nextCursor: number | undefined, groupLoanApplications: GroupLoanApplication[] }>(
    BASE_PATH, { params },
  );
}

function get(id: number) {
  return api.get<{ groupLoanApplication: GroupLoanApplication }>(`${BASE_PATH}/${id}`);
}

function update(id: number, groupLoanApplication: Partial<BaseGroupLoanApplication>) {
  return api.put<{ groupLoanApplication: GroupLoanApplication }>(`${BASE_PATH}/${id}`, { groupLoanApplication });
}

export type {
  AasmState,
  BaseGroupLoanApplication,
  GroupLoanApplication,
  GroupLoanApplicationResponse,
  ConsolidatedLoan,
  FetchGroupLoanApplicationsRansackParams,
  CompositionChanges,
};

export { getAll, get, update };
