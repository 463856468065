import { type InjectionKey, inject } from 'vue';

export default function useStrictInject<T>(key: InjectionKey<T>): T {
  const resolved = inject(key);
  if (resolved === undefined || resolved === null) {
    throw new Error(`Could not resolve ${key.toString()}`);
  }

  return resolved;
}
