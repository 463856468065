<script setup>
import { ChevronDownIcon, CheckCircleIcon, XCircleIcon } from '@heroicons/vue/24/outline';
import { useField } from 'vee-validate';
import { ref, watch, onMounted, onUnmounted } from 'vue';

import BaseIcon from '@/components/base-icon.vue';
import BaseSpinner from '@/components/base-spinner.vue';
import useErrorBounceOnFormSubmit from '@/composables/useErrorBounceOnFormSubmit.js';
import useFilteredAttrs from '@/composables/useFilteredAttrs.ts';
import { COUNTRIES } from '@/utils/countries.ts';

function findCountryCode(hint) {
  const code = hint.toUpperCase();
  const countryIndex = COUNTRIES.findIndex(country => country.key === code);
  if (countryIndex === -1) {
    return COUNTRIES[0];
  }

  return COUNTRIES[countryIndex];
}

function findCountryCodeFromPhoneNumber(phoneNumber) {
  if (!phoneNumber) return null;

  const numericPhoneNumber = phoneNumber.replace(/\D/g, '');

  return COUNTRIES.find(country => numericPhoneNumber.startsWith(country.code));
}

function removeCountryCode(value, code) {
  if (value) {
    const countryCode = code;

    if (value.startsWith('+')) {
      return value.replace(`+${countryCode}`, '');
    } else if (
      value.startsWith(countryCode)) {
      return value.replace(countryCode, '');
    }
  }

  return value;
}

const emit = defineEmits(['update:modelValue', 'blur']);
const props = defineProps({
  label: { type: String, required: true },
  description: { type: String, default: '' },
  value: { type: String, default: '' },
  modelValue: { type: String, default: '' },
  name: { type: String, required: true },
  type: { type: String, default: 'text' },
  error: { type: String, default: '' },
  countryHint: { type: String, default: 'mx' },
  helperIcon: { type: String, default: 'whatsapp' },
  helperTooltip: {
    type: String,
    default: 'Asegúrate de que el formato ' +
    ' de Whatsapp comience con +52, no tenga espacio y tenga 10 dígitos',
  },
  validateOnBlur: { type: Boolean, default: true },
  validating: { type: Boolean, default: false },
  validated: { type: Boolean, default: null },
});

const {
  value: inputValue,
  validate,
  errorMessage,
  handleChange,
} = useField(
  () => props.name,
  undefined,
  { initialValue: props.modelValue || props.value, validateOnValueUpdate: false },
);

const selectedCountry = ref(findCountryCodeFromPhoneNumber(props.value) || findCountryCode(props.countryHint));
const visibleInputValue = ref(removeCountryCode(inputValue.value, selectedCountry.value.code));

const selectOpen = ref(false);

const toggleSelectButtonRef = ref(null);
const selectRef = ref(null);

function handleClickOutsideSelect(event) {
  if (!selectRef.value?.contains(event.target) && !toggleSelectButtonRef.value.contains(event.target)) {
    selectOpen.value = false;
  }
}

onMounted(() => {
  document.addEventListener('click', handleClickOutsideSelect);
});
onUnmounted(() => {
  document.removeEventListener('click', handleClickOutsideSelect);
});

function selectCountry(country, shouldValidate = false) {
  selectedCountry.value = country;
  selectOpen.value = false;

  const valueWithCountryCode = removeCountryCode(inputValue.value, selectedCountry.value.code);
  handleChange(`+${country.code}${(valueWithCountryCode) || ''}`, shouldValidate);
}

watch(() => props.countryHint, (newVal) => {
  const countryCode = findCountryCode(newVal);
  selectCountry(countryCode);
});

function updateVisibleInputValue(newVal) {
  visibleInputValue.value = newVal;
  const valueWithCountryCode = `+${selectedCountry.value.code}${newVal}`.replaceAll(' ', '');

  handleChange(valueWithCountryCode, true);
  emit('update:modelValue', valueWithCountryCode);
}

function onPropValueChange(newVal) {
  if (newVal !== inputValue.value) {
    inputValue.value = newVal;
    // handleChange(newVal, true);

    const valueWithoutCountryCode = removeCountryCode(newVal, selectedCountry.value.code);
    if (valueWithoutCountryCode !== visibleInputValue.value) {
      visibleInputValue.value = valueWithoutCountryCode;
    }
  }
}

watch(() => props.modelValue, onPropValueChange);
watch(() => props.value, onPropValueChange);
watch(inputValue, (newVal) => {
  if (newVal) {
    visibleInputValue.value = removeCountryCode(newVal, selectedCountry.value.code);
  } else {
    visibleInputValue.value = '';
  }
});

function handlePaste(event) {
  event.preventDefault();
  const paste = (event.clipboardData || window.clipboardData).getData('text');
  const valueWithoutCountryCode = removeCountryCode(paste, selectedCountry.value.code)
    .replaceAll(' ', '');
  updateVisibleInputValue(valueWithoutCountryCode);
}

const filteredAttrs = useFilteredAttrs({ excluded: ['class'] });

const errorRef = ref(null);
useErrorBounceOnFormSubmit(errorRef);
</script>

<template>
  <div :class="$attrs.class || 'flex flex-col items-start w-full'">
    <label
      :for="name"
      class="block text-sm font-medium leading-6 text-gray-900"
    >
      {{ label }}
    </label>
    <p
      v-if="description"
      class="mt-1.5 text-xs font-light text-gray-700"
    >
      {{ description }}
    </p>
    <div class="relative mt-2 w-full">
      <div
        :class="[`box-border flex w-full flex-row items-center overflow-hidden rounded-md border
              border-transparent bg-white px-1.5 py-1 shadow-sm ring-1 ring-inset transition duration-300
              ease-in-out focus-within:ring-2 focus-within:ring-primary-600`,
                 (errorMessage || error) ?
                   'border-red-400'
                   : 'ring-gray-300 hover:border-primary-500'
        ]"
      >
        <button
          ref="toggleSelectButtonRef"
          class="-m-1 mr-1 rounded-[4px] bg-primary-50 py-1 pr-1"
          type="button"
          @click="selectOpen = !selectOpen"
        >
          <div class="flex flex-row items-center px-2">
            <div
              v-if="selectedCountry"
              class="flex flex-row items-center gap-x-2"
            >
              <span class="text-xl">
                {{ selectedCountry.emoji }}
              </span>
              <span class="text-sm">
                +{{ selectedCountry.code }}
              </span>
            </div>
            <chevron-down-icon
              :class="[
                'ml-2 h-3 w-3 fill-current text-gray-700',
                { 'rotate-180': !selectOpen }
              ]"
            />
          </div>
        </button>
        <div
          v-if="selectOpen"
          ref="selectRef"
          class="absolute top-12 z-30 h-[84px] w-40 overflow-scroll rounded-md border border-primary-600 bg-white py-2 shadow sm:h-20 sm:w-40"
        >
          <button
            v-for="country in COUNTRIES"
            :key="country.name"
            class="flex w-full flex-row items-center gap-x-2 px-2 py-0.5 text-gray-700 hover:bg-primary-600 hover:text-white"
            type="button"
            @click="selectCountry(country, true)"
          >
            <span class="text-xl">
              {{ country.emoji }}
            </span>
            <span class="text-ellipsis whitespace-nowrap">
              {{ country.name }}
            </span>
            <span class="text-sm">
              +{{ country.code }}
            </span>
          </button>
        </div>
        <input
          :name="name"
          type="hidden"
          :value="inputValue"
          v-bind="filteredAttrs"
        >
        <input
          class="w-full border-none bg-transparent p-1 text-left text-gray-900 outline-none placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6"
          :value="visibleInputValue"
          @input="(e) => updateVisibleInputValue(e.target.value)"
          @blur="() => {
            $emit('blur');
            validateOnBlur && validate();
          }"
          @paste="handlePaste"
        >
        <span
          v-if="validating"
          class="absolute right-4 top-1/2 -translate-y-1/2"
        >
          <base-spinner
            :size="4"
          />
        </span>
        <base-icon
          v-else-if="validated === true"
          v-tooltip="'El número de teléfono fue validado'"
          :icon="CheckCircleIcon"
          class="mx-2 h-7 w-7 text-green-400"
        />
        <base-icon
          v-else-if="validated === false"
          v-tooltip="'El número de teléfono no pudo ser validado'"
          :icon="XCircleIcon"
          class="mx-2 h-7 w-7 text-red-400"
        />
        <base-icon
          v-else-if="helperIcon"
          v-tooltip="helperTooltip"
          :icon="helperIcon"
          class="mx-2 h-5 w-auto fill-current text-gray-400"
        />
      </div>
    </div>
    <span
      v-if="validating"
      class="mt-1 text-xs text-gray-500"
    >
      Estamos validanto el número de telefono...
    </span>
    <span
      v-else-if="errorMessage || error"
      ref="errorRef"
      class="mt-1 text-xs text-red-500 sm:text-sm"
    >
      {{ errorMessage || error }}
    </span>
  </div>
</template>
