import { api } from '@/api';

interface CurpValidationResponse {
  curp: string;
  firstName: string;
  paternalSurname: string;
  maternalSurname: string;
  sex: 'male' | 'female';
  birthDate: string;
}

function validate(curp: string) {
  return api.post<CurpValidationResponse>('/api/internal/valida_curp/curp_validations', { curp });
}

export type { CurpValidationResponse };

export { validate };
