<script setup lang="ts">
import '@vueform/multiselect/themes/default.css';
import Multiselect from '@vueform/multiselect';
import { useField } from 'vee-validate';
import { ref, toRef } from 'vue';

import useFilteredAttrs from '@/composables/useFilteredAttrs';

interface Option {
  label: string;
  value: string | number | boolean;
}

interface Props {
  label?: string;
  description?: string;
  name: string;
  options: Option[];
  selected?: string | number;
  size?: 'sm' | 'md';
  withUndefinedValue?: boolean;
  mode?: 'single' | 'multiple' | 'tags';
}

const props = withDefaults(defineProps<Props>(), {
  label: '',
  description: '',
  selected: '',
  value: '',
  size: 'md',
  withUndefinedValue: true,
  mode: 'single',
});

const showOptions = ref<boolean>(false);
const filteredAttrs = useFilteredAttrs({ excluded: ['class'] });

const {
  value: inputValue,
  errorMessage,
  meta,
  setTouched,
} = useField(toRef(props, 'name'));
</script>
<template>
  <div
    :class="$attrs.class"
    class="flex flex-col"
  >
    <label
      v-if="label"
      :for="name"
      class="self-start text-left font-medium leading-6 text-gray-900"
    >
      {{ label }}
    </label>
    <p
      v-if="description"
      class="mt-1.5 text-xs font-light text-gray-700"
    >
      {{ description }}
    </p>
    <div class="mt-2 flex flex-col">
      <multiselect
        v-bind="filteredAttrs"
        v-model="inputValue"
        :name="name"
        :options="options"
        :mode="mode"
        append-to-body
        :classes="{
          containerActive: 'ring ring-primary-600 ring-opacity-30',
          tag: 'bg-primary-600 text-white text-sm font-semibold py-0.5 pl-2 rounded mr-1 mb-1 \
                flex items-center whitespace-nowrap min-w-0 rtl:pl-0 rtl:pr-2 rtl:mr-0 rtl:ml-1',
          dropdown: [
            'max-h-96 absolute -left-px -right-px top-0 transform translate-y-full',
            'border border-gray-300 -mt-px overflow-y-scroll z-50 bg-white flex flex-col rounded-b',
            !showOptions && ['hidden']
          ]
        }"
        no-options-text="No hay más opciones"
        no-results-text="No hay resultados"
        @open="showOptions = true"
        @close="showOptions = false"
        @focus="setTouched(false)"
      />
      <p
        v-if="errorMessage"
        :class="{
          'animate-bounce': meta.touched,
          'text-red-500': errorMessage,
        }"
      >
        {{ errorMessage }}
      </p>
    </div>
  </div>
</template>
