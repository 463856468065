import { api } from '@/api';
import type { BaseGroupInvitation } from '@/api/groupInvitation';

interface UserForm {
  firstName: string;
  phoneNumber: string;
}

type GroupInvitationForm = Pick<BaseGroupInvitation, 'relationship' | 'yearsKnown' | 'role'> & UserForm

interface GroupForm {
  name: string;
  invitations: GroupInvitationForm[];
}

interface CreateBody {
  group: GroupForm;
  user: UserForm;
}

function create(body: CreateBody) {
  return api.post<void>('/api/internal/support/groups', body);
}

export { create };
export type { CreateBody };
