import { api } from '@/api';

const BASE_PATH = '/api/internal/group_member_loan_applications';

interface PolicyResult {
  id: number;
  rejectionTag: string;
  softDealBreaker: boolean;
  hardDealBreaker: boolean;
  approved: boolean;
}

interface CreditPolicyResult {
  approved: boolean;
  policies: PolicyResult[];
}

function get(groupMemberLoanApplicationId: number) {
  const path = `${BASE_PATH}/${groupMemberLoanApplicationId}/credit_policy_result`;

  return api.get<{groupMemberLoanApplicationCreditPolicyResult: CreditPolicyResult}>(path);
}

export { get };
export type { CreditPolicyResult };
