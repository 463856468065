<script setup lang="ts">
import { useQueryClient } from '@tanstack/vue-query';
import { useForm } from 'vee-validate';
import { object } from 'yup';

import type { CreateGroupInvitationPayload } from '@/api/groupInvitation';
import { useCreateGroupInvitation } from '@/api/groupInvitation/mutations';
import { groupLoanApplicationKey } from '@/api/queryKeys';
import BaseButton from '@/components/base-button.vue';
import BaseModal from '@/components/base-modal.vue';
import VeeValidateInput from '@/components/vee-validate/vee-validate-input.vue';
import VeeValidateSelect from '@/components/vee-validate/vee-validate-select.vue';
import VeeValidateWalichatPhoneInput from '@/components/vee-validate/vee-validate-walichat-phone-input.vue';
import useUiStore from '@/stores/ui';
import { textFieldSchema, phoneNumberSchema } from '@/utils/yupRules';

interface Props {
  show: boolean;
  groupLoanApplicationId: number;
  groupId: number;
}
const props = defineProps<Props>();

interface Emits {
  (e: 'close'): void;
}
const emit = defineEmits<Emits>();

const uiStore = useUiStore();

const queryClient = useQueryClient();

const createGroupInvitationMutation = useCreateGroupInvitation({
  onSuccess: () => {
    uiStore.toast({
      message: 'Invitación creada correctamente',
      type: 'success',
      position: 'top',
    });
    emit('close');
    queryClient.invalidateQueries({ queryKey: groupLoanApplicationKey(props.groupLoanApplicationId) });
  },
  onError: (e) => {
    uiStore.toast({
      message: e.response?.data?.detail || 'Hubo un error al crear la invitación',
      type: 'error',
      position: 'top',
    });
  },
});

const initialValues: CreateGroupInvitationPayload = {
  groupId: props.groupId,
  groupInvitation: {
    firstName: '',
    phoneNumber: '',
    role: 'guest',
    relationship: null,
    yearsKnown: null,
  },
};

const validationSchema = object().shape({
  groupInvitation: object().shape({
    firstName: textFieldSchema,
    phoneNumber: phoneNumberSchema,
    role: textFieldSchema,
    relationship: textFieldSchema,
    yearsKnown: textFieldSchema,
  }),
});

const { handleSubmit } = useForm({ validationSchema, initialValues });

const onSubmit = handleSubmit((values) => {
  createGroupInvitationMutation.mutate(values);
});

</script>

<template>
  <base-modal
    :show="show"
    title="Crear invitación"
    hide-actions
    @close="emit('close')"
  >
    <template #body>
      <form
        @submit="onSubmit"
      >
        <div class="grid grid-cols-2 gap-4 py-8">
          <vee-validate-input
            label="Nombre"
            name="groupInvitation.firstName"
          />
          <vee-validate-walichat-phone-input
            label="Número de teléfono"
            name="groupInvitation.phoneNumber"
          />
          <vee-validate-select
            label="Relación"
            name="groupInvitation.relationship"
            :options="[
              { label: 'Familiar cercano (hermana/a,  madre, hija/o, padre) ', value: 'close_relative' },
              { label: 'Familiar segundo (prima/o, tía, abuela/o)', value: 'distant_relative' },
              { label: 'Familiar político (cuñada/o, suegra/o, yerna/o)', value: 'in_law_relative' },
              { label: 'Pareja (esposo/a, novio/a)', value: 'partner' },
              { label: 'Amistad', value: 'friend' },
              { label: 'Vecino/a', value: 'neighbor' },
              { label: 'Conocido/a', value: 'acquaintance' },
            ]"
          />
          <vee-validate-select
            label="Hace cuánto la conoce"
            name="groupInvitation.yearsKnown"
            :options="[
              { label: 'Menos de 1 año', value: 'one_or_less' },
              { label: '1 - 2 años', value: 'one_to_two' },
              { label: '3 - 5 años', value: 'three_to_five' },
              { label: '5 - 10 años', value: 'five_to_ten' },
              { label: 'Más de 10 años', value: 'ten_or_more' },
            ]"
          />
        </div>
        <div class="flex items-center gap-x-4">
          <base-button
            class="flex-1"
            variant="white"
            text="Volver"
            @click="emit('close')"
          />
          <base-button
            type="submit"
            class="flex-1"
            text="Crear invitación"
            :loading="createGroupInvitationMutation.isPending"
          />
        </div>
      </form>
    </template>
  </base-modal>
</template>
