<script setup lang="ts">
import { useEventBus } from '@vueuse/core';
import { Form as VForm } from 'vee-validate';
import { ref } from 'vue';

import useFilteredAttrs from '@/composables/useFilteredAttrs';

const emit = defineEmits(['invalid-submit']);

const bus = useEventBus('v-form-invalid-submit');

function handleInvalidSubmit(event) {
  bus.emit();
  emit('invalid-submit', event);
}

const filteredAttrs = useFilteredAttrs({ excluded: ['invalid-submit'] });

const innerRef = ref(null);
defineExpose({ innerRef });
</script>

<template>
  <v-form
    v-slot="{ ...slots }"
    v-bind="filteredAttrs"
    ref="innerRef"
    @invalid-submit="handleInvalidSubmit"
  >
    <slot
      v-bind="slots"
    />
  </v-form>
</template>
