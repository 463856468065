<script setup lang="ts">
import { ArrowPathRoundedSquareIcon, TrashIcon, ClockIcon } from '@heroicons/vue/24/outline';
import { formatDistanceToNow } from 'date-fns';
import { ref, computed } from 'vue';

import { useFetchGroupLoans } from '@/api/groupLoan/queries';
import { type GroupLoanApplication } from '@/api/groupLoanApplication';
import {
  useGenerateUnsignedContracts,
  useUpdateGroupLoanApplication,
} from '@/api/groupLoanApplication/reviews/mutations';
import { useCreateTags } from '@/api/tags/mutations';
import { useFetchTags } from '@/api/tags/queries';
import BaseBadge from '@/components/base-badge.vue';
import BaseButton from '@/components/base-button.vue';
import BaseListBox from '@/components/base-list-box.vue';
import BaseModal from '@/components/base-modal.vue';
import GroupLoanApplicationCancellationModal from '@/components/reviews/group-loan-application-cancellation-modal.vue';
import GroupLoanApplicationRejectionModal from '@/components/reviews/group-loan-application-rejection-modal.vue';
import GroupLoanApplicationSummary from '@/components/reviews/group-loan-application-summary.vue';
import useUIStore from '@/stores/ui';
import { groupLoanApplicationBadgeColor } from '@/utils/states';

const STATE_MAPPING = [
  { name: 'Invitaciones', value: 'inviting', order: 0 },
  { name: 'Draft', value: 'draft', order: 1 },
  { name: 'Review', value: 'application_in_review', order: 2 },
  { name: 'KYC', value: 'in_kyc', order: 3 },
];

type ListBoxAasmStates = 'inviting' | 'draft' | 'application_in_review' | 'in_kyc';
interface FireAasmStateUpdatePayload {
  aasmStateEvent: 'start_kyc'
  | 'accept_kyc'
  | 'pass_application_to_review'
  | 'reject_application'
  | 'review_kyc'
  | 'disburse'
  | 'cancel';
}
interface Props {
  rejectionTags: string[];
  groupLoanApplication: GroupLoanApplication;
  previousGroupLoanApplications: GroupLoanApplication[];
}

const props = defineProps<Props>();

const group = computed(() => props.groupLoanApplication.group);

const uiStore = useUIStore();

const memberLoanApplicationsInKyc = computed(() => props.groupLoanApplication.memberLoanApplications.filter(
  (memberLoanApplication) => ['kyc_submitted', 'kyc_in_review'].includes(memberLoanApplication.aasmState),
));

const areAllContractsSentToGenerate = computed(() => memberLoanApplicationsInKyc.value.every(
  (memberLoanApplication) => memberLoanApplication.unsignedContractGenerateJobStatus !== null,
));
const areAllContractsGenerated = computed(() => memberLoanApplicationsInKyc.value.every(
  (memberLoanApplication) => !!memberLoanApplication.unsignedContractUrl,
));

const rejectionTagsQ = useFetchTags({
  resourceId: props.groupLoanApplication.id,
  resourceType: 'GroupLoanApplication',
  context: 'rejection_tags',
});
const updateGroupLoanApplicationAasmStateMutation = useUpdateGroupLoanApplication<{
  aasmState: ListBoxAasmStates
}>({
  id: props.groupLoanApplication.id,
  onSuccess: () => {
    uiStore.toast({
      message: 'Solicitud actualizada',
      type: 'success',
      position: 'top',
    });
  },
  onError: (e) => {
    uiStore.toast({
      message: e.response?.data?.detail || 'Hubo un error al editar la solicitud',
      type: 'error',
      position: 'top',
    });
  },
});

const createTagsMutation = useCreateTags({
  onError: (e) => {
    uiStore.toast({
      message: e.response?.data?.detail || 'Hubo un error al crear los tags',
      type: 'error',
      position: 'top',
    });
  },
});

const fireAasmStateEventMutation = useUpdateGroupLoanApplication<FireAasmStateUpdatePayload>({
  id: props.groupLoanApplication.id,
  onSuccess: (_, { aasmStateEvent }) => {
    const message = {
      'start_kyc': 'Solicitud enviada a KYC',
      'accept_kyc': 'KYC aprobado',
      'pass_application_to_review': 'Solicitud enviada a revisión',
      'reject_application': 'Solicitud rechazada',
      'review_kyc': 'KYC pasado a revisión',
      'restart_application': 'Solicitud iniciada',
      'disburse': 'Crédito marcado como desembolsado',
      'cancel': 'Solicitud cancelada',
    }[aasmStateEvent];

    uiStore.toast({
      message,
      type: 'success',
      position: 'top',
    });
  },
  onError: (e) => {
    uiStore.toast({
      message: e.response?.data?.detail || 'Hubo un error al editar la solicitud',
      type: 'error',
      position: 'top',
    });
  },
});

const {
  mutate: generateUnsignedContracts, isPending: isGenerateUnsignedContractsPending,
} = useGenerateUnsignedContracts({
  groupLoanApplicationId: props.groupLoanApplication.id,
  onSuccess: () => {
    uiStore.toast({
      message: 'Contratos enviados a generar correctamente.',
      type: 'success',
      position: 'top',
    });
  },
  onError: () => {
    uiStore.toast({
      message: 'Hubo un error al generar los contratos',
      type: 'error',
      position: 'top',
    });
  },
});

const currentListBoxOption = computed(() => STATE_MAPPING.find(
  (option) => option.value === props.groupLoanApplication.aasmState),
);

const listBoxOptions = computed(() => {
  if (!currentListBoxOption.value) {
    return [];
  }

  return STATE_MAPPING.filter((option) => option.order <= (currentListBoxOption.value?.order ?? 0),
  );
});

const rejectApplicationConfirmationModalOpen = ref(false);
function rejectApplication(values: { rejectionTags: string[] }) {
  createTagsMutation.mutate({
    resourceId: props.groupLoanApplication.id,
    resourceType: 'GroupLoanApplication',
    context: 'rejection_tags',
    tags: values.rejectionTags,
  });

  fireAasmStateEventMutation.mutate({ aasmStateEvent: 'reject_application' });
  rejectApplicationConfirmationModalOpen.value = false;
}

const passApplicationToReviewConfirmationModalOpen = ref(false);
function passApplicationToReview() {
  fireAasmStateEventMutation.mutate({ aasmStateEvent: 'pass_application_to_review' });
  passApplicationToReviewConfirmationModalOpen.value = false;
}

const passApplicationToKycInReviewConfirmationModalOpen = ref(false);
function passApplicationToKycInReview() {
  fireAasmStateEventMutation.mutate({ aasmStateEvent: 'review_kyc' });
  passApplicationToKycInReviewConfirmationModalOpen.value = false;
}

const cancelApplicationConfirmationModalOpen = ref(false);
function cancelApplication(values: { cancellationReason: string }) {
  fireAasmStateEventMutation.mutate({ ...values, aasmStateEvent: 'cancel' });
  cancelApplicationConfirmationModalOpen.value = false;
}

const fetchGroupLoansQ = useFetchGroupLoans({ groupLoanApplicationId: props.groupLoanApplication.id });
</script>

<template>
  <div class="flex w-full flex-col gap-y-4">
    <div class="flex flex-col items-start justify-between md:flex-row">
      <div>
        <h1 class="text-lg">
          <span class="font-bold">{{ group.name }}</span> ({{ group.publicId }})
        </h1>
        <div class="flex items-center gap-x-2">
          <p class="cursor-pointer text-blue-500 hover:underline">
            <a
              target="_blank"
              :href="`/admin/group_loan_applications/${groupLoanApplication.id}`"
            >
              Solicitud {{ groupLoanApplication.id }}
            </a>
          </p>
          <div
            v-if="groupLoanApplication.submittedAt"
            class="flex flex-row items-center gap-x-1"
          >
            <p class="text-gray-700">
              - enviada
              {{ formatDistanceToNow(new Date(groupLoanApplication.submittedAt), { addSuffix: true }) }}
            </p>
            <ClockIcon
              class="h-4 w-4 text-primary-600/70"
            />
          </div>
        </div>
      </div>
      <div class="flex flex-col items-center gap-x-4 gap-y-3 md:flex-row">
        <base-button
          v-if="[
            'inviting',
            'draft',
            'application_in_review',
            'in_kyc',
            'kyc_in_review',
            'contracts_signed',
            'contracts_signature_pending',
            'disbursed'
          ].includes(groupLoanApplication.aasmState)"
          variant="text"
          class="md:self-start"
          @click="cancelApplicationConfirmationModalOpen = true"
        >
          Cancelar solicitud
        </base-button>
        <div class="flex flex-row justify-center gap-x-1">
          <base-badge
            v-if="group.promoter"
            :label="group.promoter.fullName"
            color="gray"
            class="self-center"
          />
          <BaseBadge
            v-if="areAllContractsGenerated && groupLoanApplication.aasmState === 'kyc_in_review'"
            label="Contratos generados"
          />
          <base-list-box
            v-if="currentListBoxOption"
            :key="currentListBoxOption.value"
            class="w-44 rounded-md border border-gray-200"
            :options="listBoxOptions"
            :initial-selected="currentListBoxOption"
            :loading="updateGroupLoanApplicationAasmStateMutation.isPending"
            @update:model-value="(option) => updateGroupLoanApplicationAasmStateMutation.mutate(
              { aasmState: option.value }
            )"
          />
          <base-badge
            v-else
            :label="$t(`groupLoanApplication.aasmState.${groupLoanApplication.aasmState}`)"
            :color="groupLoanApplicationBadgeColor(groupLoanApplication.aasmState)"
          />
          <button
            v-if="[
              'inviting',
              'draft',
              'application_in_review',
              'in_kyc',
              'kyc_in_review'
            ].includes(groupLoanApplication.aasmState)"
            class="flex items-center justify-center rounded-lg bg-red-500 p-2"
            @click="rejectApplicationConfirmationModalOpen = true"
          >
            <TrashIcon
              class="h-4 w-4 shrink-0 stroke-white"
            />
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="groupLoanApplication.cycle > 1"
      class="flex flex-col gap-y-2"
    >
      <div class="flex flex-row items-center gap-x-1">
        <ArrowPathRoundedSquareIcon class="h-4 w-4" />
        <span class="text-sm">
          Renovación (ciclo {{ groupLoanApplication.cycle }})
        </span>
      </div>
      <ul class="flex flex-row items-center gap-x-2">
        <span>
          Ciclos anteriores:
        </span>
        <li
          v-for="prevGroupLoanApplication in previousGroupLoanApplications"
          :key="prevGroupLoanApplication.id"
          class="mt-px"
        >
          <a
            :href="`/reviews/group_loan_applications/${prevGroupLoanApplication.id}`"
            target="_blank"
            class="text-blue-400 hover:opacity-75"
          >
            {{ prevGroupLoanApplication.cycle }}
          </a>
        </li>
      </ul>
    </div>
    <div
      v-if="group.president?.phoneNumber"
      class="flex gap-x-1"
    >
      <p class="font-semibold">
        Número presidenta:
      </p>
      <a
        :href="`https://wa.me/${group.president.phoneNumber}`"
        class="text-blue-500"
        target="_blank"
      >
        {{ group.president.phoneNumber }}
      </a>
    </div>
    <div
      v-if="fetchGroupLoansQ.data?.[0] && fetchGroupLoansQ.data.length === 1"
      class="flex gap-x-1"
    >
      <p>
        Ir al admin del
      </p>
      <a
        :href="`/admin/group_loans/${fetchGroupLoansQ.data[0].id}`"
        class="text-blue-500"
        target="_blank"
      >
        GroupLoan
      </a>
    </div>
    <div class="flex gap-x-2">
      <base-badge
        v-for="rejectionTag in rejectionTagsQ.data"
        :key="rejectionTag.id"
        color="red"
        :label="$t(`groupLoanApplication.rejectionTags.${rejectionTag.name}`)"
        size="xs"
      />
      <p
        v-if="props.groupLoanApplication.aasmState === 'canceled'"
        class="mt-5 text-primary-600"
      >
        Cancelada porque
        <span class="italic text-gray-700">
          {{ props.groupLoanApplication.cancellationReason }}
        </span>
      </p>
    </div>
    <div class="flex flex-row items-center gap-x-2">
      <base-button
        v-if="groupLoanApplication.aasmState === 'draft'"
        text="Pasar a revisión"
        class="self-start"
        :loading="fireAasmStateEventMutation.isPending"
        @click="() => passApplicationToReviewConfirmationModalOpen = true"
      />
      <base-button
        v-else-if="groupLoanApplication.aasmState === 'application_in_review'"
        text="Pasar a KYC"
        class="self-start"
        :loading="fireAasmStateEventMutation.isPending"
        @click="() => fireAasmStateEventMutation.mutate({ aasmStateEvent: 'start_kyc' })"
      />
      <base-button
        v-else-if="groupLoanApplication.aasmState === 'in_kyc'"
        text="Pasar KYC a revisión"
        class="w-full self-start md:w-auto"
        :loading="fireAasmStateEventMutation.isPending"
        @click="passApplicationToKycInReviewConfirmationModalOpen = true"
      />
      <template v-else-if="groupLoanApplication.aasmState === 'kyc_in_review'">
        <base-button
          v-if="areAllContractsGenerated"
          text="Aprobar KYC"
          :loading="fireAasmStateEventMutation.isPending"
          class="w-full self-start md:w-auto"
          @click="() => fireAasmStateEventMutation.mutate({ aasmStateEvent: 'accept_kyc' })"
        />
        <base-button
          v-else
          :text="areAllContractsSentToGenerate ? 'Generando contratos' : 'Generar contratos'"
          :disabled="areAllContractsSentToGenerate"
          :loading="isGenerateUnsignedContractsPending"
          class="w-full self-start md:w-auto"
          @click="generateUnsignedContracts"
        />
      </template>
      <base-button
        v-else-if="groupLoanApplication.aasmState === 'contracts_signed'"
        text="Marcar como desembolsado"
        class="self-start"
        :loading="fireAasmStateEventMutation.isPending"
        @click="fireAasmStateEventMutation.mutate({ aasmStateEvent: 'disburse' })"
      />
      <base-button
        v-if="'application_rejected' === groupLoanApplication.aasmState"
        text="Revivir solicitud"
        class="self-start"
        @click="() => fireAasmStateEventMutation.mutate({ aasmStateEvent: 'restart_application' })"
      />
    </div>
    <div class="mt-8 px-4">
      <group-loan-application-summary
        :group-loan-application="groupLoanApplication"
        :group-members="group.members"
      />
    </div>
  </div>
  <base-modal
    :show="passApplicationToReviewConfirmationModalOpen"
    title="¿Estás seguro?"
    accept-button-text="Sí, pasar a revisión"
    cancel-button-text="Cancelar"
    :body="group.pendingInvitations.length > 0
      ? `Hay ${group.pendingInvitations.length} invitaciones pendientes. ¿Estás seguro que deseas pasar a revisión?`
      : null"
    @accept="passApplicationToReview"
    @close="passApplicationToReviewConfirmationModalOpen = false"
  />
  <base-modal
    :show="passApplicationToKycInReviewConfirmationModalOpen"
    title="¿Estás seguro?"
    body="Los integrantes que no hayan subido sus documentos serán desactivados."
    accept-button-text="Sí, pasar KYC a revisión"
    cancel-button-text="Cancelar"
    @accept="passApplicationToKycInReview"
    @close="passApplicationToKycInReviewConfirmationModalOpen = false"
  />
  <group-loan-application-rejection-modal
    :rejection-tags="rejectionTags"
    :show="rejectApplicationConfirmationModalOpen"
    @submit="rejectApplication"
    @close="rejectApplicationConfirmationModalOpen = false"
  />
  <group-loan-application-cancellation-modal
    :show="cancelApplicationConfirmationModalOpen"
    @submit="cancelApplication"
    @close="cancelApplicationConfirmationModalOpen = false"
  />
</template>
