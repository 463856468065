<script setup lang="ts">
import { ref } from 'vue';

import RailsFlashAlerts from '@/components/rails-flash-alerts.vue';
import RequestMagicCode from '@/components/sessions//request-magic-code.vue';
import ValidateMagicCode from '@/components/sessions/validate-magic-code.vue';

const STATES = {
  login: 'LOGIN',
  magicCode: 'MAGIC_CODE',
} as const;

const currentStep = ref<typeof STATES[keyof typeof STATES]>(STATES.login);
const phoneNumber = ref('');

function handlePhoneNumberSubmitted(submittedPhoneNumber: string) {
  phoneNumber.value = submittedPhoneNumber;
  currentStep.value = STATES.magicCode;
}

function handleLoginSuccess(redirectPath: string = '/') {
  window.location.href = redirectPath;
}
</script>

<template>
  <div class="container flex w-full max-w-lg flex-col items-center sm:mt-20">
    <rails-flash-alerts />
    <request-magic-code
      v-if="currentStep === STATES.login"
      @phone-number-submitted="handlePhoneNumberSubmitted"
    />
    <validate-magic-code
      v-else
      :phone-number="phoneNumber"
      @login-success="handleLoginSuccess"
    />
  </div>
</template>

