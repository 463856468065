<script setup lang="ts">
import { ArrowsRightLeftIcon } from '@heroicons/vue/24/outline';

import type { CompositionChanges } from '@/api/groupLoanApplication';
import BaseDisclosure from '@/components/base-disclosure.vue';
import useFilteredAttrs from '@/composables/useFilteredAttrs';

interface Props {
  compositionChanges: CompositionChanges | null
}

defineProps<Props>();

const filteredAttrs = useFilteredAttrs({ excluded: ['class'] });
</script>
<template>
  <div
    v-if="compositionChanges"
    class="rounded-lg bg-gray-100"
    :class="$attrs.class"
  >
    <base-disclosure
      button-text="Cambios de composición"
      v-bind="filteredAttrs"
    >
      <div class="flex flex-row gap-x-24">
        <div
          v-if="compositionChanges.joined.length > 0"
          className="flex flex-col gap-y-1"
        >
          <div
            v-for="user in compositionChanges.joined"
            :key="user.id"
            class="flex flex-row items-center gap-x-2"
          >
            <ArrowsRightLeftIcon class="h-4 w-4 text-green-500" />
            <p>{{ user.fullName }}</p>
          </div>
        </div>
        <div
          v-if="compositionChanges.left.length > 0"
          className="flex flex-col gap-y-1"
        >
          <div
            v-for="user in compositionChanges.left"
            :key="user.id"
            class="flex flex-row items-center gap-x-2"
          >
            <ArrowsRightLeftIcon class="h-4 w-4 text-red-500" />
            <p>{{ user.fullName }}</p>
          </div>
        </div>
      </div>
    </base-disclosure>
  </div>
</template>
