import { api } from '@/api';

const path = '/api/internal/phone_numbers';

interface PhoneNumber {
  id: number;
  validated: boolean;
  number: string;
}

function create(phoneNumber: string) {
  return api<{ phoneNumber: PhoneNumber}>({
    method: 'post',
    url: path,
    data: { number: phoneNumber },
  });
}

export { create };
export type { PhoneNumber };
