import { type MutationOptions } from '@tanstack/vue-query';

import type { ApiError } from '@/api';
import { get, type MexicanPostalCode } from '@/api/mexicanPostalCode';
import useReactiveMutation from '@/composables/useReactiveMutation';

type GetPostalCodeInfoMutationArgs = MutationOptions<MexicanPostalCode, ApiError, string>;

function useGetPostalCodeInfoMutation(options: GetPostalCodeInfoMutationArgs = {}) {
  return useReactiveMutation({
    ...options,
    mutationFn: (postalCode) => get(postalCode).then(response => response.data.mexicanPostalCode),
  });
}

export { useGetPostalCodeInfoMutation };
