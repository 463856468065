<script setup lang="ts">
import { reactive, defineProps, defineEmits } from 'vue';
import { object, string } from 'yup';

import { useValidateMagicCodeMutation } from '@/api/user/mutations';
import BaseAlert from '@/components/base-alert.vue';
import BaseButton from '@/components/base-button.vue';
import BaseImage from '@/components/base-image.vue';
import BaseShortTextInput from '@/components/base-short-text-input.vue';
import VForm from '@/components/v-form.vue';

interface Props {
  phoneNumber: string;
}

interface Emits {
  (event: 'login-success', redirectPath: string): void;
}

const emits = defineEmits<Emits>();
const props = defineProps<Props>();

const form = reactive({
  magicCode: '',
});

const validateMagicCodeMutation = useValidateMagicCodeMutation();

function handleSubmit(values) {
  form.magicCode = values.magicCode;
  validateMagicCodeMutation.mutate(
    {
      phoneNumber: props.phoneNumber,
      magicCode: form.magicCode,
    },
    {
      onSuccess: (data) => {
        const redirectPath = data.redirectPath || '/';
        emits('login-success', redirectPath);
      },
    },
  );
}

const validationSchema = object({
  magicCode: string().required('El código es obligatorio'),
});
</script>

<template>
  <v-form
    class="flex w-full flex-col gap-y-8 rounded-xl bg-white px-4 py-6 sm:gap-y-12 sm:px-8"
    :validation-schema="validationSchema"
    @submit="handleSubmit"
  >
    <base-image
      img-name="logo"
      alt="Logo de grupalia"
      class="my-4 h-12 w-auto fill-current text-black"
    />
    <h2 class="text-xl font-bold">
      Ingresar código
    </h2>
    <base-alert
      v-if="validateMagicCodeMutation.error"
      type="error"
      :message="validateMagicCodeMutation.error?.response?.data?.detail || 'Código incorrecto'"
    />
    <div class="flex flex-col gap-y-4">
      <base-short-text-input
        name="magicCode"
        label="Código de ingreso"
      />
      <base-button
        text="Ingresar"
        type="submit"
        :loading="validateMagicCodeMutation.isPending"
      />
    </div>
  </v-form>
</template>

