<script setup>
import { computed } from 'vue';
import BaseAlert from './base-alert.vue';

const props = defineProps({
  flash: { type: Array, required: true },
});

const type = computed(() => props.flash[0]);
const content = computed(() => props.flash[1]);
const message = computed(
  () => (typeof content.value === 'string' ?
    content.value :
    Object.values(this.content)[0]),
);
const description = computed(
  () => (typeof content.value === 'string' ?
    null :
    Object.values(content.value)[1]),
);
</script>

<template>
  <base-alert
    :type="type"
    :message="message"
    :description="description"
  />
</template>
