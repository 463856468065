import { type UseQueryOptions } from '@tanstack/vue-query';
import { unref, toRef, type MaybeRef } from 'vue';

import type { ApiError } from '@/api';
import { get, type CreditPolicyResult } from '@/api/groupMemberLoanApplication/creditPolicyResult';
import { creditPolicyResultKey } from '@/api/queryKeys';
import useReactiveQuery from '@/composables/useReactiveQuery';

type FetchCreditPolicyResultComposableArgs = {
  groupMemberLoanApplicationId: MaybeRef<number>
} & UseQueryOptions<CreditPolicyResult, ApiError>;

function useFetchCreditPolicyResult(
  { groupMemberLoanApplicationId, ...options }: FetchCreditPolicyResultComposableArgs,
) {
  return useReactiveQuery({
    queryKey: creditPolicyResultKey(toRef(groupMemberLoanApplicationId)),
    queryFn: () => get(unref(groupMemberLoanApplicationId)).then(
      response => response.data.groupMemberLoanApplicationCreditPolicyResult,
    ),
    ...options,
  });
}

export { useFetchCreditPolicyResult };
