<script setup lang="ts">
import { TrashIcon } from '@heroicons/vue/24/outline';
import { useQueryClient } from '@tanstack/vue-query';
import { ref } from 'vue';

import { useDestroyGroupInvitation } from '@/api/groupInvitation/mutations';
import { groupLoanApplicationKey } from '@/api/queryKeys';
import BaseButton from '@/components/base-button.vue';
import BaseModal from '@/components/base-modal.vue';
import useUiStore from '@/stores/ui';

const showRemoveConfirmationModal = ref(false);

const uiStore = useUiStore();

const queryClient = useQueryClient();

interface Props {
  groupLoanApplicationId: number;
  invitationId: number;
  fullName: string;
  phoneNumber: string;
}
const props = defineProps<Props>();

const destroyGroupInvitationMutation = useDestroyGroupInvitation({
  onSuccess: () => {
    uiStore.toast({
      message: 'Miembro eliminado correctamente',
      type: 'success',
      position: 'top',
    });
    showRemoveConfirmationModal.value = false;
    queryClient.invalidateQueries({ queryKey: groupLoanApplicationKey(props.groupLoanApplicationId) });
  },
  onError: (e) => {
    uiStore.toast({
      message: e.response?.data?.detail || 'Hubo un error al eliminar el miembro',
      type: 'error',
      position: 'top',
    });
  },
});
</script>

<template>
  <div class="grid grid-cols-3 items-center justify-between gap-x-4 rounded-lg p-2 hover:bg-gray-100">
    <p>{{ fullName }}</p>
    <p>{{ phoneNumber }}</p>
    <base-button
      v-if="invitationId"
      class="place-self-end"
      text="Eliminar"
      variant="red"
      :left-icon="TrashIcon"
      :loading="destroyGroupInvitationMutation.isPending"
      @click="showRemoveConfirmationModal = true"
    />
    <base-modal
      :show="showRemoveConfirmationModal"
      title="Vas a eliminar a este miembro"
      cancel-button-text="Volver"
      accept-button-text="Eliminar"
      accept-button-variant="red"
      @close="showRemoveConfirmationModal = false"
      @accept="destroyGroupInvitationMutation.mutate(invitationId)"
    >
      <template #body>
        <div class="p-8">
          <p class="text-sm text-gray-500">
            ¿Estás seguro de que quieres eliminar a este miembro del grupo?
          </p>
        </div>
      </template>
    </base-modal>
  </div>
</template>
