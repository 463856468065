import type { Ref } from 'vue';

import type { Context, ResourceType } from '@/api/tags';

interface TagsKeyParams {
  resourceId: Ref<number>;
  resourceType: Ref<ResourceType>;
  context: Ref<Context>;
}
const TAGS_KEY = ['tags'];

function tagsKey(params: TagsKeyParams) {
  return [...TAGS_KEY, params];
}

export { tagsKey };
