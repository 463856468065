<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(defineProps<{
  imgName: string
  ext?: string
  alt?: string
}>(), {
  ext: 'svg',
  alt: '',
});

const tag = computed(() => (props.ext === 'svg' ? 'inline-svg' : 'img'));

const imgUrl = new URL(`../../assets/images/${props.imgName}.${props.ext}`, import.meta.url).href;
</script>

<template>
  <component
    :is="tag"
    :src="imgUrl"
    :alt="props.alt"
  />
</template>
