<script setup lang="ts">
import { useField } from 'vee-validate';
import { toRef } from 'vue';

import BaseIcon from '@/components/base-icon.vue';
import BaseSpinner from '@/components/base-spinner.vue';
import useFilteredAttrs from '@/composables/useFilteredAttrs';

interface Props {
  label?: string;
  name: string;
  description?: string;
  type?: 'text' | 'textarea' | 'email' | 'number';
  helperIcon?: string;
  helperTooltip?: string;
  loading?: boolean;
  disabled?: boolean;
  customWarningMessage?: string;
}
const props = withDefaults(defineProps<Props>(), {
  label: undefined,
  description: undefined,
  type: 'text',
  helperIcon: undefined,
  helperTooltip: undefined,
  loading: false,
  customWarningMessage: undefined,
});

const {
  value: inputValue,
  errorMessage,
  handleChange,
  meta,
  setTouched,
} = useField<string>(toRef(props, 'name'));

const filteredAttrs = useFilteredAttrs({ excluded: ['class'] });
</script>

<template>
  <div
    :class="$attrs.class"
    class="flex flex-col items-start gap-y-1.5"
  >
    <label
      v-if="label"
      :for="name"
      class="font-medium leading-6 text-gray-900"
    >
      {{ label }}
    </label>
    <p
      v-if="description"
      :id="`${name}-description`"
      class="text-sm text-gray-500"
    >
      {{ description }}
    </p>
    <div
      class="relative box-border flex w-full flex-row items-center overflow-hidden rounded-md border border-transparent bg-white ring-1 ring-inset transition duration-300 ease-in-out focus-within:ring-2 focus-within:ring-primary-600"
      :class="{
        'border-red-400': errorMessage,
        'border-amber-500': customWarningMessage && !errorMessage,
        'ring-gray-300 hover:border-primary-500': !(errorMessage || customWarningMessage),
      }"
    >
      <component
        v-bind="filteredAttrs"
        :is="type === 'textarea' ? 'textarea' : 'input'"
        class="w-full border-none px-1.5 py-1 text-left text-gray-900 outline-none placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6"
        :class="{
          'cursor-not-allowed bg-gray-100': disabled,
          'bg-transparent': !disabled,
          'h-20': type === 'textarea',
          'h-8': type === 'text',
        }"
        :value="inputValue"
        :name="name"
        :type="type"
        :disabled="disabled"
        @input="handleChange"
        @focus="setTouched(false)"
      />

      <base-icon
        v-if="helperIcon"
        v-tooltip="helperTooltip"
        :icon="helperIcon"
        class="mx-2 h-5 w-auto fill-current text-gray-400"
      />
      <span
        v-else-if="loading"
        class="absolute right-4 top-1/2 -translate-y-1/2"
      >
        <base-spinner
          :size="4"
        />
      </span>
    </div>
    <span
      v-if="errorMessage || customWarningMessage"
      class="mt-1 text-xs sm:text-sm"
      :class="{
        'animate-bounce': meta.touched,
        'text-red-500': errorMessage,
        'text-amber-500': customWarningMessage && !errorMessage,
      }"
    >
      {{ errorMessage || customWarningMessage }}
    </span>
  </div>
</template>

