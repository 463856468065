import { api } from '@/api';

const BASE_PATH = '/api/internal/tags';

type Context = 'rejection_tags'
type ResourceType = 'GroupLoanApplication' | 'GroupMemberLoanApplication'

interface Tag {
  id: number;
  name: string;
  taggingsCount: number
}

interface CreateTagPayload {
  resourceId: number;
  resourceType: ResourceType;
  context: Context;
  tags: string[];
}

function getAll(resourceId: number, resourceType: ResourceType, context: Context) {
  return api.get<{ tags: Tag[] }>(
    `${BASE_PATH}?resource_id=${resourceId}&resource_type=${resourceType}&context=${context}`,
  );
}

function create(params: CreateTagPayload) {
  return api.post<void>(BASE_PATH, params);
}

export { create, getAll };
export type { CreateTagPayload, Tag, Context, ResourceType };
