interface Country {
  code: string;
  emoji: string;
  key: string;
  lang: string;
  name: string;
}

const COUNTRIES = [
  {
    code: '56',
    emoji: '🇨🇱',
    key: 'CL',
    lang: 'es',
    name: 'Chile',
  },
  {
    code: '521',
    emoji: '🇲🇽',
    key: 'MX',
    lang: 'es',
    name: 'Mexico',
  },
];

export type { Country };

export { COUNTRIES };
