<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue';

import type { CCLoan } from '@/api/groupMemberLoanApplication/CC';
import {
  ACCOUNT_STATUS_STATES,
  getAccountStatus,
  sortCCLoans,
  type SortableCCLoanColumn,
} from '@/utils/cc';
import { formatDate, toCurrency } from '@/utils/filters';

const props = defineProps<{ loans: CCLoan[] }>();

const sortColumn = ref<SortableCCLoanColumn | null>('apertura');
const sortAsc = ref<boolean>(true);

const sortedLoans = computed(() => {
  const loansCopy = [...props.loans];

  if (sortColumn.value) {
    sortCCLoans(loansCopy, sortColumn.value, sortAsc.value);
  }

  return loansCopy;
});

function toggleSort(column: SortableCCLoanColumn) {
  if (sortColumn.value === column) {
    sortAsc.value = !sortAsc.value;
  } else {
    sortColumn.value = column;
    sortAsc.value = true;
  }
}

const isHeaderSticky = ref(false);
const tableRef = ref<HTMLElement | null>(null);
const tableHeaderRef = ref<HTMLElement | null>(null);

function handleScroll() {
  const header = tableHeaderRef.value;
  const table = tableRef.value;

  if (!header || !table) return;

  const headerRect = header.getBoundingClientRect();
  const tableRect = table.getBoundingClientRect();

  if (headerRect.top <= 0 && tableRect.bottom > headerRect.height) {
    isHeaderSticky.value = true;
  } else {
    isHeaderSticky.value = false;
  }
}

onMounted(() => {
  window.addEventListener('scroll', handleScroll);
});

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
});

function getAccountStatusEmoji(row: CCLoan) {
  switch (getAccountStatus(row)) {
  case ACCOUNT_STATUS_STATES.current:
    return '✅';
  case ACCOUNT_STATUS_STATES.overdue:
    return '❌';
  default:
    return '🚫';
  }
}
</script>

<template>
  <table
    v-if="loans.length > 0"
    ref="tableRef"
    class="min-w-full border-separate rounded-xl bg-white p-1"
  >
    <thead
      ref="tableHeaderRef"
      class="sticky top-0 z-20 bg-white outline-none"
      :class="isHeaderSticky && 'border border-gray-300 shadow-xl'"
    >
      <tr>
        <th class="border border-gray-300 px-4 py-2">
          #
        </th>
        <th class="border border-gray-300 px-4 py-2">
          Cuenta
        </th>
        <th class="border border-gray-300 px-4 py-2">
          Responsabilidad
        </th>
        <th class="border border-gray-300 px-4 py-2">
          Tipo Crédito
        </th>
        <th class="border border-gray-300 px-4 py-2">
          Plazo
        </th>
        <th class="border border-gray-300 px-4 py-2">
          <button
            class="flex grow cursor-pointer flex-row items-center gap-x-2 px-4 py-2"
            @click="toggleSort('max')"
          >
            <span>
              Maximo
            </span>
            <span v-if="sortColumn === 'max'">{{ sortAsc ? '↑' : '↓' }}</span>
          </button>
        </th>
        <th class="border border-gray-300">
          <button
            class="flex grow cursor-pointer flex-row items-center gap-x-2 px-4 py-2"
            @click="toggleSort('vencido')"
          >
            <span>
              Vencido
            </span>
            <span v-if="sortColumn === 'vencido'">{{ sortAsc ? '↑' : '↓' }}</span>
          </button>
        </th>
        <th class="border border-gray-300 px-4 py-2">
          A pagar
        </th>
        <th class="border border-gray-300">
          <button
            class="flex grow cursor-pointer flex-row items-center gap-x-2 px-4 py-2"
            @click="toggleSort('apertura')"
          >
            <span>
              Apertura
            </span>
            <span v-if="sortColumn === 'apertura'">{{ sortAsc ? '↑' : '↓' }}</span>
          </button>
        </th>
        <th class="border border-gray-300">
          <button
            class="flex grow cursor-pointer flex-row items-center gap-x-2 px-4 py-2"
            @click="toggleSort('cierre')"
          >
            <span>
              Fecha cierre
            </span>
            <span v-if="sortColumn === 'cierre'">{{ sortAsc ? '↑' : '↓' }}</span>
          </button>
        </th>
        <th class="border border-gray-300 px-4 py-2">
          Último Pago
        </th>
      </tr>
    </thead>
    <tbody>
      <template
        v-for="(row, index) in sortedLoans"
        :key="row.cuentaActual"
      >
        <tr>
          <td
            rowspan="2"
            class="border"
          >
            <div class="flex -rotate-90 flex-col items-center justify-center gap-y-1 py-4">
              <span class="text-center text-sm text-gray-600">
                {{ $t(`circuloCreditReport.paymentFrequency.${row.frecuenciaPagos}`) }}
              </span>
              <span class="text-xs text-gray-500">
                {{ index + 1 }}
              </span>
            </div>
          </td>
          <td class="border border-primary-600/60 px-4 py-2 text-sm">
            {{ row.nombreOtorgante }}
          </td>
          <td class="border border-primary-600/60 px-4 py-2 text-sm">
            {{ $t(`circuloCreditReport.responsibilityType.${row.tipoResponsabilidad}`) }}
          </td>
          <td class="border border-primary-600/60 px-4 py-2 text-sm">
            {{ $t(`circuloCreditReport.loanType.${row.tipoCredito}`) }}
          </td>
          <td class="border border-primary-600/60 px-4 py-2 text-sm">
            {{ row.numeroPagos }}
          </td>
          <td class="border border-primary-600/60 px-4 py-2 text-sm">
            {{ toCurrency(row.creditoMaximo) }}
          </td>
          <td
            :class="[
              'whitespace-nowrap border border-primary-600/60 px-4 py-2 text-sm',
              row.saldoVencido > 0 ? 'text-red-600' : 'text-green-600'
            ]"
          >
            {{ toCurrency(row.saldoVencido) }}
          </td>
          <td class="whitespace-nowrap border border-primary-600/60 px-4 py-2 text-sm">
            {{ toCurrency(row.montoPagar) }}
          </td>
          <td class="whitespace-nowrap border border-primary-600/60 px-4 py-2 text-sm">
            {{ formatDate(row.fechaAperturaCuenta, 'dd / MM / yy') }}
          </td>
          <td class="whitespace-nowrap border border-primary-600/60 px-4 py-2 text-sm">
            {{ row.fechaCierreCuenta ? formatDate(row.fechaCierreCuenta, 'dd / MM / yy') : '-' }}
          </td>
          <td class="whitespace-nowrap border border-primary-600/60 px-4 py-2 text-sm">
            {{ row.fechaUltimoPago && formatDate(row.fechaUltimoPago, 'dd / MM / yy') }}
          </td>
        </tr>
        <tr>
          <td
            colspan="2"
            class="px-4 py-2 text-sm"
          >
            {{ getAccountStatusEmoji(row) }}
          </td>
          <td
            class="px-4 py-2 text-sm text-gray-500"
            colspan="7"
          >
            {{ (row.historicoPagos || '').replaceAll('-', '').replaceAll('0', '').slice(0, 32) || '-' }}
          </td>
        </tr>
      </template>
    </tbody>
  </table>
  <span v-else>
    No tiene créditos grupales en el pasado
  </span>
</template>

