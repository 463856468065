import { api } from '@/api';

const BASE_PATH = '/api/internal/group_loans';

type GroupLoanAasmState = 'active' | 'restructured' | 'individual_collection' | 'repaid' | 'closed'

interface GroupLoan {
  id: number;
  aasmState: GroupLoanAasmState;
  cycle: number;
  repaymentPeriods: number;
  firstPayinDate: string | null;
  lastPayinDate: string | null;
  repaymentAmount: number;
  meetsConditionsForRenewal: boolean;
  canRenew: boolean;
}

function getAll(groupLoanApplicationId?: number) {
  return api.get<{groupLoans: GroupLoan[]}>(
    BASE_PATH, { params: { q: { groupLoanApplicationIdEq: groupLoanApplicationId } } },
  );
}

export { getAll };

export type { GroupLoan };
