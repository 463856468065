<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue';

const isDevelopment = import.meta.env.DEV;
const dimensions = ref({ width: 0, height: 0 });
const breakpoint = ref('');
const isVisible = ref(true);

function getBreakpoint(width) {
  if (width >= 1536) return '2xl';
  if (width >= 1280) return 'xl';
  if (width >= 1024) return 'lg';
  if (width >= 768) return 'md';
  if (width >= 640) return 'sm';

  return 'xs';
}

function handleResize() {
  dimensions.value = {
    width: window.innerWidth,
    height: window.innerHeight,
  };

  breakpoint.value = getBreakpoint(window.innerWidth);
}

onMounted(() => {
  window.addEventListener('resize', handleResize);
  handleResize();
});

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
});
</script>

<template>
  <div
    v-if="isDevelopment && isVisible"
    class="fixed bottom-0 right-0 rounded-lg bg-neutral-950/50"
  >
    <div class="relative p-6 text-sm text-white">
      <button
        class="absolute left-2 top-0.5 text-white"
        @click="isVisible = false"
      >
        X
      </button>
      <p>Width: {{ dimensions.width }}px</p>
      <p>Height: {{ dimensions.height }}px</p>
      <p>Breakpoint: {{ breakpoint }}</p>
    </div>
  </div>
</template>
