import type { MutationOptions } from '@tanstack/vue-query';

import type { ApiError } from '@/api';
import { create, type GroupLoanRenewalForm } from '@/api/groupLoan/renewal/support';
import useReactiveMutation from '@/composables/useReactiveMutation';

type CreateReturn = Awaited<ReturnType<typeof create>>['data']['groupLoanApplication'];
type UseCreateGroupMutationArgs = MutationOptions<CreateReturn, ApiError, GroupLoanRenewalForm>;

function useCreateGroupLoanRenewal(options: UseCreateGroupMutationArgs = {}) {
  return useReactiveMutation({
    ...options,
    mutationFn: (body) => create(body).then((response) => response.data.groupLoanApplication),
  });
}

export { useCreateGroupLoanRenewal };
