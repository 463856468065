import axios, {
  type AxiosError,
  type AxiosRequestTransformer,
  type AxiosResponseTransformer,
  type AxiosResponseHeaders,
} from 'axios';

import { convertKeys, type objectToConvert } from '../utils/caseConverter';
import { csrfToken } from '../utils/csrfToken';

interface ApiErrorResponseObject {
  message?: string;
  detail?: string;
  status?: number;
}

type ApiError = AxiosError<ApiErrorResponseObject>

export type { ApiError };

function extractCursor(headers: AxiosResponseHeaders, rel: string) {
  const regex = new RegExp(`<[^>]*[?&]page=(\\d+)[^>]*>\\s*;\\s*rel="${rel}"`);
  const match = headers.link.match(regex);

  return match?.[1];
}

const api = axios.create({
  transformRequest: [
    (data: objectToConvert) => convertKeys(data, 'decamelize'),
    ...(axios.defaults.transformRequest as AxiosRequestTransformer[]),
  ],
  transformResponse: [
    ...(axios.defaults.transformResponse as AxiosResponseTransformer[]),
    // eslint-disable-next-line max-statements, complexity
    (data: objectToConvert, headers) => {
      const newData = convertKeys(data, 'camelize');

      if (
        typeof newData === 'object' &&
        newData !== null &&
        !Array.isArray(newData) &&
        !(newData instanceof FormData)
      ) {
        if (headers['x-page'] !== undefined) {
          newData.currentCursor = headers['x-page'];
        }
        if (headers['x-total'] !== undefined) {
          newData.totalCount = headers['x-total'];
        }

        if (headers['x-per-page'] !== undefined) {
          newData.perPage = headers['x-per-page'];
        }

        if (data && headers.link) {
          newData.nextCursor = extractCursor(headers, 'next');
          newData.prevCursor = extractCursor(headers, 'prev');
          newData.firstCursor = extractCursor(headers, 'first');
          newData.lastCursor = extractCursor(headers, 'last');
        }
      }

      return newData;
    },
  ],
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'X-CSRF-Token': csrfToken(),
  },
});

api.interceptors.request.use((request) => ({ ...request, params: convertKeys(request.params, 'decamelize') }));

export { api };

/*
// Example to use the api object in the path ´app/javascript/api/users.ts´

import { api } from './index';

function index() {
  const path = '/api/internal/users';

  return api({
    method: 'get',
    url: path,
  });
}

function create(data: Partial<User>) {
  const path = '/api/internal/users';

  return api({
    method: 'post',
    url: path,
    data: {
      user: data,
    },
  });
}

function update(data: Partial<User>) {
  const path = `/api/internal/users/${data.id}`;

  return api({
    method: 'put',
    url: path,
    data: {
      user: data,
    },
  });
}

export { index, create, update };

*/
