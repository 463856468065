<script setup>
import { ref, watch } from 'vue';
import { useField } from 'vee-validate';
import useFilteredAttrs from '@/composables/useFilteredAttrs.ts';
import useErrorBounceOnFormSubmit from '@/composables/useErrorBounceOnFormSubmit.js';

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
  label: { type: String, default: null },
  description: { type: String, default: null },
  name: { type: String, required: true },
  error: { type: String, default: '' },
  value: { type: [Boolean, String], default: true },
  uncheckedValue: { type: [Boolean, String, null], default: false },
  modelValue: { type: [String, Number, Boolean], default: null },
  type: { type: String, default: 'text' },
});

const { checked, errorMessage, handleChange } = useField(() => props.name, undefined, {
  type: 'checkbox',
  initialValue: props.modelValue || props.uncheckedValue,
  checkedValue: props.value,
  uncheckedValue: props.uncheckedValue,
});

watch(checked, (newVal) => {
  emit('update:modelValue', newVal);
});

const filteredAttrs = useFilteredAttrs({ excluded: ['class'] });

const errorRef = ref(null);
useErrorBounceOnFormSubmit(errorRef);
</script>

<template>
  <div class="flex items-start gap-x-3">
    <div class="flex h-6 items-center">
      <input
        :id="name"
        :checked="checked"
        :value="value"
        :aria-describedby="`${name}-description`"
        :name="name"
        type="checkbox"
        class="h-4 w-4 rounded border border-gray-300 text-primary-600 focus:ring focus:ring-primary-600"
        v-bind="filteredAttrs"
        @change="handleChange"
      >
    </div>
    <div class="flex flex-col items-start gap-y-1">
      <div class="text-left text-sm leading-6">
        <slot name="label">
          <label
            v-if="label"
            :for="name"
            class="text-gray-900"
          >
            {{ label }}
          </label>
        </slot>
        <p
          v-if="description"
          :id="`${name}-description`"
          class="text-sm text-gray-500"
        >
          {{ description }}
        </p>
      </div>
      <span
        v-if="errorMessage || error"
        ref="errorRef"
        class="text-xs text-red-500 sm:text-sm"
      >
        {{ errorMessage || error }}
      </span>
    </div>
  </div>
</template>
