import { type MutationOptions } from '@tanstack/vue-query';
import { toRef, unref, type MaybeRef } from 'vue';

import type { ApiError } from '@/api';
import {
  submit, uploadKyc, generateSigningUrl, type SubmitBody, type KycUploadBody,
} from '@/api/groupMemberLoanApplication/support';
import { groupMemberLoanApplicationKey } from '@/api/queryKeys';
import useReactiveMutation from '@/composables/useReactiveMutation';

type SubmitReturn = Awaited<ReturnType<typeof submit>>['data']['groupMemberLoanApplication'];
type UseSubmitGroupMemberLoanApplicationComposableArgs = MutationOptions<SubmitReturn, ApiError, SubmitBody> & {
  groupMemberLoanApplicationId: MaybeRef<number>;
};

function useSubmitGroupMemberLoanApplication(
  { groupMemberLoanApplicationId, ...options }: UseSubmitGroupMemberLoanApplicationComposableArgs,
) {
  return useReactiveMutation({
    ...options,
    mutationKey: groupMemberLoanApplicationKey(toRef(groupMemberLoanApplicationId)),
    mutationFn: (body) => (
      submit(unref(groupMemberLoanApplicationId), body).then((response) => response.data.groupMemberLoanApplication)
    ),
  });
}

type UploadKycReturn = Awaited<ReturnType<typeof uploadKyc>>['data']['groupMemberLoanApplication'];
type UseUploadKycComposableArgs = MutationOptions<UploadKycReturn, ApiError, KycUploadBody> & {
  groupMemberLoanApplicationId: MaybeRef<number>;
};

function useUploadKyc(
  { groupMemberLoanApplicationId, ...options }: UseUploadKycComposableArgs,
) {
  return useReactiveMutation({
    ...options,
    mutationKey: groupMemberLoanApplicationKey(toRef(groupMemberLoanApplicationId)),
    mutationFn: (body) => uploadKyc(unref(groupMemberLoanApplicationId), body)
      .then((response) => response.data.groupMemberLoanApplication),
  });
}

type GenerateJwtReturn = Awaited<ReturnType<typeof generateSigningUrl>>['data'];
type UseGenerateJwtComposableArgs = MutationOptions<GenerateJwtReturn, ApiError> & {
  groupMemberLoanApplicationId: MaybeRef<number>;
};

function useGenerateSigningUrl(
  { groupMemberLoanApplicationId, ...options }: UseGenerateJwtComposableArgs,
) {
  return useReactiveMutation({
    ...options,
    mutationFn: () => generateSigningUrl(unref(groupMemberLoanApplicationId)).then((response) => response.data),
  });
}

export { useSubmitGroupMemberLoanApplication, useUploadKyc, useGenerateSigningUrl };
