import type { UseQueryOptions } from '@tanstack/vue-query';
import { unref, ref, type MaybeRef } from 'vue';

import type { ApiError } from '@/api';
import { getAll, type GroupLoan } from '@/api/groupLoan';
import { groupLoansKey } from '@/api/queryKeys';
import useReactiveQuery from '@/composables/useReactiveQuery';

type FetchGroupLoansComposableArgs = {
  groupLoanApplicationId?: MaybeRef<number | undefined>
} & UseQueryOptions<GroupLoan[], ApiError>

function useFetchGroupLoans({ groupLoanApplicationId, ...options }: FetchGroupLoansComposableArgs) {
  return useReactiveQuery({
    ...options,
    queryKey: groupLoansKey(ref(groupLoanApplicationId)),
    queryFn: () => getAll(unref(groupLoanApplicationId)).then((response) => response.data.groupLoans),

  });
}

export { useFetchGroupLoans };
