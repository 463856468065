<script setup lang="ts">
import { computed } from 'vue';

import type { ConsolidatedLoan, GroupLoanApplication } from '@/api/groupLoanApplication';
import BaseDisclosure from '@/components/base-disclosure.vue';
import useFilteredAttrs from '@/composables/useFilteredAttrs';
import { toCurrency, formatDate } from '@/utils/filters';

interface Props {
  groupLoanApplication: GroupLoanApplication;
  consolidatedLoans: ConsolidatedLoan[];
}

const props = defineProps<Props>();

const groupMembers = computed(() => props.groupLoanApplication.memberLoanApplications.map(
  groupMemberLoanApplication => groupMemberLoanApplication.groupMember,
));

function findGroupMember(id?: number) {
  return groupMembers.value.find(member => member.id === id);
}

function findGroupMemberFromGroupMemberLoanApplicationId(id: number) {
  const groupMemberLoanApplication = props.groupLoanApplication.memberLoanApplications.find(mla => mla.id === id);

  return findGroupMember(groupMemberLoanApplication?.groupMemberId);
}

function formatMemberNames(ids: number[]) {
  return ids.map(id => findGroupMemberFromGroupMemberLoanApplicationId(id)?.user?.fullName).join(', ');
}

const filteredAttrs = useFilteredAttrs({ excluded: ['class'] });
</script>

<template>
  <div
    class="rounded-lg bg-gray-100"
    :class="$attrs.class"
  >
    <base-disclosure
      button-text="Consolidaciones del grupo"
      v-bind="filteredAttrs"
    >
      <div class="flex flex-col gap-y-4">
        <div
          v-for="consolidatedLoan in consolidatedLoans"
          :key="consolidatedLoan.groupMemberLoanApplicationIds.join('-')"
          class="flex flex-col gap-y-4 rounded-lg bg-gray-100 p-6"
        >
          <div class="flex flex-col justify-between gap-y-5 md:flex-row">
            <div class="flex flex-col gap-y-1">
              <span class="text-sm font-semibold">
                {{ formatMemberNames(consolidatedLoan.groupMemberLoanApplicationIds) }}
              </span>
              <span class="text-sm">
                Ciclos: {{ consolidatedLoan.loans.length }}
              </span>
              <span class="text-sm">
                Fecha de apertura más reciente: {{
                  formatDate(consolidatedLoan.mostRecentLoanStartDate, 'dd-MM-yyyy')
                }}
              </span>
              <span class="text-sm">
                Peor atraso: {{ consolidatedLoan.worstDelinquencyStrike }}
              </span>
            </div>
            <div
              v-if="consolidatedLoan.mostRecentDelinquentBalanceAmount > 0"
              class="flex flex-col gap-y-1"
            >
              <span class="text-sm font-bold text-red-600">
                Saldo vencido: {{ toCurrency(consolidatedLoan.mostRecentDelinquentBalanceAmount) }}
              </span>
              <span class="text-sm">
                Número de pagos vencidos: {{ consolidatedLoan.mostRecentDelinquentPaymentsCount }}
              </span>
              <span class="text-sm">
                Fecha de saldo vencido más reciente: {{ consolidatedLoan.mostRecentDelinquentBalanceDate }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </base-disclosure>
  </div>
</template>

