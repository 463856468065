<script setup lang="ts">
import { useFieldArray, useForm } from 'vee-validate';
import { computed } from 'vue';
import { object, array } from 'yup';

import type { CreateBody } from '@/api/group/support';
import { useCreateGroup } from '@/api/group/support/mutations';
import BaseButton from '@/components/base-button.vue';
import VeeValidateInput from '@/components/vee-validate/vee-validate-input.vue';
import VeeValidateSelect from '@/components/vee-validate/vee-validate-select.vue';
import VeeValidateWalichatPhoneInput from '@/components/vee-validate/vee-validate-walichat-phone-input.vue';
import useUiStore from '@/stores/ui';
import { phoneNumberSchema, textFieldSchema } from '@/utils/yupRules';

const MINIMUM_GROUP_MEMBERS = Number(window.env.GROUP_MINIMUM_MEMBERS);

const uiStore = useUiStore();

const validationSchema = computed(() => object({
  user: object({
    firstName: textFieldSchema,
    phoneNumber: phoneNumberSchema,
  }),
  group: object({
    name: textFieldSchema,
    invitations: array().of(
      object({
        firstName: textFieldSchema,
        phoneNumber: phoneNumberSchema,
        relationship: textFieldSchema,
        yearsKnown: textFieldSchema,
        role: textFieldSchema,
      }),
    ).test(
      'one-vice-president',
      'Debe haber exactamente una vicepresidenta en el grupo',
      (invitations) => {
        if (!invitations) return false;
        const vicePresidentCount = invitations.filter(
          (invitation) => invitation.role === 'vice_president',
        ).length;

        return vicePresidentCount === 1;
      },
    ),
  }),
}));

const initialValues: CreateBody = {
  user: {
    firstName: '',
    phoneNumber: '',
  },
  group: {
    name: '',
    invitations: Array.from({ length: MINIMUM_GROUP_MEMBERS - 1 }, () => ({
      firstName: '',
      phoneNumber: '',
      relationship: null,
      yearsKnown: null,
      role: 'guest',
    })),
  },
};

const { handleSubmit, resetForm, values: formValues, errors: formErrors, meta } = useForm({
  validationSchema, initialValues,
});

const { remove: removeInvitation, push: pushInvitation } = useFieldArray('group.invitations');

function addInvitation() {
  pushInvitation({
    firstName: undefined,
    phoneNumber: undefined,
    relationship: '',
    yearsKnown: undefined,
    role: 'guest',
  });
}

const createGroupMutation = useCreateGroup({
  onSuccess: () => {
    uiStore.toast({
      message: 'Grupo creado correctamente',
      type: 'success',
      position: 'top',
    });
    resetForm();
  },
  onError: (e) => {
    uiStore.toast({
      message: e.response?.data?.detail || 'Hubo un error al crear el grupo',
      type: 'error',
      position: 'top',
    });
  },
});

const onSubmit = handleSubmit((values) => {
  createGroupMutation.mutate(values);
});
</script>

<template>
  <div class="container flex flex-col gap-y-4">
    <h1 class="text-xl font-bold">
      Crear grupo
    </h1>
    <form
      class="flex flex-1 flex-col gap-y-5 rounded-lg bg-white p-4"
      @submit="onSubmit"
    >
      <div class="flex flex-col gap-y-2 rounded-lg border p-4">
        <h2 class="text-lg font-semibold">
          Datos de la presidenta
        </h2>
        <div class="flex items-center justify-between gap-x-8">
          <vee-validate-input
            class="flex-1"
            label="Nombre"
            name="user.firstName"
            type="text"
            placeholder="Nombre de la presidenta"
          />
          <vee-validate-walichat-phone-input
            class="flex-1"
            label="Teléfono"
            name="user.phoneNumber"
            placeholder="Teléfono de la presidenta"
          />
        </div>
      </div>
      <div class="flex flex-col gap-y-2 rounded-lg border p-4">
        <h2 class="text-lg font-semibold">
          Datos del grupo
        </h2>
        <vee-validate-input
          label="Nombre del grupo"
          name="group.name"
          type="text"
          placeholder="Nombre del grupo"
        />
        <h3 class="font-medium">
          Invitaciones
        </h3>
        <p
          v-if="meta.touched && formErrors['group.invitations']"
          class="text-red-500"
        >
          {{ formErrors['group.invitations'] }}
        </p>
        <div
          v-for="(_, index) in formValues.group.invitations"
          :key="index"
          class="flex justify-between gap-x-4"
        >
          <vee-validate-input
            :name="`group.invitations[${index}].firstName`"
            label="Nombre"
            class="flex-1"
          />
          <vee-validate-walichat-phone-input
            :name="`group.invitations[${index}].phoneNumber`"
            label="Número de teléfono"
            class="flex-1"
          />
          <vee-validate-select
            :name="`group.invitations[${index}].relationship`"
            label="Relación"
            class="h-10 flex-1"
            :options="[
              { label: 'Familiar cercano (hermana/a,  madre, hija/o, padre) ', value: 'close_relative' },
              { label: 'Familiar segundo (prima/o, tía, abuela/o)', value: 'distant_relative' },
              { label: 'Familiar político (cuñada/o, suegra/o, yerna/o)', value: 'in_law_relative' },
              { label: 'Pareja (esposo/a, novio/a)', value: 'partner' },
              { label: 'Amistad', value: 'friend' },
              { label: 'Vecino/a', value: 'neighbor' },
              { label: 'Conocido/a', value: 'acquaintance' },
            ]"
          />
          <vee-validate-select
            :name="`group.invitations[${index}].yearsKnown`"
            label="Hace cuánto la conoce"
            class="flex-1"
            :options="[
              { label: 'Menos de 1 año', value: 'one_or_less' },
              { label: '1 - 2 años', value: 'one_to_two' },
              { label: '3 - 5 años', value: 'three_to_five' },
              { label: '5 - 10 años', value: 'five_to_ten' },
              { label: 'Más de 10 años', value: 'ten_or_more' },
            ]"
          />
          <vee-validate-select
            :name="`group.invitations[${index}].role`"
            label="Rol"
            class="flex-1"
            :options="[
              { label: 'Invitado', value: 'guest' },
              { label: 'Vicepresidenta', value: 'vice_president' },
            ]"
          />
          <base-button
            class="h-12 self-center"
            type="button"
            text="Eliminar"
            :disabled="formValues.group.invitations.length <= MINIMUM_GROUP_MEMBERS - 1"
            @click="removeInvitation(index)"
          />
        </div>
        <base-button
          type="button"
          variant="text"
          text="Agregar invitación"
          @click="addInvitation"
        />
      </div>
      <base-button
        type="submit"
        text="Enviar"
        :loading="createGroupMutation.isPending"
      />
    </form>
  </div>
</template>
