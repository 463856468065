<script setup lang="ts">
import {
  startOfWeek,
  endOfWeek,
  isWithinInterval,
  parseISO,
  format,
  compareAsc,
  isToday,
} from 'date-fns';
import { computed, inject } from 'vue';

import BaseBackButton from '@/components/base-back-button.vue';
import BaseTable from '@/components/base-table.vue';
import { toCurrency } from '@/utils/filters';
import { currentUserKey } from '@/utils/keys';

const startOfThisWeek = startOfWeek(new Date(), { weekStartsOn: 1 });
const endOfThisWeek = endOfWeek(new Date(), { weekStartsOn: 1 });

const props = defineProps({
  groupLoans: { type: Array, required: true },
  subordinate: { type: Object, default: null },
});

const currentUser = inject(currentUserKey);

function findThisWeekPayment(gl) {
  const sortedPayments = [
    ...gl.loanPayments.sort(
      (a, b) => compareAsc(parseISO(a.dueDate), parseISO(b.dueDate)),
    ),
  ];

  const weekPayment = gl.loanPayments.find(lp =>
    isWithinInterval(parseISO(lp.dueDate), {
      start: startOfThisWeek,
      end: endOfThisWeek,
    }),
  );

  const firstPendingPayment = sortedPayments.filter(p => p.aasmState !== 'paid');

  return weekPayment || firstPendingPayment || sortedPayments[sortedPayments.length - 1];
}

const sortedGroupLoans = computed(() =>
  [...props.groupLoans].sort((a, b) =>
    compareAsc(
      parseISO(findThisWeekPayment(a).dueDate),
      parseISO(findThisWeekPayment(b).dueDate),
    ),
  ));

function getBadgeProperties(groupLoan, payment) {
  if (groupLoan.delinquent) {
    return { label: 'mora', color: 'red' };
  } else if (payment && payment.aasmState === 'pending' && isToday(parseISO(payment.dueDate))) {
    return { label: 'pendiente', color: 'yellow' };
  }

  return { label: 'al día', color: 'green' };
}

const tableItems = computed(() => sortedGroupLoans.value
  .map((gl) => {
    const thisWeekPayment = findThisWeekPayment(gl);
    const badgeProps = getBadgeProperties(gl, thisWeekPayment);
    const totalAmountDue = gl.loanPayments
      .filter(p => p.aasmState === 'pending')
      .reduce((acc, p) => acc + p.amountWithFees, 0);

    return [
      gl.group.name,
      toCurrency(gl.principal),
      format(parseISO(gl.firstPayinDate), 'EEEE'),
      toCurrency(gl.repaymentAmount),
      toCurrency(totalAmountDue),
      {
        component: 'base-badge',
        props: {
          label: badgeProps.label,
          size: 'xs',
          color: badgeProps.color,
        },
      },
      gl.loanPayments.filter(payment => payment.aasmState === 'paid').length,
      gl.delinquentPayments.length,
      gl.loanPayments.filter(payment => payment.late).length,
    ];
  }));
</script>

<template>
  <div class="flex w-full flex-col">
    <base-back-button
      :href="subordinate ? `/promoters/${subordinate.id}/home` : `/promoters/${currentUser.id}/home`"
    />
    <div class="overflow-hidden rounded-xl bg-white p-4">
      <base-table
        title="Estado de créditos grupales"
        :headers="[
          'Grupo',
          'Préstamo',
          'Día de pago',
          'Monto de la ficha',
          'Monto con multas',
          'Estado',
          'Pagos realizados',
          'Pagos vencidos',
          'Pagos tardes',
        ]"
        :items="tableItems"
      />
    </div>
  </div>
</template>
