<script setup>
import { useEventBus } from '@vueuse/core';

const scrollEventBus = useEventBus('scroll');

const navigation = {
  main: [
    { name: 'Grupalia', scrollFn: () => scrollEventBus.emit('grupalia') },
    { name: 'Cómo funciona', scrollFn: () => scrollEventBus.emit('how-it-works') },
    { name: 'Requisitos', scrollFn: () => scrollEventBus.emit('requirements') },
    { name: 'Preguntas frecuentes', scrollFn: () => scrollEventBus.emit('faqs') },
    { name: 'Terminos y condiciones', scrollFn: () => window.open('/terminos') },
    { name: 'Politica de privacidad', scrollFn: () => window.open('/privacidad') },
  ],
};
</script>

<template>
  <footer class="bg-white">
    <div class="mx-auto flex max-w-7xl flex-col items-center overflow-hidden px-6 py-20 sm:py-24 lg:px-8">
      <nav
        class="columns-2 sm:flex sm:justify-center sm:space-x-12"
        aria-label="Footer"
      >
        <div
          v-for="item in navigation.main"
          :key="item.name"
          class="pb-6"
        >
          <button
            class="text-sm leading-6 text-gray-600 hover:text-gray-900 hover:text-primary-600"
            @click="item.scrollFn"
          >
            {{ item.name }}
          </button>
        </div>
      </nav>
      <p class="mt-10 text-center text-xs leading-5 text-gray-500">
        &copy;  {{ new Date().getFullYear() }} XYZ PAGOS S.A. DE C.V. Todos los derechos reservados.
        <br>
        <br>
        Calz. Gral. Mariano Escobedo 568.
        <br>
        Anzures, Miguel Hidalgo, 11590 Ciudad de México, CDMX
      </p>
    </div>
  </footer>
</template>

