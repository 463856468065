import { api } from '@/api/index';

interface MexicanPostalCode {
  postalCode: string;
  municipality: string;
  state: string;
  stateCode: string;
  city: string;
  colonies: string[];
}

export type { MexicanPostalCode };

function get(postalCode: string) {
  return api.get<{ mexicanPostalCode: MexicanPostalCode }>(`/api/v1/mexican_postal_codes/${postalCode}`);
}

export { get };
