import { type MutationOptions } from '@tanstack/vue-query';

import type { ApiError } from '@/api';
import { validate, type CurpValidationResponse } from '@/api/curpValidations';
import useReactiveMutation from '@/composables/useReactiveMutation';

type ValidateCurpMutationArgs = MutationOptions<CurpValidationResponse, ApiError, string>;

function useValidateCurpMutation(options: ValidateCurpMutationArgs = {}) {
  return useReactiveMutation({
    ...options,
    mutationFn: (curp) => validate(curp).then(response => response.data),
  });
}

export { useValidateCurpMutation };
