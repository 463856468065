<template>
  <div class="trix_container">
    <trix-editor
      ref="trix"
      :contenteditable="!disabledEditor"
      :class="['trix-content']"
      :input="computedId"
      :placeholder="placeholder"
      @trix-change="handleContentChange"
      @trix-file-accept="(event) => $emit('trix-file-accept', event)"
      @trix-attachment-add="(event) => $emit('trix-attachment-add', event)"
      @trix-attachment-remove="(event) => $emit('trix-attachment-remove', event)"
      @trix-selection-change="(event) => $emit('trix-selection-change', event)"
      @trix-initialize="handleInitialize"
      @trix-focus="processTrixFocus"
      @trix-blur="processTrixBlur"
    />
    <input
      :id="computedId"
      type="hidden"
      :name="inputName"
      :value="editorContent"
    >
  </div>
</template>

<script>
import Trix from 'trix';
// Override default toolbar configuration
// @see https://github.com/basecamp/trix/blob/main/src/trix/config/toolbar.js to see default configuration
function toolbarDefaultHTML() {
  const { lang } = Trix.config;

  return `
  <div class="flex flex-col gap-y-6">
    <div class="flex flex-wrap justify-center gap-x-2">
      <button
        type="button"
        class="trix-button--icon trix-button--icon-bold"
        data-trix-attribute="bold"
        data-trix-key="b"
        title="${lang.bold}"
        tabindex="-1"
      >
        ${lang.bold}
      </button>
      <button
        type="button"
        class="trix-button--icon trix-button--icon-italic"
        data-trix-attribute="italic"
        data-trix-key="i"
        title="${lang.italic}"
        tabindex="-1"
      >
        ${lang.italic}
      </button>
      <button
        type="button"
        class="trix-button--icon trix-button--icon-strike"
        data-trix-attribute="strike"
        title="${lang.strike}"
        tabindex="-1"
      >
        ${lang.strike}
      </buttontype=>
      <button
        type="button"
        class="trix-button--icon trix-button--icon-link"
        data-trix-attribute="href" data-trix-action="link" data-trix-key="k"
        title="${lang.link}"
        tabindex="-1"
      >
        ${lang.link}
      </buttontype=>
      <button
        type="button"
        class="trix-button--icon trix-button--icon-heading-1"
        data-trix-attribute="heading1"
        title="${lang.heading1}"
        tabindex="-1"
      >
        ${lang.heading1}
      </buttontype=>
      <button
        type="button"
        class="trix-button--icon trix-button--icon-quote"
        data-trix-attribute="quote"
        title="${lang.quote}"
        tabindex="-1"
      >
        ${lang.quote}
      </button>
      <button
        type="button"
        class="trix-button--icon trix-button--icon-code"
        data-trix-attribute="code"
        title="${lang.code}"
        tabindex="-1"
      >
        ${lang.code}
      </buttontype=>
      <button
        type="button"
        class="trix-button--icon trix-button--icon-bullet-list"
        data-trix-attribute="bullet"
        title="${lang.bullets}"
        tabindex="-1"
      >
        ${lang.bullets}
      </buttontype=>
      <button
        type="button"
        class="trix-button--icon trix-button--icon-number-list"
        data-trix-attribute="number"
        title="${lang.numbers}"
        tabindex="-1"
      >
        ${lang.numbers}
      </buttontype=>
      <button
        type="button"
        class="trix-button--icon trix-button--icon-attach" data-trix-action="attachFiles"
        title="${lang.attachFiles}"
        tabindex="-1"
      >
        ${lang.attachFiles}
      </button>
    </div>
    <div class="flex gap-x-4 justify-center">
      <button
        type="button"
        class="trix-button--icon trix-button--icon-undo"
        data-trix-action="undo"
        data-trix-key="z"
        title="${lang.undo}"
        tabindex="-1"
      >
        ${lang.undo}
      </button>
      <button
        type="button"
        class="trix-button--icon trix-button--icon-redo"
        data-trix-action="redo"
        data-trix-key="shift+z"
        title="${lang.redo}"
        tabindex="-1"
      >
        ${lang.redo}
      </buttontype=>
    </div>
  </div>
  <div class="trix-dialogs" data-trix-dialogs>
    <div class="trix-dialog trix-dialog--link" data-trix-dialog="href" data-trix-dialog-attribute="href">
      <div class="trix-dialog__link-fields">
        <input
          type="url"
          name="href"
          class="trix-input
          trix-input--dialog"
          placeholder="${lang.urlPlaceholder}"
          aria-label="${lang.url}"
          required
          data-trix-input
        >
        <div class="trix-button-group">
          <input
            type="button"
            class="trix-button trix-button--dialog"
            value="${lang.link}"
            data-trix-method="setAttribute"
          >
          <input
            type="button"
            class="trix-button trix-button--dialog"
            value="${lang.unlink}"
            data-trix-method="removeAttribute"
          >
        </div>
      </div>
    </div>
  </div>
`;
}

Trix.config.toolbar.getDefaultHTML = toolbarDefaultHTML;

function updateToolbars(_event) {
  const toolbars = document.querySelectorAll('trix-toolbar');
  const html = Trix.config.toolbar.getDefaultHTML();
  toolbars.forEach((toolbar) => (toolbar.innerHTML = html));
}

document.addEventListener('trix-initialize', updateToolbars, { once: true });

/* eslint-disable */
export default {
  name: 'vue-trix',
  model: {
    prop: 'srcContent',
    event: 'update',
  },
  emits: [
    'input',
    'update',
    'trix-focus',
    'trix-blur',
    'trix-initialize',
    'trix-file-accept',
    'trix-attachment-add',
    'trix-attachment-remove',
    'trix-selection-change',
  ],
  props: {
    /**
     * This prop will put the editor in read-only mode
     */
    disabledEditor: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    /**
     * This is referenced `id` of the hidden input field defined.
     * It is optional and will be a random string by default.
     */
    inputId: {
      type: String,
      required: false,
      default() {
        return '';
      },
    },
    /**
     * This is referenced `name` of the hidden input field defined,
     * default value is `content`.
     */
    inputName: {
      type: String,
      required: false,
      default() {
        return 'content';
      },
    },
    /**
     * The placeholder attribute specifies a short hint
     * that describes the expected value of a editor.
     */
    placeholder: {
      type: String,
      required: false,
      default() {
        return '';
      },
    },
    /**
     * The source content is associated to v-model directive.
     */
    srcContent: {
      type: String,
      required: false,
      default() {
        return '';
      },
    },
    /**
     * The boolean attribute allows saving editor state into browser's localStorage
     * (optional, default is `false`).
     */
    localStorage: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    /**
     * Focuses cursor in the editor when attached to the DOM
     * (optional, default is `false`).
     */
    autofocus: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    /**
     * Object to override default editor configuration
     */
    config: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },
  mounted() {
    /** Override editor configuration */
    this.overrideConfig(this.config);
    /** Check if editor read-only mode is required */
    this.decorateDisabledEditor(this.disabledEditor);
    this.$nextTick(() => {
      /**
       *  If localStorage is enabled,
       *  then load editor's content from the beginning.
       */
      if (this.localStorage) {
        const savedValue = localStorage.getItem(this.storageId('VueTrix'));
        if (savedValue && !this.srcContent) {
          this.$refs.trix.editor.loadJSON(JSON.parse(savedValue));
        }
      }
    });
  },
  data() {
    return {
      editorContent: this.srcContent,
      isActivated: null,
    };
  },
  methods: {
    processTrixFocus(event) {
      if (this.$refs.trix) {
        this.isActivated = true;
        this.$emit('trix-focus', this.$refs.trix.editor, event);
      }
    },
    processTrixBlur(event) {
      if (this.$refs.trix) {
        this.isActivated = false;
        this.$emit('trix-blur', this.$refs.trix.editor, event);
      }
    },
    handleContentChange(event) {
      this.editorContent = event.srcElement ? event.srcElement.value : event.target.value;
      this.$emit('input', this.editorContent);
    },
    handleInitialize(event) {
      /**
       * If autofocus is true, manually set focus to
       * beginning of content (consistent with Trix behavior)
       */
      if (this.autofocus) {
        this.$refs.trix.editor.setSelectedRange(0);
      }

      this.$emit('trix-initialize', this.emitInitialize);
    },
    handleInitialContentChange(newContent, oldContent) {
      newContent = newContent === undefined ? '' : newContent;

      if (this.$refs.trix.editor && this.$refs.trix.editor.innerHTML !== newContent) {
        /* Update editor's content when initial content changed */
        this.editorContent = newContent;

        /**
         *  If user are typing, then don't reload the editor,
         *  hence keep cursor's position after typing.
         */
        if (!this.isActivated) {
          this.reloadEditorContent(this.editorContent);
        }
      }
    },
    emitEditorState(value) {
      /**
       * If localStorage is enabled,
       * then save editor's content into storage
       */
      if (this.localStorage) {
        localStorage.setItem(
          this.storageId('VueTrix'),
          JSON.stringify(this.$refs.trix.editor),
        );
      }
      this.$emit('update', this.editorContent);
    },
    storageId(component) {
      if (this.inputId) {
        return `${component}.${this.inputId}.content`;
      }

      return `${component}.content`;
    },
    reloadEditorContent(newContent) {
      // Reload HTML content
      this.$refs.trix.editor.loadHTML(newContent);

      // Move cursor to end of new content updated
      this.$refs.trix.editor.setSelectedRange(this.getContentEndPosition());
    },
    getContentEndPosition() {
      return this.$refs.trix.editor.getDocument().toString().length - 1;
    },
    decorateDisabledEditor(editorState) {
      /** Disable toolbar and editor by pointer events styling */
      if (editorState) {
        this.$refs.trix.toolbarElement.style['pointer-events'] = 'none';
        this.$refs.trix.contentEditable = false;
        this.$refs.trix.style.background = '#e9ecef';
      } else {
        // this.$refs.trix.toolbarElement.style['pointer-events'] = 'unset';
        this.$refs.trix.style['pointer-events'] = 'unset';
        this.$refs.trix.style.background = 'transparent';
      }
    },
    overrideConfig(config) {
      Trix.config = this.deepMerge(Trix.config, config);
    },
    deepMerge(target, override) {
      // deep merge the object into the target object
      for (const prop in override) {
        if (override.hasOwnProperty(prop)) {
          if (Object.prototype.toString.call(override[prop]) === '[object Object]') {
            // if the property is a nested object
            target[prop] = this.deepMerge(target[prop], override[prop]);
          } else {
            // for regular property
            target[prop] = override[prop];
          }
        }
      }

      return target;
    },
  },
  computed: {
    /**
     * Compute a random id of hidden input
     * when it haven't been specified.
     */
    generateId() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        const r = Math.random() * 16 | 0;
        const v = c === 'x' ? r : (r & 0x3 | 0x8);

        return v.toString(16);
      });
    },
    computedId() {
      return this.inputId || this.generateId;
    },
    initialContent() {
      return this.srcContent;
    },
    isDisabled() {
      return this.disabledEditor;
    },
  },
  watch: {
    editorContent: {
      handler: 'emitEditorState',
    },
    initialContent: {
      handler: 'handleInitialContentChange',
    },
    isDisabled: {
      handler: 'decorateDisabledEditor',
    },
    config: {
      handler: 'overrideConfig',
      deep: true,
    },
  },
};
/* eslint-enable */
</script>

<style scoped>
.trix_container {
  max-width: 100%;
  height: auto;
}
</style>
