import type { GroupLoanApplication } from '@/api/groupLoanApplication';
import type { GroupMemberLoanApplication } from '@/api/groupMemberLoanApplication';

// eslint-disable-next-line complexity
export function groupMemberLoanApplicationBadgeColor(aasmState: GroupMemberLoanApplication['aasmState']) {
  switch (aasmState) {
  case 'draft':
    return 'gray';
  case 'submitted':
    return 'green';
  case 'in_kyc':
    return 'blue';
  case 'kyc_submitted':
    return 'green';
  case 'contract_signature_pending':
    return 'yellow';
  case 'contract_signed':
    return 'green';
  case 'rejected':
    return 'red';
  default:
    return 'gray';
  }
}

export function groupLoanApplicationBadgeColor(status: GroupLoanApplication['aasmState']) {
  const colors = {
    'inviting': 'yellow',
    'draft': 'blue',
    'application_in_review': 'green',
    'in_kyc': 'pink',
    'kyc_in_review': 'pink',
    'contracts_signature_pending': 'pink',
    'contracts_signed': 'gray',
    'application_rejected': 'red',
    'disbursed': 'gray',
    'canceled': 'red',
  } as const;

  return colors[status];
}

