import type { MutationOptions } from '@tanstack/vue-query';

import type { ApiError } from '@/api';
import { create, type CreateBody } from '@/api/group/support/index';
import useReactiveMutation from '@/composables/useReactiveMutation';

type CreateReturn = Awaited<ReturnType<typeof create>>['data'];
type UseCreateGroupMutationArgs = MutationOptions<CreateReturn, ApiError, CreateBody>;

function useCreateGroup(options: UseCreateGroupMutationArgs = {}) {
  return useReactiveMutation({
    ...options,
    mutationFn: (body) => create(body).then((response) => response.data),
  });
}

export { useCreateGroup };
