<script setup lang="ts">
import { useField } from 'vee-validate';
import { toRef, watch } from 'vue';

import { useCreateDocument, useDestroyDocument } from '@/api/document/mutations';
import { MS_IN_SECOND } from '@/utils/date';

import VueTrix from './vue-trix.vue';

interface Props {
  modelValue: string;
  loadingAttachmentModelValue: boolean;
  submittedAttachmentIds: number[];
  label?: string;
  description?: string;
  name: string;
  variant?: 'base';
  size?: 'small' | 'base';
}
interface Emits {
  (e: 'update:modelValue', value: string): void;
  (e: 'update:loadingAttachmentModelValue', value: boolean): void;
  (e: 'add-attachment-id', id: number): void;
  (e: 'remove-attachment-id', id: number): void;
}
const props = withDefaults(defineProps<Props>(), {
  label: '',
  description: '',
  variant: 'base',
  size: 'base',
});
const emit = defineEmits<Emits>();

const name = toRef(props, 'name');
const {
  value: inputValue,
  handleChange,
  errorMessage,
} = useField(name, undefined, { initialValue: props.modelValue });

watch(inputValue, (newVal) => {
  emit('update:modelValue', newVal);
});

const createDocumentMutation = useCreateDocument({
  onMutate: () => {
    emit('update:loadingAttachmentModelValue', true);
  },
  onSuccess: () => {
    emit('update:loadingAttachmentModelValue', false);
  },
  onError: () => {
    emit('update:loadingAttachmentModelValue', false);
  },
});
const destroyDocumentMutation = useDestroyDocument({
  onMutate: () => {
    emit('update:loadingAttachmentModelValue', true);
  },
  onSuccess: () => {
    emit('update:loadingAttachmentModelValue', false);
  },
  onError: () => {
    emit('update:loadingAttachmentModelValue', false);
  },
});

interface TrixAttachment {
  file: File;
  createdDocumentId: number;
  setAttributes: (attributes: {
    url?: number;
    href?: string;
    createdDocumentId?: number;
  }) => void;
  setUploadProgress: (progress: number) => void;
}

interface TrixAddAttachmentEvent {
  attachment: TrixAttachment;
}

interface TrixRemoveAttachmentEvent {
  attachment: {
    attachment: {
      attributes: {
        values: TrixAttachment;
      };
    };
  };
}

const FULL_PERCENTAGE = 100;
const HALF_PERCENTAGE = 50;

async function handleAttachmentAdd(event: TrixAddAttachmentEvent) {
  const file = event.attachment.file;

  const document = await createDocumentMutation.mutateAsync({ file, name: file.name, label: 'comment_attachment' });

  event.attachment.setAttributes({
    url: document.id,
    href: document.fileUrl,
    createdDocumentId: document.id,
  });
  emit('add-attachment-id', document.id);
  event.attachment.setUploadProgress(HALF_PERCENTAGE);

  setTimeout(() => {
    event.attachment.setUploadProgress(FULL_PERCENTAGE);
  }, MS_IN_SECOND);
}

function handleAttachmentRemove(event: TrixRemoveAttachmentEvent) {
  const documentId = event.attachment.attachment.attributes.values.createdDocumentId;
  if (!props.submittedAttachmentIds.includes(documentId)) {
    destroyDocumentMutation.mutate(documentId);
    emit('remove-attachment-id', documentId);
  }
}
</script>

<template>
  <div class="flex w-full flex-col gap-y-4">
    <label
      :for="name"
      class="flex flex-col gap-y-1"
    >
      <span class="text-sm font-medium text-gray-900">
        {{ label }}
      </span>
      <span
        v-if="description"
        class="text-xs font-light text-gray-700"
      >
        {{ description }}
      </span>
    </label>
    <vue-trix
      :src-content="modelValue"
      v-bind="$attrs"
      :input-name="name"
      :class="[
        `vue-trix-editor vue-trix-editor--${variant}`,
        { 'vue-trix-editor--error': errorMessage }
      ]"
      @input="handleChange"
      @trix-attachment-add="handleAttachmentAdd"
      @trix-attachment-remove="handleAttachmentRemove"
    />
    <span
      v-if="errorMessage"
      class="mt-0.5 text-sm text-red-600"
    >
      {{ errorMessage }}
    </span>
  </div>
</template>

<style lang="scss">
  .vue-trix-editor {
    &.vue-trix-editor--base {
      trix-editor {
        @apply bg-gray-100 box-border border border-transparent text-gray-700
          ring-1 ring-inset ring-gray-300 transition duration-300 ease-in-out
          focus:ring-2 focus:ring-primary-600
          hover:border-primary-500 #{!important};
      }
    }

    &.vue-trix-editor--error {
      trix-editor {
        @apply border-red-600 #{!important};
      }
    }

    trix-editor {
      @apply appearance-none rounded-md w-full py-2 px-3 text-sm sm:text-base;

      .attachment__caption {
        @apply hidden;
      }

      pre {
        @apply text-red-500;
      }

      ul {
        @apply list-disc;
      }

      ol {
        @apply list-decimal;
      }
    }

    trix-toolbar {
      .trix-button-group {
        @apply border-none flex justify-around mb-2;

        &:not(:first-child) {
          @apply ml-4;
        }
      }

      .trix-button-row {
        @apply flex flex-row px-1;
      }

      .trix-button {
        @apply w-6 h-6 max-w-none mx-0.5 border-none rounded-md #{!important};

        &--dialog {
          @apply w-auto;
        }

          &--icon {
          top: 50%;
          left: 50%;
          @apply w-4 h-4 transform -translate-x-1/2 -translate-y-1/2 opacity-100;
        }

        &--icon::before {
          top: 50%;
          left: 50%;
          @apply w-4 h-4 transform -translate-x-1/2 -translate-y-1/2 opacity-100;
        }

        &:not(:first-child) {
          @apply border-l-0;
        }

        &.trix-button--icon-bold::before {
          background-image: url('/trix-toolbar/bold-inactive.svg');
        }

        &.trix-button--icon-italic::before {
          background-image: url('/trix-toolbar/italic-inactive.svg');
        }

        &.trix-button--icon-strike::before {
          background-image: url('/trix-toolbar/strike-inactive.svg');
        }

        &.trix-button--icon-link::before {
          background-image: url('/trix-toolbar/link-inactive.svg');
        }

        &.trix-button--icon-heading-1::before {
          background-image: url('/trix-toolbar/heading-1-inactive.svg');
        }

        &.trix-button--icon-quote::before {
          background-image: url('/trix-toolbar/quote-inactive.svg');
        }

        &.trix-button--icon-code::before {
          background-image: url('/trix-toolbar/code-inactive.svg');
        }

        &.trix-button--icon-bullet-list::before {
          background-image: url('/trix-toolbar/bullet-list-inactive.svg');
        }

        &.trix-button--icon-number-list::before {
          background-image: url('/trix-toolbar/number-list-inactive.svg');
        }

        &.trix-button--icon-decrease-nesting-level::before {
          background-image: url('/trix-toolbar/decrease-nesting-level-inactive.svg');
        }

        &.trix-button--icon-increase-nesting-level::before {
          background-image: url('/trix-toolbar/increase-nesting-level-inactive.svg');
        }

        &.trix-button--icon-attach::before {
          background-image: url('/trix-toolbar/attach-inactive.svg');
        }

        &.trix-button--icon-undo::before {
          background-image: url('/trix-toolbar/undo-inactive.svg');
        }

        &.trix-button--icon-redo::before {
          background-image: url('/trix-toolbar/redo-inactive.svg');
        }

        &.trix-active {
          @apply bg-primary-600;

          &.trix-button--icon-bold::before {
            background-image: url('/trix-toolbar/bold-active.svg');
          }

          &.trix-button--icon-italic::before {
            background-image: url('/trix-toolbar/italic-active.svg');
          }

          &.trix-button--icon-strike::before {
            background-image: url('/trix-toolbar/strike-active.svg');
          }

          &.trix-button--icon-link::before {
            background-image: url('/trix-toolbar/link-active.svg');
          }

          &.trix-button--icon-heading-1::before {
            background-image: url('/trix-toolbar/heading-1-active.svg');
          }

          &.trix-button--icon-quote::before {
            background-image: url('/trix-toolbar/quote-active.svg');
          }

          &.trix-button--icon-code::before {
            background-image: url('/trix-toolbar/code-active.svg');
          }

          &.trix-button--icon-bullet-list::before {
            background-image: url('/trix-toolbar/bullet-list-active.svg');
          }

          &.trix-button--icon-number-list::before {
            background-image: url('/trix-toolbar/number-list-active.svg');
          }

          &.trix-button--icon-decrease-nesting-level::before {
            background-image: url('/trix-toolbar/decrease-nesting-level-active.svg');
          }

          &.trix-button--icon-increase-nesting-level::before {
            background-image: url('/trix-toolbar/increase-nesting-level-active.svg');
          }

          &.trix-button--icon-attach::before {
            background-image: url('/trix-toolbar/attach-active.svg');
          }

          &.trix-button--icon-undo::before {
            background-image: url('/trix-toolbar/undo-active.svg');
          }

          &.trix-button--icon-redo::before {
            background-image: url('/trix-toolbar/redo-active.svg');
          }
        }
      }

      .trix-input {
        &--dialog {
          @apply mr-0;
        }
      }
    }
  }
</style>
