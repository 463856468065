<script setup lang="ts">
import { useForm } from 'vee-validate';
import { object } from 'yup';

import type { GroupLoanRenewalForm } from '@/api/groupLoan/renewal/support';
import { useCreateGroupLoanRenewal } from '@/api/groupLoan/renewal/support/mutations';
import BaseButton from '@/components/base-button.vue';
import VeeValidatePhoneInput from '@/components/vee-validate/vee-validate-phone-input.vue';
import useUiStore from '@/stores/ui';
import { phoneNumberSchema } from '@/utils/yupRules';

const uiStore = useUiStore();

const { handleSubmit } = useForm<GroupLoanRenewalForm>({
  validationSchema: object({
    user: object({
      phoneNumber: phoneNumberSchema,
    }),
  }),
});

const createGroupLoanRenewalMutation = useCreateGroupLoanRenewal({
  onSuccess: (groupLoanApplication) => {
    window.location.href = `/support/group_loan_applications/${groupLoanApplication.id}/edit`;
  },
  onError: (error) => {
    uiStore.toast({
      message: error.response?.data?.detail || 'Hubo un error al crear el grupo',
      type: 'error',
      position: 'top',
    });
  },
});

const onSubmit = handleSubmit((values) => {
  createGroupLoanRenewalMutation.mutate(values);
});
</script>

<template>
  <div class="container flex flex-col gap-y-4">
    <h1 class="text-xl font-bold">
      Solicitar renovación
    </h1>
    <form
      class="flex flex-1 flex-col gap-y-5 rounded-lg bg-white p-4"
      @submit="onSubmit"
    >
      <vee-validate-phone-input
        label="Número de teléfono"
        name="user.phoneNumber"
        placeholder="Teléfono de la presidenta"
      />
      <base-button
        type="submit"
        text="Enviar"
      />
    </form>
  </div>
</template>
