<script setup lang="ts">
import { useForm } from 'vee-validate';
import { object, string } from 'yup';

import BaseModal from '@/components/base-modal.vue';
import VeeValidateInput from '@/components/vee-validate/vee-validate-input.vue';

interface Props {
  show: boolean;
}

defineProps<Props>();
const emit = defineEmits(['close', 'submit']);
const validationSchema = object({
  cancellationReason: string().required('Es necesario que expliques el por qué').trim(),
});
const {
  values, handleSubmit, resetForm,
} = useForm({ validationSchema, initialValues: { cancellationReason: undefined } });

const onSubmit = handleSubmit(() => {
  emit('submit', values);
  resetForm();
});

</script>
<template>
  <base-modal
    :show="show"
    title="Vas a cancelar la solicitud"
    cancel-button-text="Volver"
    accept-button-text="Confirmar"
    @close="$emit('close')"
    @accept="onSubmit"
  >
    <template #body>
      <form class="mt-10">
        <vee-validate-input
          type="textarea"
          label="¿Por qué quieres hacerlo?"
          name="cancellationReason"
          placeholder="Escribe todo lo que te llevó a tomar esta decisión"
        />
      </form>
    </template>
  </base-modal>
</template>
