<script setup lang="ts">
import { XMarkIcon, ArrowUpTrayIcon } from '@heroicons/vue/24/outline';
import { useField } from 'vee-validate';
import { ref, computed, toRef } from 'vue';

interface Props {
  inputId: string;
  name: string;
  label?: string;
  description?: string;
  extensions: string;
  sizeLimit?: number;
  initialFileUrl?: string | null;
  initialFileName?: string | null;
}

const props = withDefaults(defineProps<Props>(), {
  extensions: '.JPG, .PNG, .JPEG',
  label: undefined,
  description: undefined,
  sizeLimit: undefined,
  initialFileUrl: undefined,
  initialFileName: undefined,
});

const {
  value: inputValue,
  errorMessage,
  handleChange,
  meta,
  setTouched,
} = useField<File | null>(toRef(props, 'name'));

const inputRef = ref<HTMLElement | null>(null);
const initialFileUrlRef = ref<string|undefined|null>(props.initialFileUrl);

function handleDrop(event: DragEvent) {
  event.preventDefault();
  if (event.dataTransfer && event.dataTransfer.files) {
    handleChange(event.dataTransfer.files[0]);
    initialFileUrlRef.value = undefined;
  }
}

function handleInput(event: Event) {
  const target = event.target as HTMLInputElement;
  if (target.files) {
    setTouched(false);
    handleChange(target.files[0]);
    initialFileUrlRef.value = undefined;
  }
}

const displayUrl = computed(() => {
  if (inputValue.value) {
    return URL.createObjectURL(inputValue.value);
  }

  return initialFileUrlRef.value;
});

const isImage = computed(() => {
  if (inputValue.value) {
    return inputValue.value.type.includes('image');
  }

  return initialFileUrlRef.value?.match(/\.(jpg|jpeg|png)$/i);
});

function handleRemove() {
  handleChange(null);
  initialFileUrlRef.value = undefined;
}
</script>

<template>
  <div :class="$attrs.class || 'flex w-full flex-col items-start'">
    <label
      v-if="label"
      :for="name"
      class="font-medium leading-6 text-gray-900"
    >
      {{ label }}
    </label>
    <p
      v-if="description"
      class="mt-1.5 text-xs font-light text-gray-700"
    >
      {{ description }}
    </p>
    <label
      class="relative mt-2 flex w-full cursor-pointer items-center justify-center rounded-md border-2 border-dashed border-primary-600 p-4"
      @dragenter.prevent
      @dragover.prevent
      @drop.prevent="handleDrop"
    >
      <button
        v-if="inputValue || initialFileUrlRef"
        class="absolute -right-3 -top-3 items-center justify-center rounded-full bg-primary-600 p-1 focus:outline-none"
        @click.prevent="handleRemove"
      >
        <x-mark-icon class="h-auto w-4 text-white" />
      </button>
      <input
        :id="inputId"
        v-bind="$attrs"
        ref="inputRef"
        type="file"
        :accept="extensions"
        :name="name"
        class="hidden"
        @change="handleInput"
      >
      <div
        class="relative flex flex-col items-center justify-center space-y-3"
      >
        <img
          v-if="isImage && displayUrl"
          :src="displayUrl"
          class="h-full max-h-36 w-full rounded-md object-cover"
        >
        <template v-else>
          <ArrowUpTrayIcon
            class="h-8 w-auto text-gray-400"
          />
          <span
            v-if="inputValue || initialFileUrlRef"
            class="flex h-8 w-full items-center justify-center break-all text-center sm:h-12"
          >
            {{ inputValue?.name || initialFileName }}
          </span>
          <div
            v-else
            class="flex h-8 flex-col items-center justify-center sm:h-12"
          >
            <p class="text-sm font-medium text-gray-700">
              Sube un archivo
            </p>
            <div v-if="extensions || sizeLimit">
              <span
                v-if="extensions"
                class="text-xs uppercase text-gray-600"
              >
                {{ extensions.replaceAll('.', '') }}
              </span>
              <span v-if="sizeLimit">
                Hasta {{ sizeLimit }} MB
              </span>
            </div>
          </div>
        </template>
      </div>
    </label>
    <span
      v-if="errorMessage"
      class="mt-1 text-xs text-red-500 sm:text-sm"
      :class="{
        'animate-bounce': meta.touched,
      }"
    >
      {{ errorMessage }}
    </span>
  </div>
</template>
