<script setup lang="ts">
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/vue';
import { ChevronUpIcon } from '@heroicons/vue/20/solid';
import { useField } from 'vee-validate';
import { watch, computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { useFetchTags } from '@/api/tags/queries';
import Comments from '@/components/comments.vue';
import type {
  GroupMemberLoanApplicationWithLabels,
} from '@/components/reviews/group-loan-application-pre-approval-slide-over.vue';
import VeeValidateCheckbox from '@/components/vee-validate/vee-validate-checkbox.vue';
import VeeValidateInput from '@/components/vee-validate/vee-validate-input.vue';
import VeeValidateSelect from '@/components/vee-validate/vee-validate-select.vue';

interface Props {
  groupMemberLoanApplication: GroupMemberLoanApplicationWithLabels
  rejectionTags: string[];
}

const props = defineProps<Props>();

const { t } = useI18n();
const rejectionTagsQ = useFetchTags({
  resourceId: props.groupMemberLoanApplication.id,
  resourceType: 'GroupMemberLoanApplication',
  context: 'rejection_tags',
});

const rejectionTagOptions = computed(() => props.rejectionTags.map(
  (rejectionTag) => ({ label: t(`groupMemberLoanApplication.rejectionTags.${rejectionTag}`), value: rejectionTag }),
));

const { value: preApprovedValue } = useField(`${props.groupMemberLoanApplication.id}.preApproved`);
const { setValue } = useField(`${props.groupMemberLoanApplication.id}.rejectionTags`);

watch(() => rejectionTagsQ.isFetched, () => {
  setValue(rejectionTagsQ.data?.map((element) => element.name));
});

</script>
<template>
  <Disclosure v-slot="{ open: disclosureOpen }">
    <DisclosureButton
      class="flex w-full justify-between rounded-lg bg-gray-100 px-4 py-2 text-left text-sm font-medium text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-500/75"
    >
      <p>{{ groupMemberLoanApplication.groupMember.user.fullName }}</p>
      <ChevronUpIcon
        :class="disclosureOpen && 'rotate-180 transform'"
        class="h-5 w-5 text-gray-500"
      />
    </DisclosureButton>
    <DisclosurePanel>
      <div class="flex flex-col gap-y-8 px-8">
        <ul>
          <li
            v-for="(value, label) in groupMemberLoanApplication.slideOverItems"
            :key="label"
            class="grid grid-cols-2 border-b border-gray-200 py-4"
          >
            <p class="text-sm font-semibold">
              {{ label }}
            </p>
            <p class="text-sm text-gray-600">
              {{ value }}
            </p>
          </li>
          <li class="grid grid-cols-2 items-center py-4">
            <p class="text-sm font-semibold">
              Monto aceptado
            </p>
            <vee-validate-input
              :name="`${groupMemberLoanApplication.id}.acceptedAmount`"
              type="number"
              placeholder="Monto aceptado"
            />
          </li>
          <li class="grid grid-cols-2 items-center py-4">
            <p class="text-sm font-semibold">
              Tasa de interés
            </p>
            <vee-validate-input
              :name="`${groupMemberLoanApplication.id}.interestRate`"
              type="number"
              placeholder="Tasa de interés"
              :step="0.01"
            />
          </li>
          <li class="grid grid-cols-2 items-center py-4">
            <p class="text-sm font-semibold">
              Cumple políticas de crédito
            </p>
            <vee-validate-checkbox
              :name="`${groupMemberLoanApplication.id}.meetsLoanConditions`"
            />
          </li>
          <li class="grid grid-cols-2 items-center py-4">
            <p class="text-sm font-semibold">
              Integrante consolidado
            </p>
            <vee-validate-checkbox
              :name="`${groupMemberLoanApplication.id}.consolidated`"
            />
          </li>
          <li class="grid grid-cols-2 items-center gap-y-4 py-4">
            <p class="text-sm font-semibold">
              Pre-aprobar solicitud
            </p>
            <vee-validate-select
              :name="`${groupMemberLoanApplication.id}.preApproved`"
              :options="[{label: 'Si', value: true}, {label: 'No', value: false}]"
            />
          </li>
          <li
            v-if="preApprovedValue === false"
            class="grid grid-cols-2 items-center gap-y-4 py-4"
          >
            <p class="text-sm font-semibold">
              Razones de rechazo
            </p>
            <div>
              <vee-validate-select
                :name="`${groupMemberLoanApplication.id}.rejectionTags`"
                mode="tags"
                :options="rejectionTagOptions"
              />
            </div>
          </li>
        </ul>
        <comments
          name="comment[body]"
          comments-height-class="h-40"
          resource-type="GroupMemberLoanApplication"
          :resource-id="groupMemberLoanApplication.id"
        />
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>
