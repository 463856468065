<template>
  <div class="flex w-full flex-col items-center justify-center rounded-lg bg-white py-6 text-center">
    <p>
      La solicitud fue enviada correctamente,
      pero aún <span class="font-bold text-primary-700">no</span> se ha obtenido el consentimiento para la consulta de buró de crédito.
    </p>
    <p>
      Al usuario le debió haber llegado un mensaje de Whastapp solicitando su consentimiento.
    </p>
  </div>
</template>
