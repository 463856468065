<script setup lang="ts">
import { useForm } from 'vee-validate';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { object, string, array } from 'yup';

import BaseModal from '@/components/base-modal.vue';
import VeeValidateSelect from '@/components/vee-validate/vee-validate-select.vue';

interface Props {
  show: boolean;
  rejectionTags: string[];
}

const props = defineProps<Props>();
const emit = defineEmits(['close', 'submit']);
const validationSchema = object({
  rejectionTags: array().of(
    string().oneOf(props.rejectionTags, 'Las razones elegidas no corresponden a las existentes').required(),
  ).min(1, 'Tienes que elegir al menos una razón'),
});
const { values, handleSubmit, resetForm } = useForm({ validationSchema, initialValues: { rejectionTags: undefined } });
const { t } = useI18n();

const rejectionTagOptions = computed(() => props.rejectionTags.map(
  (rejectionTag) => ({ label: t(`groupLoanApplication.rejectionTags.${rejectionTag}`), value: rejectionTag }),
));

const onSubmit = handleSubmit(() => {
  emit('submit', { ...values });
  resetForm();
});

</script>
<template>
  <base-modal
    :show="show"
    title="Vas a rechazar esta solicitud"
    cancel-button-text="Volver"
    accept-button-text="Rechazar"
    @close="$emit('close')"
    @accept="onSubmit"
  >
    <template #body>
      <form class="mt-10">
        <vee-validate-select
          label="¿Por qué quieres hacerlo?"
          name="rejectionTags"
          with-undefined-value
          :options="rejectionTagOptions"
          mode="tags"
        />
      </form>
    </template>
  </base-modal>
</template>
