<script setup lang="ts">
import { ref, reactive, defineEmits } from 'vue';
import { object } from 'yup';

import { useCreateMagicCodeMutation } from '@/api/user/mutations';
import BaseAlert from '@/components/base-alert.vue';
import BaseButton from '@/components/base-button.vue';
import BaseImage from '@/components/base-image.vue';
import BasePhoneInput from '@/components/base-phone-input.vue';
import VForm from '@/components/v-form.vue';
import useUIStore from '@/stores/ui';
import { phoneNumberSchema } from '@/utils/yupRules';

interface Emits {
  (event: 'phone-number-submitted', phoneNumber: string): void;
}

const emits = defineEmits<Emits>();

const form = reactive({
  phoneNumber: '',
});

const showDownloadAppOverlay = ref(false);

const uiStore = useUIStore();

const createMagicCodeMutation = useCreateMagicCodeMutation({
  onSuccess: () => uiStore.toast({
    message: 'Hemos enviado un código a tu Whatsapp!',
    type: 'success',
    position: 'top',
  }),
  onError: (error) => {
    if (error.response?.status === 403) {
      showDownloadAppOverlay.value = true;
    } else {
      uiStore.toast({
        message: error.response?.data?.detail || 'No hay un usuario registrado con ese número de teléfono',
        type: 'error',
        position: 'top',
      });
    }
  },
});

function handleSubmit(values) {
  form.phoneNumber = values.phoneNumber;
  createMagicCodeMutation.mutate(form.phoneNumber, {
    onSuccess: () => {
      emits('phone-number-submitted', form.phoneNumber);
    },
  });
}

const validationSchema = object({ phoneNumber: phoneNumberSchema });
</script>

<template>
  <app-download-overlay v-if="showDownloadAppOverlay" />
  <v-form
    v-else
    class="flex w-full flex-col gap-y-8 rounded-xl bg-white px-4 py-6 sm:gap-y-12 sm:px-8"
    :validation-schema="validationSchema"
    @submit="handleSubmit"
  >
    <base-image
      img-name="logo"
      alt="Logo de grupalia"
      class="my-4 h-12 w-auto fill-current text-black"
    />
    <h2 class="text-xl font-bold">
      Iniciar sesión
    </h2>
    <base-alert
      v-if="createMagicCodeMutation.isSuccess"
      type="success"
      message="Hemos enviado un código a tu Whatsapp!"
    />
    <p class="text-sm">
      Te enviaremos un código a tu <b>Whatsapp</b>. Deberás ingresarlo para entrar a tu cuenta.
    </p>
    <div class="flex flex-col gap-y-4">
      <base-phone-input
        name="phoneNumber"
        label="Número de teléfono"
      />
      <base-button
        text="Iniciar sesión"
        type="submit"
        :loading="createMagicCodeMutation.isPending"
      />
    </div>
  </v-form>
</template>

