<script setup lang="ts">
import { computed } from 'vue';

import type { IUser } from '@/api/currentUser';

interface Props {
  user: IUser;
  size?: 'sm' | 'md' | 'lg' | 'xl';
}

const props = withDefaults(defineProps<Props>(), {
  size: 'md',
});

const sizeClasses = computed(() => {
  switch (props.size) {
  case 'sm':
    return 'h-8 w-8';
  case 'md':
    return 'h-10 w-10';
  case 'lg':
    return 'h-16 w-16';
  case 'xl':
    return 'h-20 w-20';
  default:
    return 'h-12 w-12';
  }
});

const textClasses = computed(() => {
  switch (props.size) {
  case 'sm':
    return 'text-xs';
  case 'md':
    return 'text-sm';
  case 'lg':
    return 'text-lg';
  case 'xl':
    return 'text-xl';
  default:
    return 'text-sm';
  }
});

const initials = computed(
  () => props.user.firstName?.[0] + (props.user.paternalSurname?.[0] || ''),
);
</script>

<template>
  <div :class="['flex shrink-0 items-center justify-center overflow-hidden rounded-full bg-gray-500', sizeClasses]">
    <img
      v-if="user.avatarUrl"
      :src="user.avatarUrl"
      :alt="`Avatar de ${user.firstName}`"
      class="rounded-full"
    >
    <span
      v-else
      :class="['font-semibold text-white', textClasses]"
    >
      {{ initials }}
    </span>
  </div>
</template>
