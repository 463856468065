import { type MutationOptions } from '@tanstack/vue-query';
import { toRef, unref, type MaybeRef } from 'vue';

import type { ApiError } from '@/api';
import { signContract } from '@/api/groupMemberLoanApplication/contractSignature';
import { groupMemberLoanApplicationKey } from '@/api/queryKeys';
import useReactiveMutation from '@/composables/useReactiveMutation';

type SignContractReturn = Awaited<ReturnType<typeof signContract>>['data']['groupMemberLoanApplication'];
type SignContractMutationArgs = MutationOptions<SignContractReturn, ApiError> & {
  groupMemberLoanApplicationId: MaybeRef<number>;
};

function useSignContractMutation({ groupMemberLoanApplicationId, ...options }: SignContractMutationArgs) {
  return useReactiveMutation({
    ...options,
    mutationKey: groupMemberLoanApplicationKey(toRef(groupMemberLoanApplicationId)),
    mutationFn: () => (
      signContract(unref(groupMemberLoanApplicationId)).then(response => response.data.groupMemberLoanApplication)
    ),
  });
}

export { useSignContractMutation };
