<script setup lang="ts">
import { useDraggable, useElementBounding } from '@vueuse/core';
import { computed, ref } from 'vue';

import BaseModal from '@/components/base-modal.vue';
import useFilteredAttrs from '@/composables/useFilteredAttrs';

interface Props {
  src: string;
  caption?: string;
}

defineProps<Props>();

const isModalOpen = ref(false);

const rotation = ref(0);
const scale = ref(1);

const imageContainer = ref(null);
const draggable = ref<HTMLElement | null>(null);

const { top: boundsTop, left: boundsLeft } = useElementBounding(imageContainer);

const adjustedLeft = ref(0);
const adjustedTop = ref(0);

const { x: draggableX, y: draggableY } = useDraggable(draggable, {
  initialValue: {
    x: 0,
    y: 0,
  },
  onMove({ x, y }) {
    adjustedLeft.value = x - boundsLeft.value;
    adjustedTop.value = y - boundsTop.value;
  },
});

const draggableStyle = computed(() => ({
  left: `${adjustedLeft.value}px`,
  top: `${adjustedTop.value}px`,
}));

function rotateImage(degrees: number) {
  rotation.value = (rotation.value + degrees) % 360; // eslint-disable-line no-magic-numbers
}

function zoomImage(factor: number) {
  scale.value *= factor;
}

function resetImage() {
  rotation.value = 0;
  scale.value = 1;
  draggableX.value = 0;
  draggableY.value = 0;
  adjustedLeft.value = 0;
  adjustedTop.value = 0;
}

function openModal() {
  isModalOpen.value = true;
}

function closeModal() {
  isModalOpen.value = false;
  resetImage();
}

const modalImgStyles = computed(() => ({
  '--rotation': `${rotation.value}deg`,
  '--scale': scale.value,
}));

const filteredAttrs = useFilteredAttrs({ excluded: ['class'] });
</script>

<template>
  <div
    class="flex flex-col items-center"
    :class="$attrs.class"
  >
    <img
      :src="src"
      class="cursor-pointer"
      v-bind="filteredAttrs"
      @click="openModal"
    >
    <p
      v-if="caption"
      class="text-xs text-gray-500"
    >
      {{ caption }}
    </p>
  </div>
  <base-modal
    v-if="isModalOpen"
    :show="isModalOpen"
    :hide-actions="true"
    @close="closeModal"
  >
    <template #title>
      <slot name="title">
        <span>Imagen</span>
      </slot>
    </template>
    <template #body>
      <div
        ref="imageContainer"
        class="relative mt-4 h-[300px] w-[300px] self-center overflow-hidden lg:h-[500px] lg:w-[500px] xl:h-[600px] xl:w-[600px]"
      >
        <div
          ref="draggable"
          class="absolute h-full w-full"
          :style="draggableStyle"
        >
          <img
            id="modal-image"
            :src="src"
            :draggable="false"
            class="h-full w-full cursor-pointer object-contain"
            :style="modalImgStyles"
          >
        </div>
      </div>
      <div class="mt-4 flex justify-center gap-3">
        <button
          class="bg-gray-300 px-4 py-2"
          @click="rotateImage(-90)"
        >
          Rotate Left
        </button>
        <button
          class="bg-gray-300 px-4 py-2"
          @click="rotateImage(90)"
        >
          Rotate Right
        </button>
        <button
          class="bg-gray-300 px-4 py-2"
          @click="zoomImage(1.1)"
        >
          Zoom In
        </button>
        <button
          class="bg-gray-300 px-4 py-2"
          @click="zoomImage(0.9)"
        >
          Zoom Out
        </button>
        <button
          class="bg-gray-300 px-4 py-2"
          @click="resetImage"
        >
          Reset
        </button>
      </div>
    </template>
  </base-modal>
</template>

<style scoped>
#modal-image {
  transform: rotate(var(--rotation)) scale(var(--scale));
}
</style>

