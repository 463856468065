import { computed, useAttrs } from 'vue';

export default function useFilteredAttrs({ excluded }: { excluded: string[] }) {
  const attrs = useAttrs();
  const filteredAttrs = computed(() => Object.fromEntries(
    Object.entries(attrs).filter(([key]) => !excluded.includes(key)),
  ));

  return filteredAttrs;
}
