import type { CCReport, CCLoan } from '@/api/groupMemberLoanApplication/CC';

export function getGroupLoansFromConsolidatedCreditReport(
  consolidatedCreditReport: CCReport | null,
): CCLoan[] {
  if (!consolidatedCreditReport) {
    return [];
  }

  return (consolidatedCreditReport.creditos || []).filter((loan: CCLoan) =>
    ['Q', 'F'].includes(loan.tipoCuenta) &&
    ['C', 'M', 'Q', 'S'].includes(loan.frecuenciaPagos) &&
    // eslint-disable-next-line no-magic-numbers
    [16, 40, 10, 8].includes(Number(loan.numeroPagos)),
  );
}
