import { type MutationOptions, useQueryClient } from '@tanstack/vue-query';
import { unref, type MaybeRef, toRef } from 'vue';

import type { ApiError } from '@/api';
import { create, update, type PromoterPresidentInvite } from '@/api/promoterPresidentInvite';
import { promoterPresidentInvitesKey } from '@/api/queryKeys';
import useReactiveMutation from '@/composables/useReactiveMutation';

type UseCreatePromoterPresidentInvite = MutationOptions<void, ApiError, Partial<PromoterPresidentInvite>>

function useCreatePromoterPresidentInvite(options: UseCreatePromoterPresidentInvite) {
  return useReactiveMutation({
    ...options,
    mutationFn: (values) => create(values).then((response) => response.data),
  });
}

type UseUpdatePromoterPresidentInvite = {
  id: MaybeRef<number>, inviteeId?: MaybeRef<number | undefined>
} & MutationOptions<
Partial<PromoterPresidentInvite>, ApiError, Partial<PromoterPresidentInvite>
>

function useUpdatePromoterPresidentInvite({ id, inviteeId, ...options }: UseUpdatePromoterPresidentInvite) {
  const queryClient = useQueryClient();

  return useReactiveMutation({
    ...options,
    mutationFn: (promoterPresidentInvite) => (
      update(unref(id), promoterPresidentInvite).then((response) => response.data.promoterPresidentInvite)
    ),
    onSuccess: (...args) => {
      options.onSuccess?.(...args);
      queryClient.invalidateQueries({ queryKey: promoterPresidentInvitesKey(toRef(inviteeId)) });
    },
  });
}
export { useUpdatePromoterPresidentInvite, useCreatePromoterPresidentInvite };
