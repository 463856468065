<script setup lang="ts">
import { CalendarDaysIcon } from '@heroicons/vue/24/outline';
import { vMaska } from 'maska';
import { useField } from 'vee-validate';
import { ref, watch } from 'vue';

import useFilteredAttrs from '@/composables/useFilteredAttrs';

interface Props {
  label: string;
  description?: string;
  name: string;
  disabled?: boolean;
}

const props = defineProps<Props>();

const {
  value,
  handleChange,
  errorMessage,
  meta,
  setTouched,
} = useField<Date>(() => props.name);

function padDate(date: number) {
  // eslint-disable-next-line no-magic-numbers
  return String(date).padStart(2, '0');
}

function formatInitialValue(date: Date | string | null) {
  if (!date) return '';

  if (date instanceof Date) {
    return `${padDate(date.getDate())}/${padDate(date.getMonth() + 1)}/${date.getFullYear()}`;
  }

  if (date.match(/^\d{4}[-/]\d{2}[-/]\d{2}$/)) {
    return date.split(/[-/]/).reverse().join('/');
  }

  return date;
}

const maskedValue = ref(formatInitialValue(value.value));

watch(maskedValue, () => {
  const [day, month, year] = maskedValue.value.toString().split('/');

  if (day && month && year) {
    const date = new Date(Number(year), Number(month) - 1, Number(day));
    if (date instanceof Date && !Number.isNaN(date)) {
      handleChange(date);
    } else {
      handleChange(null);
    }
  } else {
    handleChange(null);
  }
}, { immediate: true });

watch(value, (newValue) => {
  if (!newValue) return;

  maskedValue.value = formatInitialValue(newValue);
});

const filteredAttrs = useFilteredAttrs({ excluded: ['class'] });

</script>

<template>
  <div :class="$attrs.class || 'flex flex-col items-start'">
    <label
      v-if="label"
      :for="name"
      class="font-medium leading-6 text-gray-900"
    >
      {{ label }}
    </label>
    <p
      v-if="description"
      class="mt-1.5 text-xs font-light text-gray-700"
    >
      {{ description }}
    </p>
    <div
      class="mt-2 box-border flex w-40 flex-row items-center gap-x-1 overflow-hidden rounded-md border border-transparent px-3 py-1 shadow-sm ring-1 ring-inset transition duration-300 ease-in-out focus-within:ring-2 focus-within:ring-primary-600"
      :class="{
        'border-red-400': errorMessage,
        'ring-gray-300 hover:border-primary-500': !errorMessage,
        'bg-transparent': !disabled,
        'bg-gray-100': disabled,
      }"
    >
      <CalendarDaysIcon class="h-5 w-5 text-gray-400" />
      <input
        v-bind="filteredAttrs"
        v-model="maskedValue"
        v-maska
        class="w-full border-none bg-transparent p-1 text-left text-gray-900 outline-none placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6"
        type="text"
        placeholder="DD/MM/YYYY"
        data-maska="##/##/####"
        :disabled="disabled"
        @input="setTouched(false)"
      >
    </div>
    <span
      v-if="errorMessage"
      class="mt-1 text-xs text-red-500 sm:text-sm"
      :class="{
        'animate-bounce': meta.touched,
      }"
    >
      {{ errorMessage }}
    </span>
  </div>
</template>
