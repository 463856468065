import { api } from '@/api';
import type { BaseGroup } from '@/api/group';
import type { User } from '@/api/user';

type Relationship = 'close_relative'
  | 'distant_relative'
  | 'in_law_relative'
  | 'partner'
  | 'friend'
  | 'neighbor'
  | 'acquaintance'
  | null

type YearsKnown = 'one_or_less'
  | 'one_to_two'
  | 'three_to_five'
  | 'five_to_ten'
  | 'ten_or_more'
  | null

interface BaseGroupInvitation {
  id: number;
  status: 'pending' | 'accepted' | 'rejected' | 'inactive';
  inviterId: number;
  inviteeId: number;
  groupId: number;
  invitationCode: string;
  role: 'guest' | 'vice_president';
  relationship: Relationship;
  yearsKnown: YearsKnown;
  humanYearsKnown: string;
  humanStatus: string;
  lastSentAt: string;
  invitationUrl: string;
  active: boolean;
}

interface GroupInvitation extends BaseGroupInvitation {
  inviter: User;
  invitee: User;
  group: BaseGroup;
}

const baseUrl = '/api/internal/group_invitations';

interface CreateGroupInvitationPayload {
  groupId: BaseGroupInvitation['groupId'];
  groupInvitation: Pick<User, 'firstName' | 'phoneNumber'> & {
    role: BaseGroupInvitation['role'];
    relationship: BaseGroupInvitation['relationship'];
    yearsKnown: BaseGroupInvitation['yearsKnown'];
  };
}

function create(body: CreateGroupInvitationPayload) {
  return api.post<{ groupInvitation: GroupInvitation }>(baseUrl, body);
}

function destroy(id: number) {
  return api.delete<void>(`${baseUrl}/${id}`);
}

export { create, destroy };

export type {
  BaseGroupInvitation,
  GroupInvitation,
  CreateGroupInvitationPayload,
};
