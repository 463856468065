import type { MutationOptions } from '@tanstack/vue-query';

import type { ApiError } from '@/api';
import { create, destroy, type CreateGroupInvitationPayload } from '@/api/groupInvitation';
import useReactiveMutation from '@/composables/useReactiveMutation';

type CreateReturn = Awaited<ReturnType<typeof create>>['data']['groupInvitation'];
type UseCreateGroupInvitationMutationArgs = MutationOptions<CreateReturn, ApiError, CreateGroupInvitationPayload>;

function useCreateGroupInvitation(options: UseCreateGroupInvitationMutationArgs = {}) {
  return useReactiveMutation({
    ...options,
    mutationFn: (body) => create(body).then((response) => response.data.groupInvitation),
  });
}

type DestroyReturn = Awaited<ReturnType<typeof destroy>>['data'];
type UseDestroyGroupInvitationMutationArgs = MutationOptions<DestroyReturn, ApiError, number>;

function useDestroyGroupInvitation(options: UseDestroyGroupInvitationMutationArgs = {}) {
  return useReactiveMutation({
    ...options,
    mutationFn: (id) => destroy(id).then((response) => response.data),
  });
}

export { useCreateGroupInvitation, useDestroyGroupInvitation };
